import React from "react";
import { useTheme } from "react-jss";

import useStyles from "./useStyles";
import {
  OrderItemCardInformation,
  OrderItemCardOptionList,
  OrderItemCardControl,
  OrderItemCardChoiceButton,
} from "../OrderItemCard";

export default ({ item, index, onClick, hideControl, displayPrice }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [imgSrc, setSrc] = React.useState("");
  const { image, barcode } = item;

  const getImageUrlWithSize = ({ sizeLevel }) => {
    let lastIndexOfSlash = image.lastIndexOf("/");
    let imageUrlWithSizeLevel = `${image.substr(
      0,
      lastIndexOfSlash
    )}/${barcode}_${sizeLevel}.jpg`;

    return imageUrlWithSizeLevel;
  };

  React.useEffect(() => {
    setSrc(item.selectedSize ? getImageUrlWithSize(item.selectedSize) : image);
  }, []);

  React.useEffect(() => {
    setSrc(item.selectedSize ? getImageUrlWithSize(item.selectedSize) : image);
  }, [item.selectedSize]);

  const onError = () => {
    // generate default image url
    const lastIndexOfSlash = image.lastIndexOf("/");

    const defaultImageUrl = `${image.substr(
      0,
      lastIndexOfSlash
    )}/default_product.jpg`;
    if (imgSrc !== image) {
      setSrc(image);
    } else {
      setSrc(defaultImageUrl);
    }
  };

  const getUnitPrice = () => {
    let unitPrice =
      item.selectedSize && item.selectedSize.price
        ? item.selectedSize.price
        : item.price;

    if (item.selectedOptions) {
      item.selectedOptions.forEach((o) => {
        unitPrice += o.price;
      });
    }

    return parseFloat(unitPrice).toFixed(2);
  };

  return (
    <div className={classes.container} onClick={onClick || function () {}}>
      <div className={classes.imageContainer}>
        <img src={imgSrc} className={classes.image} alt="" onError={onError} />
      </div>
      <OrderItemCardInformation
        className={classes.information}
        item={item}
        editable={true}
        displayPrice={displayPrice}
      />
      <OrderItemCardOptionList className={classes.options} item={item} />
      {!displayPrice ? (
        <div className={classes.price}>
          <span className={classes.priceSymbol}>$</span>
          <span className={classes.priceValue}>{getUnitPrice()}</span>
        </div>
      ) : (
        <div className={classes.quantity}>x {item.quantity}</div>
      )}
      {!hideControl && (
        <OrderItemCardControl
          className={classes.control}
          index={index}
          item={item}
        />
      )}
      {hideControl && (
        <OrderItemCardChoiceButton className={classes.control} item={item} />
      )}
    </div>
  );
};
