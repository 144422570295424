const reducer = (state, action) => {
    switch (action.type) {
        case "SET":

            return { ...state, ...action.payload };

        default:
            return state;
    }
}

const initState = {
    global: false,
    addDish: false,
    login: false,
}

export default [reducer, initState]