import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import HistoryOrderCtx from "../../contexts/history-order.context";

import clockIcon from "../../assets/images/icons/clock.svg";

export default () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [historyOrderState] = useContext(HistoryOrderCtx.Context);

  const getMinutes = () => {
    const currentDate = new Date();
    const orderedTime = new Date(
      `${historyOrderState.historyOrder.startTime}+1000`
    );

    const diff = currentDate.getTime() - orderedTime.getTime();

    return Math.round(diff / 60000);
  };

  if (!historyOrderState.historyOrder.startTime) return null;

  return (
    <>
      <img src={clockIcon} className={classes.clockIcon} alt="" />
      <div className={classes.clockValue}>
        <span className={classes.minutes}>{getMinutes()}</span>
        <span className={classes.unit}>min</span>
      </div>
    </>
  );
};

const useStyles = createUseStyles((theme) => {
  return {
    clockIcon: {
      width: "100%",
      height: "100%",
    },
    minutes: {
      fontSize: "10px",
      lineHeight: "16px",
      color: "#202326",
      fontFamily: `Poppins-Medium,Poppins`,
    },
    clockValue: {
      position: "absolute",
      top: 0,
      left: "atuo",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "39px",
      width: "35px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    unit: {
      fontSize: "9px",
      marginTop: "-2px",
      lineHeight: "10px",
      color: "#202326",
      fontWeight: "500",
    },
  };
});
