import React, { useReducer, createContext } from "react";

export interface State {
  defaultLanguage: string;
  multiLanguage: boolean;
  enCode: number | null; //language code , description = 1, description2 =2
  cnCode: number | null;
  expired: {
    // in minutes
    timeLimit: boolean;
    limit: {
      total: number; // expired notification will be shown, and addDish request will be blocked
      alarm: number; // countDown clock will turn red when lower than this value
    };
  };
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    [propName: string]: any;
  };
  extraToStock: {
    [propName: string]: any;
  };
  bannerStyle: {
    backgroundSize: string;
  };
  welcomeBannerHeight: string;
  needPay: boolean;
  defaultStaffCode?: string;
  sourceBarcode?: string;
  buttonTextColor: string;
  mainLightColor: string;
  shoppingCartLabelColor: string;
  mainColor: string;
  tel: string;
  userOpenId: string;
}

const initState: State = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: null, //language code , description = 1, description2 =2
  cnCode: null,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "40%",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
  tel: "",
  buttonTextColor: "#FFF",
  mainLightColor: "#ffd2583d",
  shoppingCartLabelColor: "#ffd258",
  mainColor: "#ffd258",
  userOpenId: ""
};

const reducer = (
  preState: State,
  action: { type: string; payload: any }
): State => {
  switch (action.type) {
    case "SET":
      return { ...preState, ...action.payload };

    default:
      return preState;
  }
};

export const Context = createContext<[State, Function]>([initState, reducer]);

export const Provider = ({ children }: { children: any }) => {
  const hook = useReducer(reducer, initState);

  return <Context.Provider value={hook}>{children}</Context.Provider>;
};
