import React from "react";
import { useTheme, createUseStyles } from "react-jss";

const ModalCover = ({ children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...theme, ...props });

  return (
    <div onClick={props.dismiss} className={classes.container}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classes.content}
      >
        {children}
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    backgroundColor: (props) => props.colors.coverBackground,
    display: "flex",
    flexDirection: "row",
    alignItems: (props) =>
      props.contentPosition ? props.contentPosition : "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 999999,
  },
  content: {
    backgroundColor: "#FFF",
    padding: (props) =>
      props.noPadding
        ? 0
        : props.contentPadding
        ? props.contentPadding
        : "26px 30px 20px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: (props) => (props.noPadding ? "space-between" : "center"),
    borderRadius: (props) =>
      props.contentPosition === "flex-end" ? "16px 16px 0px 0px" : "16px",
    position: (props) =>
      props.contentPosition === "flex-end" ? "fixed" : "relative",
    bottom: 0,
    left: 0,
    width: (props) => props.funcs.rw(props.contentWidth || 303),
    overflowX: "hidden",
    animation: (props) =>
      props.contentPosition === "flex-end" ? "$rising 400ms ease-in-out" : "",
    maxHeight: "90%",
  },
  "@keyframes rising": {
    from: "transform: translateY(100%)",
    to: "transform: translateY(0)",
  },
});

export default ModalCover;
