import request from "./request";

interface InputValues {
  barcode: string;
  tableCode?: string;
}

const getStaffToken = async (inputValues: InputValues) => {
  const resp = await request.put("stafftoken", inputValues);

  return resp;
};

export default getStaffToken;
