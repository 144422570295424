// ES6 module syntax
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    shoppingCart: "Shopping Cart",
    showShoppingCart: "Show Pending List",
    allTables: "ALL",
    availableTables: "Availables",
    occupiedTables: "Occupied",
    ppl: "p",
    serversError: "SERVER ERROR, CONTACT SURPORT",
    loginFail: "Fail to login",
    addDish: "ADD",
    viewTable: "VIEW",
    changeTable: "CHANGE",
    openTable: "CONFIRM",
    option: "OPTION",
    confirm: "Confirm",
    orderComments: "Comments",
    orderConfirm: "Confirm",
    tableNo: "TableNo.",
    orderNo: "OrderNo.",
    time: "Time",
    orderTime: "order time",
    orderCommentsTitle: "Order Comments",
    orderedDishTitle: "Ordered Dishes",
    orderConfirmTitle: "Order Confirm",
    changeTableTitle: "Table Change",
    successMessageForAddDish: "Order Created",
    showOrderedDishes: "Show Ordered Dishes",
    failMessageForAddDish: "Order Rejected",
    reSubmitOrder: "Re-Send",
    removeInactiveItems:
      "{0} are removed from cart, because those items are sold out. please adjust your order and submit again.",
    sending: "Sending...",
    sendingFail: "Fail",
    failMessageForUnknow: "POS Disconnect",
    refresh: "refresh",
    passwordPlaceholder: "Pin Code...",
    guestNoPlaceholder: "Guest No.",
    loginPageTitle: "Please Ask Staff For Assistance",
    internetConnectionError: "Internet Connection Error",
    passwordError: "Incorrect Password",
    openTableFailMessage1: "Fail to open table, table is using by POS",
    openTableFailMessage2: "Fail to open table, table is already opened",
    openTableFailMessage3: "Fail to opent table, table code incorrect",
    openTableFailMessage4: "Fail to open table",
    addDishFailMessage1: "Authorization Expried, Please Login again.",
    size: "Size",
    back: "Back",
    preorderInstruction: "Please present this qr-code in front of counter",
    optionTag: "Options",
    shoppingCartButtonLabel: "Confirm",
    clearCart: "Clear Cart",
    choiceButton: "Customize",
    confirmTotal: "total {0}",
    dismiss: "Dismiss",
    expiredTable: "Ordering time expired. Please contact our staffs.",
    view: "View",
    orderingFailMessage:
      "Unexpected Response Received. Please check your history order or ask our staff to check your order has been sent successfully or not.",
    addToCart: "Add To Cart",
    notEditable: "Side dishes are charged based on Soup Quantity",
    prepareAddDish: "Preparing to submit order,thank you for your patient",
    checkPayment: "Checking payment status",
    transferToPayment: "Submiting payment request",
    tableNotAvailableToPay:
      "This table ({0}) is not available to make order. please ask our staff for assistance.",
    notFoundOrderId: "Order not found. please ask our staffs for assistance.",
    seansonalIntro:
      "The price will change according to the seasonal price and actual weight",
    minSpendingNotice: "Minimum consumption in this private room",
    greedingWords: "Have a good meal!",
    underMinConsumption: "Minimum consumption is not reached, which is",
    tableBusyCallStaff:
      "Order failed. The table is ocuppying by counter staff, please ask our staff for assistance.",
    reserveNumber: "Reservations, please call ",
    makePayment: "Pay",
    connecting: "connecting",
    sourceNoPlaceholder: "Number of Sources",
    introForcedChoices: "Must make {0} choices",
    missChoices: "Missing Mandatory Choices",
  },
  cn: {
    shoppingCart: "核对菜品",
    showShoppingCart: "核对菜品",
    allTables: "全部桌台",
    availableTables: "空闲桌台",
    occupiedTables: "占用桌台",
    ppl: "人",
    serversError: "服务器出错，请联系服务人员",
    loginFail: "登录失败",
    addDish: "加菜",
    viewTable: "查看",
    changeTable: "换桌",
    openTable: "开桌",
    option: "选项",
    confirm: "确认",
    cancel: "取消",
    orderComments: "单注",
    orderConfirm: "确认",
    tableNo: "桌号",
    orderNo: "单号",
    time: "时间",
    orderTime: "下单时间",
    orderCommentsTitle: "整单备注",
    orderedDishTitle: "已点菜品",
    showOrderedDishes: "已下单菜品",
    orderConfirmTitle: "核对订单",
    changeTableTitle: "选择换桌",
    successMessageForAddDish: "落单成功",
    failMessageForAddDish: "落单失败",
    reSubmitOrder: "再次尝试",
    removeInactiveItems:
      "因产品已估清，{0}已从购物车中移除。请调整点单后再次发送。多谢合作。",
    sending: "发送中",
    sendingFail: "超时无响应",
    failMessageForUnknow: "POS无响应",
    refresh: "手动刷新",
    passwordPlaceholder: "输入密码后完成落单",
    guestNoPlaceholder: "就餐人数",
    loginPageTitle: "请呼唤服务员完成点单",
    internetConnectionError: "网络连接错误",
    passwordError: "密码错误",
    openTableFailMessage1: "开台失败,该桌正在其他终端使用",
    openTableFailMessage2: "开台失败,该桌已经被激活",
    openTableFailMessage3: "开台失败,错误的桌号",
    openTableFailMessage4: "开台失败,检查QRCODE或网络",
    addDishFailMessage1: "验证失效,请重新登录",
    size: "型号",
    back: "返回",
    preorderInstruction: "请将此二维码出示给服务员",
    shoppingCartButtonLabel: "确定",
    clearCart: "清空购物车",
    choiceButton: "更新选择",
    confirmTotal: "共 {0} 个",
    optionTag: "选规格",
    dismiss: "知道了",
    expiredTable: "超时，您已无法再加菜，您仍可以正常查看已点菜品",
    view: "核对菜品",
    orderingFailMessage: "落单异常，请查看已点菜品或服务员，查看是否落单成功",
    addToCart: "加入购物车",
    notEditable: "调料水果等按锅底数收费，无限取用",
    prepareAddDish: "准备下单，请耐心等待",
    checkPayment: "正在查看支付状态，请耐心等待",
    transferToPayment: "正在跳转支付平台，请稍后",
    notFoundOrderId: "指定订单未找到，请联系工作人员",
    tableNotAvailableToPay: "{0}桌暂时不能自助付款，请联系工作人员",
    seansonalIntro: "价格会按时令价和实际重量发生改变",
    minSpendingNotice: "本包间最低消费",
    greedingWords: "祝您用餐愉快",
    underMinConsumption: "未达到最低消费",
    tableBusyCallStaff: "落单失败，该桌被收银台使用中，请联系服务员落单",
    reserveNumber: "订餐电话: ",
    makePayment: "支付",
    connecting: "链接中",
    sourceNoPlaceholder: "小料数目",
    introForcedChoices: "该选项需要选{0}个",
    missChoices: "请先完成所有必要选择",
  },
});

export default strings;
