import React from "react"

export default ({ className, src, onClick }) => {
    return (
        <div
            style={{
                backgroundImage: `url(${src})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center'
            }}
            className={className}
            onClick={onClick || function () { }}
        />
    )
}
