import React from "react"

const PaymentSuccess = () => {
    return (
        <div>
            Payment Success
        </div>
    )
}

export default PaymentSuccess