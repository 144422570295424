import React, { useState, useContext } from "react";
import { useTheme } from "react-jss";

import useStyles from "./useStyles";
import Image from "../Image/Image";
import { useLanguage, useAppSetting, useLayout } from "../../hooks";

import cnPNG from "../../assets/images/icons/language_cn.png";
import enPNG from "../../assets/images/icons/language_en.png";
import searchPNG from "../../assets/images/icons/search.png";

import { getStoreName } from "../../utils";
import strings from "../../strings";
import { AppContext } from "../../contexts";

export default ({ showSearch }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [appState] = useContext(AppContext);
  const [counter, setCounter] = useState(0);
  const { changeLanguage, isCn } = useLanguage();
  const { multiLanguage } = useAppSetting();
  const { switchUiSuite } = useLayout();
  const hiddenDivOnClick = (e) => {
    if (counter >= 2) {
      setCounter(0);
      switchUiSuite();
    } else {
      setCounter(counter + 1);
    }
  };
  const getImageSrc = () => {
    if (!multiLanguage) {
      return "";
    } else {
      return isCn ? enPNG : cnPNG;
    }
  };
  return (
    <>
      {
        <Image
          src={getImageSrc()}
          onClick={changeLanguage}
          className={classes.icon}
        />
      }
      {/* {React.useMemo(() => <Image src={`${state.baseImageUrl}banner.png`} className={classes.logo} />, [state.baseImageUrl])} */}
      {/* {window.location.hostname !== "hotpotpalace.order.redpayments.com.au" && <img src={`${state.baseImageUrl}banner.png`} alt="" className={classes.logo} />} */}
      {/* <img src={bannerImage} alt="" className={classes.logo} /> */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: "calc(50% - 1rem)",
          height: "3rem",
          width: "2rem",
          backgroundColor: "transparent",
        }}
        onClick={hiddenDivOnClick}
      ></div>
      {/* {(window.location.hostname === 'localhost' || window.location.hostname === 'nhocafe.preorder.redpayments.com.au') && <div src={`${state.baseImageUrl}banner.png`} alt="" className={classes.textLogo}>Cafe Nho</div>} */}
      {appState.tel && (
        <div className={classes.tel}>
          {strings.reserveNumber}
          {appState.tel}
        </div>
      )}
      <Image src={searchPNG} onClick={showSearch} className={classes.icon} />
    </>
  );
};
