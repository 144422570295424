import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    colors,
    funcs: { rw, rem },
  } = theme;

  return {
    name: {
      color: colors.primaryText,
      fontSize: "1rem",
      letterSpacing: "-0.39px",
    },
    price: {
      color: colors.primaryText,
      fontSize: "0.875rem",
      letterSpacing: "1px",
      fontFamily: "sans-serif",
    },
    controlIcon: {
      width: rw(24),
      height: rw(24),
      position: "relative",
    },
    inforamtionPrice: {
      fontFamily: "Poppins-Medium",
      fontSize: rem(14),
      color: "#4a4a4a",
      fontWeight: "bold",
    },
    name: {
      fontSize: "1rem",
      fontWeight: "normal",
      fontFamily: "PingFang,arial",
      lineHeight: "1rem",
      marginBottom: "4px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      paddingRight: "10px",
    },
    quantity: {
      color: colors.lightPrimaryText,
      fontWeight: 500,
      fontSize: "1.125rem",
      textAlign: "center",
      flex: 1,
      letterSpacing: "1.29px",
    },
    editIcon: {
      height: "0.7rem",
      marginRight: "0.5rem",
    },
    choiceButton: {
      padding: "7px 6px",
      backgroundColor: colors.mainLight,
      fontSize: "9px",
      color: "#000",
      lineHeight: "11px",
      borderRadius: "12px",
    },
  };
});

export default useStyles;
