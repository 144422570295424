import axios, { AxiosResponse } from "axios";

export interface RequestBody {
  total: number;
  channel: string;
  order_id: number;
  quantity: number;
  hostname: string;
  returnUrl: string;
}

const getProducts = async (
  values: RequestBody
): Promise<AxiosResponse<any>> => {
  const resp = await axios.post(
    "https://kidsnparty-com-au.ozwearugg.com.au/roben_api/payments/public/api/payments",
    values
  );

  return resp.data;
};

export default getProducts;
