import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    height: "4.75rem",
    display: "flex",
    padding: "0 1rem",
    boxShadow: "0 2px 4px 0 rgba(235, 235, 235, 0.5)",
    alignItems: "center",
    marginBottom: "0.25rem",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    position: "relative",
  },
});

export default useStyles;
