import Axios from "axios";

const getWebSiteSettings = async (storeName) => {
  const resp = await Axios.get(
    `https://kidsnparty-com-au.ozwearugg.com.au/roben_api/payments/public/api/online-store/${storeName}`
  );
  return resp.data;
};

export default getWebSiteSettings;
