import React from "react"
import { useTheme } from "react-jss"

import useStyles from "./useStyles"
import { OrderItemCardInformation, OrderItemCardOptionList, OrderItemCardFooter } from "../OrderItemCard"

export default ({ item }) => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const [imgSrc, setSrc] = React.useState("");

    React.useEffect(() => {
        setSrc(item.image);
    }, [])

    const onError = () => {
        // generate default image url
        const lastIndexOfSlash = item.image.lastIndexOf('/')

        setSrc(`${item.image.substr(0, lastIndexOfSlash)}/default_product.jpg`)
    }

    const getTime = (time) => {
        const date = new Date(`${time}+1000`);
        const day = date.getDate().toString().padStart(2, "0");
        const month = date.getMonth().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${day}/${month} ${hours}:${minutes}`;
    }

    return (
        <div className={classes.container}>
            <div className={classes.imageContainer}><img src={imgSrc} onError={onError} className={classes.image} alt="" /></div>
            <OrderItemCardInformation className={classes.information} nameColor={theme.colors.sentDishName} item={item} displayPrice={true} />

            <OrderItemCardOptionList className={classes.options} item={item} />
            <div className={classes.quantity}>
                <span>{getTime(item.timeOrdered)}</span>
                <span>x {item.quantity}</span>
            </div>
        </div>
    )
}