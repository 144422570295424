import { useContext } from "react";
import { Context } from "../contexts/App.context";
import { getWebSiteSettings } from "../apis";
import { getStoreName } from "../utils/index"
const defaultWebSiteSettings = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    "(N/A)": "D",
  },
  extraToStock: {
    "(N/A)": {
      G108: -2,
    },
  },
  bannerStyle: {
    backgroundSize: "40%",
    backgroundPosition: "center 2px",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
  sourceBarcode: "SAUCE",
};

const useBootStrap = () => {
  const [, dispatch] = useContext(Context);

  const loadWebSiteSettings = async () => {
    const storeName = getStoreName()
    try {
      const respData = await getWebSiteSettings(storeName);

      if (respData.store) {
        const {
          default_language,
          multi_language,
          en_code,
          cn_code,
          expired_limit,
          expired_limit_total,
          expired_limit_alarm,
          banner_size,
          banner_position,
          welcome_banner_height,
          need_pay,
          extraAdv,
          extraToStock,
          default_staff_code,
          primary_color,
          light_primary_color,
          shopping_cart_label_color,
          button_text_color,
          tel,
        } = respData.store;
        dispatch({
          type: "SET",
          payload: {
            defaultLanguage: default_language,
            multiLanguage: multi_language !== 0,
            enCode: en_code, //language code , description = 1, description2 =2
            cnCode: cn_code,
            expired: {
              // in minutes
              timeLimit: expired_limit !== 0,
              limit: {
                total: expired_limit_total, // expired notification will be shown, and addDish request will be blocked
                alarm: expired_limit_alarm, // countDown clock will turn red when lower than this value
              },
            },
            extraAdv: extraAdv,
            extraToStock: extraToStock,
            bannerStyle: {
              backgroundSize: banner_size,
              backgroundPosition: banner_position,
            },
            welcomeBannerHeight: welcome_banner_height,
            needPay: need_pay !== 0,
            defaultStaffCode: default_staff_code,
            buttonTextColor: button_text_color,
            mainLightColor: light_primary_color,
            shoppingCartLabelColor: shopping_cart_label_color,
            mainColor: primary_color,
            tel: tel,
          },
        });
      } else {
        dispatch({
          type: "SET",
          payload: defaultWebSiteSettings,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loadWebSiteSettings,
  };
};

export default useBootStrap;
