import React from "react";
import "./PaymentLoading.css";
import wechatpayImage from "../../assets/images/wechat.png";
import aliPayImage from "../../assets/images/alipay.png";

import strings from "../../strings";

interface Props {
  channel: string;
}

const PaymentLoading = ({ channel }: Props) => {
  const renderImageUrl = () => {
    switch (channel) {
      case "WECHAT":
        return wechatpayImage;
      case "ALIPAY":
        return aliPayImage;
      default:
        return wechatpayImage;
    }
  };
  return (
    <div className="payment-loading">
      <div className="content">
        <div className="header">
          <img src={renderImageUrl()} alt="" />
          <div>{strings.connecting}</div>
        </div>
        <div style={{ width: "100%", height: "100%" }} className="lds-ellipsis">
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentLoading;
