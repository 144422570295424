import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
    return({
        root:{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            // zIndex: 100,
            
            overflow: "hidden"
        },
        cover:{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%"
        },
        body:{
            position: "absolute",
            padding:"0 1rem",
            bottom: 0,
            width: "100%",
            backgroundColor: "#ffffff",
            transition: "transform 0.3s ease-in-out",
            // transformOrigin: "bottom center",
            borderRadius: "20px 20px 0 0"
        },

        title:{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        title__text:{
            fontSize: 20,
            fontWeight: "bold"
        },
        title__closeIcon:{
            position: "absolute",
            left: 0,

        },
        pay:{
            padding: "3rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",

        },
        pay__unit:{
            position: "absolute",
            left: 0,
            bottom: "0",
            transform: "translateX(-120%) translateY(-5px)",
            fontSize: 25,
            fontWeight: "bolder",
            // lineHeight: 1.2
        },
        pay__amount:{
            position: "relative",
            fontSize: 40,
            fontWeight: "bolder",
        },
        options:{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            padding: "0 0 2rem 0"
        },
        options__title:{
            alignSelf: "center",
            color: "rgba(0,0,0,0.7)",
            fontSize: 18,
            padding: "1rem 0"
        },
        options__channel:{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        options__channel_detail:{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        options__channel_detail_icon:{
            width: 40,
            height: 40,
            marginRight: 18
        },
        options__channel_detail_icon_img:{
            width: "100%",
            height: "100%"
        },
        options__channel_detail_text:{
            fontSize: 20,
        },
        options__channel_checkbox:{
            width: 20,
            height: 20,
            borderRadius: "50%",
            // backgroundColor: "#FFBB0E",
            backgroundColor: "#22AC38",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        options__channel_checkbox_icon:{
            "&.MuiSvgIcon-root":{
                width: 20,
                height: 20,
                color: "#FFFFFF",
                fontWeight: "bolder"
            }
        },
        payButton:{
            width: "100%",
            height: 45,
            borderRadius: 10,
            backgroundColor: "#22AC38",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: "#FFFFFF",
            outline: "none",
            border: "none",
            "&:active":{
                backgroundColor: "#1C8B2D",
            }
        }
    })
})

export default useStyles