import React from "react"
import { useTheme, createUseStyles } from "react-jss"

import ModalCover from "../../components/ModalCover/ModalCover"
import { useConfirmPage } from "../../hooks"
import strings from "../../strings";
import { LanguageContext } from "../../contexts";

import editPNG from "../../assets/images/icons/edit.png"
import RectangleButton from "../../components/Button/RectangleButton"


export default () => {
    const { dismissCommentsModal, orderComments, orderCommentsOnChange, confirmOrderComments } = useConfirmPage()

    const theme = useTheme()
    const classes = useStyles(theme)
    const [state] = React.useContext(LanguageContext);
    const { isCn } = state;
    return (
        <ModalCover
            contentWidth={375}
            dismiss={dismissCommentsModal}
        >
            <div className={classes.title}>{strings.orderComments}</div>
            <input
                type="text"
                className={classes.input}
                value={orderComments}
                onChange={orderCommentsOnChange}
            />
            <div className={classes.footer}>
                <RectangleButton
                    onClick={confirmOrderComments}
                    buttonText={isCn ? "确认" : "confirm"}
                />
            </div>
        </ModalCover>
    )
}

const useStyles = createUseStyles(theme => {

    const { borders, funcs: { rem, rw, rh }, colors } = theme

    return {
        title: {
            width: '100%',
            textAlign: 'left',
            paddingLeft: '0.6875rem',
            fontFamily: 'PingFangSC',
            fontSize: '1.25rem',
            color: colors.primaryText
        },
        input: {
            marginTop: rem(20),
            marginBottom: rem(18),
            width: '100%',
            height: '2.125rem',
            borderRadius: '21px',
            border: borders.input,
            backgroundImage: `url(${editPNG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '1rem',
            backgroundPosition: '5% center',
            outline: 'none',
            paddingLeft: '2.5rem',
            color: colors.primaryText,
            fontSize: '1rem'
        },
        footer: {
            width: '100%',
            textAlign: 'right'
        }
    }
})