import React from "react";
import { useTheme } from "react-jss";

import { CartContext, MenuContext } from "../../contexts";

import useStyles from "./useStyles";
import IconButton from "../Button/IconButton";
import Button from "../Button/Button";

// import NumberButton from "../Button/NumberButton"

import addIcon from "../../assets/images/icons/add.svg";
import { ReactComponent as AddButton } from "../../assets/images/icons/add.svg";
// import optionIcon from "../../assets/images/icons/option.png"
import decreaseIcon from "../../assets/images/icons/decrease.svg";
import {useLayout} from "../../hooks"
import strings from "../../strings";

import {checkShopNameAndOrderType} from "../../utils/index"

export default ({ stock, promotion }) => {
  /* get css class */
  const theme = useTheme();
  const classes = useStyles(theme);
  const { uiSuite } = useLayout();

  /* import context state */
  const [cartState, cartDispatch] = React.useContext(CartContext);
  const [, menuDispatch] = React.useContext(MenuContext);
  const { cartList } = cartState;

  /* component state for managing displaying status, ex: which button should be shown */
  const [stockWithOptions, setStockWithOptions] = React.useState(false);

  /* initialize component state */
  React.useEffect(() => {
    setStockWithOptions(
      stock.extra.length > 0 ||
        stock.taste.length > 0 ||
        stock.sizeLevels.length > 1
    );
  }, [cartList]);

  /* helper functions for adding,removing stock from cart AND calculate stock card display quantity */
  const calculateQuantity = () => {
    return cartList.reduce((qty, orderItem) => {
      if (orderItem.barcode === stock.barcode) {
        return qty + orderItem.quantity;
      } else {
        return qty;
      }
    }, 0);
  };

  const add = () => {
    if (stockWithOptions) {
      // show option select window
      menuDispatch({
        type: "SET",
        payload: {
          showOptionModal: true,
          selectedItem: stock,
          isShowLargeImage: false,
        },
      });
    } else {
      cartDispatch({ type: "ADD", payload: { ...stock, quantity: 1 } });
    }
  };

  const decrease = () => {
    if (stockWithOptions) return false;
    cartDispatch({ type: "DECREASE", payload: { ...stock, quantity: 1 } });
  };

  if (stock.barcode === "SAUCE") {
    const { hostname } = window.location;
    if (
      checkShopNameAndOrderType("jackpothotpot", "order") ||
      hostname === "localhost"
    ) {
      return null;
    }
  }

  /* render component*/
  if (uiSuite === "CHOWBUS" && promotion) {
    return (
      <div className={classes.stockCardControllChow}>
        {calculateQuantity() > 0 && (
          <div className={classes.buttonQuantity}>{calculateQuantity()}</div>
        )}
        <AddButton className={classes["icon-button-svg"]} onClick={add} />
      </div>
    );
  }
  return (
    <div className={classes.stockCardControll}>
      {/* button show only quantity greater than 0 and stock without any options*/}
      {calculateQuantity() > 0 && !stockWithOptions && (
        <IconButton type="remove" icon={decreaseIcon} onClick={decrease} />
      )}
      {/* button shows when quantity greater than 0 whatever stock with exts or not */}
      {/* {calculateQuantity() > 0 && <NumberButton onClick={add} quantity={calculateQuantity()} />} */}
      {/* display quantity when quantity > 0 */}
      {calculateQuantity() > 0 && !stockWithOptions && (
        <span className={classes.stockCardQuantity}>{calculateQuantity()}</span>
      )}
      {/* button shows only quantity is Zero */}
      {!stockWithOptions && (
        <IconButton type="add" icon={addIcon} onClick={add} />
      )}
      {/* {!stockWithOptions && <Button onClick={add} style={{ fontSize: '1.25rem' }}>+</Button>} */}

      {/* {stockWithOptions && <IconButton icon={optionIcon} onClick={add} />} */}
      {stockWithOptions && (
        <Button
          onClick={add}
          style={{
            fontSize: "12px",
            fontFamily: `'Lato',sans-serif`,
            fontWeight: "300",
            padding: "0 10px",
            height: "27px",
            borderRadius: "14px",
          }}
        >
          {strings.optionTag}
        </Button>
      )}
    </div>
  );
};
