import React from "react";
import { withRouter, useParams } from "react-router";

import { Header } from "..";
import useStyles from "./useStyles";
import strings from "../../strings";
import { useConfirmPage } from "../../hooks";

import backPNG from "../../assets/images/icons/arrow-left.svg";
import tableIcon from "../../assets/images/icons/table.svg";

const ConfirmPageHeader = ({ history }) => {
  const { tableCode } = useParams();
  const { showCart } = useConfirmPage();

  const classes = useStyles();

  const navToMenu = () => {
    showCart();
    if (tableCode === "preorder") {
      history.push(`${process.env.PUBLIC_URL}/`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/${tableCode}`);
    }
  };

  return (
    <Header>
      <img
        className={classes.backIcon}
        src={backPNG}
        onClick={navToMenu}
        alt=""
      />
      <img className={classes.tableIcon} src={tableIcon} alt="" />
      <div className={classes.tableCode}>
        {tableCode === "preorder" ? "" : tableCode}
      </div>
      <div className={classes.title}>{strings.orderConfirmTitle}</div>
    </Header>
  );
};

export default withRouter(ConfirmPageHeader);
