import React from "react";
import _ from "lodash";
import { LoadingContext } from "../contexts";
import { useParams } from "react-router";
import { getHistoryOrder } from "../apis";
import { ConfirmPageContext } from "../contexts";
import { Context as AppCTX } from "../contexts/App.context";
import HistoryOrderCtx from "../contexts/history-order.context";

const useHistoryOrder = () => {
  const [, loadingDispatch] = React.useContext(LoadingContext);
  const [, confirmPageDispatch] = React.useContext(ConfirmPageContext);
  const [historyOrderState, historyOrderDispatch] = React.useContext(
    HistoryOrderCtx.Context
  );
  const [historyOrder, setHistoryOrder] = React.useState([]);
  const [appState] = React.useContext(AppCTX);
  const { enCode, cnCode, expired } = appState;

  const getTime = ({ timeOrdered }) => {
    const date = new Date(`${timeOrdered}+1000`);

    return date.getTime();
  };
  const { tableCode } = useParams();
  const fetchHistoryOrder = async () => {
    try {
      loadingDispatch({ type: "SET", payload: { global: true } });

      const resp = await getHistoryOrder({ tableCode });
      if (resp.data.code == "0") {
        // check expired or not
        historyOrderDispatch({
          type: HistoryOrderCtx.ActionTypes.update,
          payload: {
            historyOrder: {
              ...resp.data.data,
              salesorderLines: resp.data.data.salesorderLines.map(
                (orderItem) => ({
                  ...orderItem,
                  description1:
                    cnCode === 1
                      ? orderItem.description
                      : orderItem.description2,
                  description2:
                    enCode === 2
                      ? orderItem.description2
                      : orderItem.description,
                  orderTime: getTime(orderItem),
                })
              ),
            },
          },
        });

        if (resp.data.data.startTime && expired.timeLimit) {
          try {
            const startTime = new Date(`${resp.data.data.startTime}+1000`);
            const currentDateTime = new Date();

            const diff = currentDateTime.getTime() - startTime.getTime();
            confirmPageDispatch({
              type: "SET",
              payload: { expired: diff / 3600000 > 1.5 },
            });
          } catch (error) {
            console.log("parse start time error: ", error);
          }
        }
        // if success, store salesorderId locally - will be used to determine what process should be used when customs are trying to add dishes
        localStorage.setItem(
          "aupos_customer_order_salesorder_id",
          resp.data.data.salesorderId
        );
        const orderedArray = _.orderBy(
          resp.data.data.salesorderLines.map((orderItem) => ({
            ...orderItem,
            description1:
              cnCode === 1 ? orderItem.description : orderItem.description2,
            description2:
              enCode === 2 ? orderItem.description2 : orderItem.description,
            orderTime: getTime(orderItem),
          })),
          ["orderTime"],
          ["desc"]
        );

        setHistoryOrder(
          orderedArray.map((sl) => ({
            ...sl,
            image: `${resp.data.data.imageUrl}${sl.barcode}.jpg`,
            selectedOptions: sl.option,
          }))
        );
        loadingDispatch({ type: "SET", payload: { global: false } });
      } else {
        /**
         * code === "2000" Wrong table code
         */
        localStorage.removeItem("aupos_customer_order_salesorder_id");

        loadingDispatch({ type: "SET", payload: { global: false } });
      }
    } catch (error) {
      localStorage.removeItem("aupos_customer_order_salesorder_id");
      loadingDispatch({ type: "SET", payload: { global: false } });
    }
  };

  const getSubTotal = () => {
    let sum = 0;

    historyOrder.forEach((orderItem) => {
      sum += orderItem.price * orderItem.quantity;
      if (orderItem.option && typeof orderItem.option.forEach === "function") {
        orderItem.option.forEach((option) => {
          sum += option.price * option.quantity;
        });
      }
    });

    return sum.toFixed(2);
  };

  const getQuantity = () => {
    let sum = 0;

    historyOrder.forEach((orderItem) => {
      sum += orderItem.quantity;
    });

    return sum;
  };

  React.useEffect(() => {
    fetchHistoryOrder();
  }, []);

  return {
    historyOrder,
    minConsumption: historyOrderState.minConsumption,
    subTotal: getSubTotal(),
    quantity: getQuantity(),
    updateMinConsumption: (value) => {
      historyOrderDispatch({
        type: HistoryOrderCtx.ActionTypes.update,
        payload: { minConsumption: value },
      });
    },
  };
};

export default useHistoryOrder;
