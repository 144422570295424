import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
    return({
        root:{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            width: "100%",
            minHeight: "100vh",
            background: "#fafafa"
        },
        statusIcon:{
            marginTop: "5rem",
            width: 90,
            height: 90,
            alignSelf: "center"
        },
        statusIcon__processing:{
            width: "100%",
            height: "100%"
        },
        statusText:{
            marginTop: "3rem",
            fontSize: 25,
            fontWeight: "bold",
            alignSelf: "center"
        },
        orderNumber:{
            alignSelf: "center",
            marginTop: "2rem",
            fontSize: 14,
        },
        successReminderText:{
            alignSelf: "center",
            marginTop: "2rem",
            fontSize: 18,
        },
        table:{
            marginTop: "2rem",
            width: "100%",
            borderRadius: 10,
            backgroundColor: "#FFFFFF",
            padding: "10px 20px 20px 20px",
            minHeight: "10rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch"
        },
        table__cell:{
            padding: "0.7rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        table__title: {
            fontSize: 17,
        },
        table_text:{
            fontSize: 17,
            color: "rgba(0,0,0,0.87)"
        },
        backButton:{
            padding: "0 20px 0 20px",
            marginTop: 20,
        },
        backButton__button:{
            width: "100%",
            height: 45,
            borderRadius: 10,
            backgroundColor: "#22AC38",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: "#FFFFFF",
            outline: "none",
            border: "none",
            "&:active":{
                backgroundColor: "#1C8B2D",
            }
        },
        successIcon: {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: "#22AC38",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        successIcon__icon:{
            "&.MuiSvgIcon-root":{
                width: "80%",
                height: "80%",
                color: "#FFFFFF",
                fontWeight: "bolder"
            }
        },
        errorIcon:{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: "#f44336",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    })
})

export default useStyles