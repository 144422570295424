import React from "react";

import { useTheme } from "react-jss";
import useStyles from "./useStyles";

export default ({ onClick, buttonText, style }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={classes.button}
      style={style ? style : {}}
    >
      {buttonText}
    </button>
  );
};
