import {checkShopNameAndOrderType} from "../utils/index"
const reducer = (state, action) => {
  let newCartList = [];
  const { type, payload } = action;
  const { hostname } = window.location;

  const addSource =
    hostname === "localhost" ||
    checkShopNameAndOrderType("dolarshopsydney", "order")
  switch (type) {
    case "SET":
      return { ...state, ...payload };

    case "LOAD":
      const localCart = localStorage.getItem(
        "aupos_customer_order_shopping_cart"
      );
      if (localCart) {
        newCartList = JSON.parse(localCart);
      } else {
        newCartList = [];
      }

      return { ...state, cartList: newCartList };

    case "DECREASE":
      newCartList = state.cartList
        .map((orderItem) => {
          if (orderItem.stock_id === payload.stock_id) {
            return { ...orderItem, quantity: orderItem.quantity - 1 };
          } else {
            return orderItem;
          }
        })
        .filter((x) => x.quantity > 0);

      // 豆捞定制：加锅底的时候加入酱料费用
      if (addSource) {
        if (payload.cat_name === "HOT POT") {
          newCartList = newCartList
            .map((item) => {
              if (item.barcode === "A001") {
                return { ...item, quantity: item.quantity - 1 };
              } else {
                return item;
              }
            })
            .filter((x) => x.quantity > 0);
        }
      }

      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList)
      );

      return { ...state, cartList: newCartList };

    case "ADD":
      if (
        payload.taste.length === 0 &&
        payload.extra.length === 0 &&
        payload.sizeLevels.length <= 1
      ) {
        let flag = false;
        newCartList = state.cartList.map((orderItem) => {
          if (orderItem.stock_id === payload.stock_id) {
            flag = true;
            return {
              ...orderItem,
              quantity: orderItem.quantity + payload.quantity,
              comments: payload.comments,
              selectedSize: payload.selectedSize,
            };
          } else {
            return orderItem;
          }
        });

        if (!flag) {
          newCartList = [...state.cartList, payload];
        }
      } else {
        // need check both extra and taste

        let flag = false; // not add to cart yet
        newCartList = state.cartList.map((orderItem) => {
          if (
            orderItem.stock_id === payload.stock_id &&
            orderItem.selectedOptions.length === payload.selectedOptions.length
          ) {
            let flag2 = false; // any unmatched option found
            payload.selectedOptions.forEach((x) => {
              if (
                orderItem.selectedOptions.find(
                  (y) => y.barcode === x.barcode
                ) === undefined
              ) {
                flag2 = true; // found unmatched option
              }
            });
            // if size not match! found unmatched value;
            if (
              orderItem.selectedSize &&
              orderItem.selectedSize.sizeLevel !==
                payload.selectedSize.sizeLevel
            )
              flag2 = true;

            if (!flag2) {
              // if not found any unmatched option that means this orderItem is equal to payload
              // so mark this payload added to cart already
              flag = true;
              // increaste orderItem quantity according to payload.quantity
              return {
                ...orderItem,
                quantity: orderItem.quantity + payload.quantity,
                comments: payload.comments,
                selectedSize: payload.selectedSize,
              };
            } else {
              return orderItem;
            }
          } else {
            return orderItem;
          }
        });

        if (!flag) {
          newCartList = [...state.cartList, payload];
        }
      }

      // 豆捞定制：加锅底的时候加入酱料费用
      if (addSource) {
        if (payload.cat_name === "HOT POT") {
          let added = false;
          newCartList = newCartList.map((item) => {
            if (item.barcode === "A001") {
              added = true;
              return { ...item, quantity: item.quantity + payload.quantity };
            } else {
              return item;
            }
          });

          if (!added) {
            newCartList = [
              { ...state.defaultAddItem, quantity: payload.quantity },
              ...newCartList,
            ];
          }
        }
      }
      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList.filter((x) => x.quantity > 0))
      );

      return { ...state, cartList: newCartList.filter((x) => x.quantity > 0) };
    case "RESET":
      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify([])
      );

      newCartList = [];
      return { ...state, cartList: newCartList, orderComments: "" };

    case "ORDER_ITEM_UPDATE":
      newCartList = state.cartList.map((orderItem, index) => {
        if (index === payload.index) {
          return {
            ...orderItem,
            quantity: orderItem.quantity + payload.quantity,
            comments: payload.comments,
            selectedOptions: payload.selectedOptions,
            selectedSize: payload.selectedSize,
          };
        } else {
          return orderItem;
        }
      });

      // 豆捞定制：加锅底的时候加入酱料费用
      if (addSource) {
        const itemToDecrease = newCartList.find((x, i) => i === payload.index);
        if (itemToDecrease.cat_name === "HOT POT") {
          newCartList = newCartList
            .map((item) => {
              if (item.barcode === "A001") {
                return { ...item, quantity: item.quantity + payload.quantity };
              } else {
                return item;
              }
            })
            .filter((x) => x.quantity > 0);
        }
      }
      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList.filter((x) => x.quantity > 0))
      );

      return { ...state, cartList: newCartList.filter((x) => x.quantity > 0) };

    case "DECREASE_IN_CART":
      let itemIsHotpot = false;
      newCartList = state.cartList
        .map((orderItem, index) => {
          if (index === payload) {
            if (orderItem.cat_name === "HOT POT") itemIsHotpot = true;
            return { ...orderItem, quantity: orderItem.quantity - 1 };
          } else {
            return orderItem;
          }
        })
        .filter((x) => x.quantity > 0);

      // 豆捞定制：加锅底的时候加入酱料费用
      if (addSource) {
        if (itemIsHotpot) {
          newCartList = newCartList
            .map((item) => {
              if (item.barcode === "A001") {
                return { ...item, quantity: item.quantity - 1 };
              } else {
                return item;
              }
            })
            .filter((x) => x.quantity > 0);
        }
      }

      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList)
      );
      return { ...state, cartList: newCartList };

    case "INCREASE_IN_CART":
      newCartList = state.cartList.map((orderItem, index) => {
        if (index === payload) {
          return { ...orderItem, quantity: orderItem.quantity + 1 };
        } else {
          return orderItem;
        }
      });

      // 豆捞定制：加锅底的时候加入酱料费用
      if (addSource) {
        const itemToIncrease = newCartList.find((x, i) => i === payload);
        if (itemToIncrease.cat_name === "HOT POT") {
          newCartList = newCartList.map((item) => {
            if (item.barcode === "A001") {
              return { ...item, quantity: item.quantity + 1 };
            } else {
              return item;
            }
          });
        }
      }

      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList)
      );
      return { ...state, cartList: newCartList };
    case "ADD_SAUCE_JACKPOT":
      // JACK POT定制功能，服务员输入密码开台的时候会手动输入该台需要的小料数目
      const sourceIndexInCart = state.cartList.findIndex(
        (x) => x.barcode === "SAUCE"
      );
      if (sourceIndexInCart !== -1) {
        newCartList = state.cartList.map((orderItem) => {
          if (orderItem.barcode === "SAUCE") {
            return { ...orderItem, quantity: action.payload.quantity };
          } else {
            return orderItem;
          }
        });
      } else {
        newCartList = [...state.cartList, action.payload];
      }
      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList.filter((x) => x.quantity > 0))
      );
      return { ...state, cartList: newCartList.filter((x) => x.quantity > 0) };
    case "REMOVE_INACTIVE_ORDERITEM":
      newCartList = state.cartList.filter(
        (orderItem) => !action.payload.includes(orderItem.stockId)
      );
      localStorage.setItem(
        "aupos_customer_order_shopping_cart",
        JSON.stringify(newCartList)
      );
      return { ...state, cartList: newCartList.filter((x) => x.quantity > 0) };
    default:
      return state;
  }
};

const initState = {
  cartList: [],
  orderComments: "",
  defaultAddItem: {},
};
export default [reducer, initState];
