import React from "react";
import { useTheme } from "react-jss";

import useStyles from "./useStyles";
import StockCardControl from "./StockCardControl";
import { MenuContext, LanguageContext } from "../../contexts";
import strings from "../../strings";
import { useLayout } from "../../hooks";

import { checkShopNameAndOrderType } from "../../utils/index"

export default ({ stock, promotion }) => {
  const isSeasonal = () => {
    const { hostname } = window.location;
    if (
      hostname === "localhost" ||
      checkShopNameAndOrderType("jackpothotpot", "order")
    ) {
      return [
        "H101",
        "H102",
        "H103",
        "H107",
        "H108",
        "H109",
        "H110",
        "H111",
      ].includes(stock.barcode);
    } else {
      return false;
    }
  };
  const { uiSuite } = useLayout();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [, menuDispatch] = React.useContext(MenuContext);
  const [src, setSrc] = React.useState("");
  const [state] = React.useContext(LanguageContext);
  const { isCn } = state;
  React.useEffect(() => {
    setSrc(stock.image);
  }, []);

  const onError = () => {
    // generate default image url
    const lastIndexOfSlash = stock.image.lastIndexOf("/");

    setSrc(`${stock.image.substr(0, lastIndexOfSlash)}/default_product.jpg`);
  };

  const onImageClick = () => {
    menuDispatch({
      type: "SET",
      payload: { selectedItem: stock, isShowLargeImage: true },
    });
  };

  if (uiSuite === "CHOWBUS" && promotion) {
    return (
      <div className={classes.stockCardContainerChow}>
        <img
          src={src}
          className={classes.stockCardImageChow}
          onError={onError}
          alt=""
          onClick={onImageClick}
        />
        <div className={classes.stockCardInfoChow}>
          {checkShopNameAndOrderType("hkyuebaby", "preorder")
            ? stock.barcode
            : null}{" "}
          <div className={classes.stockCardNameChow}>
            {isCn ? stock.description1 : stock.description2}
          </div>
          <div className={classes.stockCardPriceChow}>
            ${parseFloat(stock.price).toFixed(2)}
          </div>
        </div>
        {isSeasonal() && (
          <div className={classes.seasonalIntro}>{strings.seansonalIntro}</div>
        )}
        <div className={classes.stockCardPriceControlChow}>
          <StockCardControl stock={stock} promotion={promotion} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.stockCardContainer}>
      <img
        src={src}
        className={classes.stockCardImage}
        onError={onError}
        alt=""
        onClick={onImageClick}
      />
      <div className={classes.stockCardMain}>
        <div className={classes.stockCardName}>
          {checkShopNameAndOrderType("hkyuebaby", "preorder")
            ? stock.barcode
            : null}{" "}
          {isCn ? stock.description1 : stock.description2}
        </div>
        {isSeasonal() && (
          <div className={classes.seasonalIntro}>{strings.seansonalIntro}</div>
        )}
        <div className={classes.stockCardPriceControl}>
          <div className={classes.stockCardPrice}>
            <span className={classes.priceSymbol}>$</span>
            <span className={classes.priceValue}>
              {parseFloat(stock.price).toFixed(2)}
            </span>
          </div>
          <StockCardControl stock={stock} promotion={promotion} />
        </div>
      </div>
    </div>
  );
};
