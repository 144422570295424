import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    funcs: { rw, rh, rem },
    colors,
    extends: { verticalFlexbox },
  } = theme;

  return {
    container: {
      height: rem(50),
      width: rw(320),
      position: "fixed",
      left: rw(32),
      bottom: "0.5625rem",
      borderRadius: rem(27),
      transition: "all 300ms ease-in-out",
      boxShadow:
        "0px 4px 20px 0px rgba(32,35,38,0.15),0px 3px 6px 2px rgba(32,35,38,0.1),0px 2px 4px 0px rgba(32,35,38,0.1)",
      color: "#FFF",
      ...verticalFlexbox,
      overflow: "hidden",
      backgroundColor: colors.buttonBlack,
      letterSpacing: "1px",
    },
    containerChow: {
      height: rem(50),
      width: "100vw",
      position: "fixed",
      left: 0,
      bottom: 0,
      borderRadius: 0,
      transition: "all 300ms ease-in-out",
      color: "#FFF",
      ...verticalFlexbox,
      overflow: "hidden",
      backgroundColor: colors.buttonBlack,
      letterSpacing: "1px",
    },
    cartIconContainer: {
      ...verticalFlexbox,
      position: "absolute",
      top: "auto",
      left: rem(16),
      height: rem(36),
      width: rem(36),
      backgroundColor: "#FFF",
      borderRadius: "50%",
    },
    cartIcon: {
      height: "85%",
    },
    quantity: {
      ...verticalFlexbox,
      position: "absolute",
      top: "-0.25rem",
      right: "-0.5rem",
      height: rem(16),
      width: rem(16),
      borderRadius: "50%",
      backgroundColor: colors.main,
      color: "#FFF",
      fontSize: "10px",
      fontFamily: "Poppins-Medium",
    },
    label: {
      // fontSize: rem(14),
      fontFamily: `sans-serif`,
      fontWeight: 600,
      letterSpacing: "0px",
      // textShadow: '0px 4px 20px rgba(32,35,38,0.15)',
      color: "#FFF",
      opacity: 0.6,
      "&.active": {
        opacity: 1,
        color: colors.shoppingCartLabel,
      },
    },
    amount: {
      position: "absolute",
      right: rem(13),
      top: "auto",
      color: "#FFF",
      fontSize: rem(18),
      fontFamily: "Poppins-Medium",
      fontWeight: 500,
      textShadow: "0px 4px 20px rgba(32,35,38,0.15)",
    },
  };
});

export default useStyles;
