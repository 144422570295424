import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { useAddDish, useConfirmPage } from "../../hooks";
import ModalCover from "../ModalCover/ModalCover";
import strings from "../../strings";

import successPNG from "../../assets/images/icons/success.png";
import failPNG from "../../assets/images/icons/fail.png";

const AddDishResponsePage = () => {
  const { addDishResponse, resetAddDishResponse, addDish } = useAddDish();
  const { showCart, showOrder } = useConfirmPage();
  const { code, message } = addDishResponse;

  const theme = useTheme();
  const classes = useStyles(theme);
  let t;

  React.useEffect(() => {
    const fn = () => {
      t = setTimeout(
        () => {
          if (code !== 1001) {
            resetAddDishResponse();
            showOrder();
          } else {
            resetAddDishResponse();
            showCart();
          }
        },
        code === 200 ? 3000 : code === 1001 ? 20000 : 8000
      );
    };

    if (code !== 400) {
      fn();
    }

    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <ModalCover
      dismiss={() => {
        resetAddDishResponse();
        if (code !== 400 && code !== 1001) {
          showOrder();
        } else {
          showCart();
        }
      }}
    >
      <img
        className={classes.img}
        src={code === 200 ? successPNG : failPNG}
        alt=""
      />
      <div
        className={classes.title}
        style={code === 1001 ? { fontSize: "0.65rem" } : {}}
      >
        {code === 200 && strings.successMessageForAddDish}
        {code === 400 && strings.tableBusyCallStaff}
        {code === 1001 &&
          strings.formatString(strings.removeInactiveItems, message)}
        {code !== 200 &&
          code !== 400 &&
          code !== 1001 &&
          strings.failMessageForAddDish}
      </div>
      {code === 400 && (
        <button className={classes.retryButton} onClick={addDish}>
          {strings.reSubmitOrder}
        </button>
      )}
      {code === 300 && (
        <div className={classes.info}>{strings.orderingFailMessage}</div>
      )}
    </ModalCover>
  );
};

export default AddDishResponsePage;

const useStyles = createUseStyles((theme) => {
  const {
    funcs: { rw, rh, rem },
    colors,
  } = theme;

  return {
    img: {
      width: rw(140),
    },
    title: {
      marginTop: rh(10),
      fontSize: rem(18),
      lineHeight: rem(25),
      color: colors.lightPrimaryText,
      fontFamily: "PingFangSC-Regular,PingFang SC",
    },
    retryButton: {
      marginTop: rh(46),
      outline: "none",
      border: "none",
      borderRadius: "6px",
      backgroundColor: colors.primaryText,
      color: "#FFF",
      width: rw(114),
      fontSize: rem(20),
      padding: `${rh(10)} ${rw(15)}`,
      fontFamily: `PingFangSC-Regular,PingFang SC`,
      fontWeight: 400,
    },
    info: {
      fontSize: rem(14),
      color: "#a4a4a4",
    },
  };
});
