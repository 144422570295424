import React, {useEffect} from "react"
import useStyles from "./useStyles"

import { CartContext } from "../../contexts";
import { Context } from "../../contexts/App.context";

//Utils
import { checkIsMiniProgram } from "../../utils/miniapp";
import {secondToMinutesStr, amountToStr} from "../../utils/index"
//Apis
import createMiniappWechatPay from "../../apis/createMiniappWechatPay";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Divider } from "@material-ui/core";
import wechatpay from "../../assets/images/icons/wechatpay.svg"
import CheckIcon from '@material-ui/icons/Check';
// import CheckSharpIcon from '@material-ui/icons/CheckSharp';
const PaymentCover = ({open=false, onClose}) => {
    const classes = useStyles()
    const [appState, appDispatch] = React.useContext(Context);
    const [state, dispatch] = React.useContext(CartContext);
    const { cartList } = state;
    const {
        userOpenId
      } = appState;
    const [totalAmount, setTotalAmount] = React.useState(0);
    useEffect(()=>{
        // setTotalAmount(0.01)
        console.log("payment cover cart state", state)
        setTotalAmount(
            cartList
              .reduce((sum, orderItem) => {
                let unitPrice =
                  orderItem.quantity *
                  parseFloat(
                    orderItem.selectedSize && orderItem.selectedSize.price
                      ? orderItem.selectedSize.price
                      : orderItem.price
                  );
                if (orderItem.selectedOptions) {
                  orderItem.selectedOptions.forEach((o) => {
                    unitPrice += o.price;
                  });
                }
                return sum + unitPrice;
              }, 0)
          );
    },[cartList])

    const handleClickPay = async() => {
      if(checkIsMiniProgram()){
        const res = await createMiniappWechatPay({amount: totalAmount, userOpenId:userOpenId})
        console.log("res",res)
        if(res.code === "0" && res?.data && res.data?.data && res.data.data?.resultCode === "success"){
            const data = {...res.data.data, userOpenId:userOpenId}
            wx.miniProgram.navigateTo({
                url: '../pay/index?data=' + encodeURIComponent(JSON.stringify(data))
            }) 
        }
      }

    }

    const handleClose = ()=>{
        onClose && onClose()
    }
    return(
        <div className={classes.root} style={{height: open?"100vh":0, zIndex: open?100:-100}}>
            <div onClick={handleClose} className={classes.cover}/>
            <div className={classes.body} style={{transform: open ? "translateY(0)":"translateY(100%)"}}>
                <div className={classes.title}>
                    <span className={classes.title__text}>Payment Details</span>
                    <div className={classes.title__closeIcon}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                    </div>
                </div>
                <Divider/>
                <div className={classes.pay}>
                    <div className={classes.pay__amount}>
                        {amountToStr(totalAmount)}
                        {/* {totalAmount} */}
                        <div className={classes.pay__unit}>A$</div>
                    </div>
                </div>
                <Divider/>
                <div className={classes.options}>
                    <div className={classes.options__title}>
                        Choose Payment
                    </div>
                    <div className={classes.options__channel}>
                        <div className={classes.options__channel_detail}>
                            <div className={classes.options__channel_detail_icon}>
                                <img className={classes.options__channel_detail_icon_img} src={wechatpay} alt="Wechat Icon"/>
                            </div>
                            <div className={classes.options__channel_detail_text}>
                                WeChat Pay
                            </div>
                        </div>
                        <div className={classes.options__channel_checkbox}>
                            <CheckIcon className={classes.options__channel_checkbox_icon}/>
                        </div>
                    </div>
                </div>
                <button className={classes.payButton} onClick={handleClickPay}>
                    Confirmed
                </button>
            </div>
        </div>
    )
}

export default PaymentCover