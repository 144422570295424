import React, { useContext, useEffect } from "react";
import { useLastLocation } from "react-router-last-location";

import { useMenu, useHistoryOrder } from "../../hooks";
import { MenuContext, ConfirmPageContext } from "../../contexts";
import { Context as AppCTX } from "../../contexts/App.context";

import WelcomeCtx from "../../contexts/welcome-modal.context";
import { useParams } from "react-router-dom";

import {checkShopNameAndOrderType} from "../../utils/index"

import {
  Header,
  Menu,
  SearchBar,
  MenuHeader,
  ShoppingCartButton,
  ExpiredModal,
  WelcomeModal,
} from "../../components";

let firstTimeRender = true;

export default () => {
  useHistoryOrder();
  // get dishes from API, all dishes grouped by categories
  const { getStockList } = useMenu();
  const [appState] = useContext(AppCTX);
  const { cnCode, enCode } = appState;
  useEffect(() => {
    if (enCode !== null && cnCode !== null) {
      getStockList();
    }
  }, [enCode, cnCode]);
  const lastLocation = useLastLocation();
  const [welcomeState, welcomeDispatch] = useContext(WelcomeCtx.Context);
  const { tableCode } = useParams();
  const { hostname } = window.location;

  if (firstTimeRender && lastLocation === null) {
    if (
      !checkShopNameAndOrderType("jackpothotpot", "order")||
      hostname !== "localhost"
    ) {
      const action1 = {
        type: WelcomeCtx.ActionTypes.update,
        payload: {
          show: true,
        },
      };
      welcomeDispatch(action1);
    }

    if (
      (checkShopNameAndOrderType("jackpothotpot", "order")||
        hostname === "localhost") &&
      (tableCode === "大包" || tableCode === "小包")
    ) {
      const action2 = {
        type: WelcomeCtx.ActionTypes.update,
        payload: {
          show: true,
          type: "min-spending",
          limitValue: tableCode === "大包" ? 888 : 688,
        },
      };
      welcomeDispatch(action2);
    }

    firstTimeRender = false;
  }

  // menu context
  const [menuState, menuDispatch] = React.useContext(MenuContext);
  const { searchKeyword, showSearchInput } = menuState;
  // history order context
  const [confirmPageState, confirmPageDispatch] = useContext(
    ConfirmPageContext
  );

  /* component state  */

  useEffect(() => {
    if (confirmPageState.expired) {
      confirmPageDispatch({
        type: "SET",
        payload: { isShowExpiredModal: true },
      });
    }
  }, [confirmPageState.expired]);

  return (
    <div>
      {welcomeState.show && <WelcomeModal />}
      {confirmPageState.isShowExpiredModal && confirmPageState.expired && (
        <ExpiredModal />
      )}
      <Header size="lg" search={showSearchInput}>
        {showSearchInput ? (
          <SearchBar
            inputValue={searchKeyword}
            onInputChange={(e) => {
              menuDispatch({
                type: "SET",
                payload: { searchKeyword: e.target.value },
              });
            }}
            hideSearch={() => {
              menuDispatch({
                type: "SET",
                payload: { searchKeyword: "", showSearchInput: false },
              });
            }}
          />
        ) : (
          <MenuHeader
            showSearch={() => {
              menuDispatch({ type: "SET", payload: { showSearchInput: true } });
            }}
          />
        )}
      </Header>
      <Menu />

      <ShoppingCartButton />
    </div>
  );
};
