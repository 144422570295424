import React, { useContext } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ConfirmPageContext } from "../../contexts";
import Cover from "../ModalCover/ModalCover";
import failPNG from "../../assets/images/icons/fail.png";
import strings from "../../strings";

const ExpiredModal = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [, dispatch] = useContext(ConfirmPageContext);

  const dismiss = () => {
    dispatch({
      type: "SET",
      payload: {
        isShowExpiredModal: false,
      },
    });
  };

  return (
    <Cover dismiss={dismiss}>
      <img className={classes.img} src={failPNG} alt="" />
      <div className={classes.title}>{strings.expiredTable}</div>
      {
        <button className={classes.retryButton} onClick={dismiss}>
          {strings.dismiss}
        </button>
      }
    </Cover>
  );
};

export default ExpiredModal;

const useStyles = createUseStyles((theme: any) => {
  const {
    funcs: { rw, rh, rem },
    colors,
  } = theme;

  return {
    img: {
      width: rw(140),
    },
    title: {
      marginTop: rh(10),
      fontSize: rem(18),
      lineHeight: rem(25),
      color: colors.lightPrimaryText,
      fontFamily: "PingFangSC-Regular,PingFang SC",
    },
    retryButton: {
      marginTop: rh(46),
      outline: "none",
      border: "none",
      borderRadius: "6px",
      backgroundColor: colors.primaryText,
      color: "#FFF",
      width: rw(114),
      fontSize: rem(20),
      padding: `${rh(10)} ${rw(15)}`,
      fontFamily: `PingFangSC-Regular,PingFang SC`,
      fontWeight: 400,
    },
  };
});
