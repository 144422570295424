import { useContext } from "react";
import LayoutCTX from "../contexts/Layout.context";

const useLayout = () => {
  const [state, dispatch] = useContext(LayoutCTX.Context);

  const updateState = (value: any) => {
    dispatch({
      type: LayoutCTX.ActionTypes.update,
      payload: value,
    });
  };

  const switchUiSuite = () => {
    dispatch({
      type: LayoutCTX.ActionTypes.switchUi,
    });
  };

  return {
    uiSuite: state.uiSuite,
    updateState,
    switchUiSuite,
  };
};

export default useLayout;
