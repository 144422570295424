import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import classnames from "classnames";

import { useConfirmPage, useLogin, useHistoryOrder } from "../../hooks";
import ModalCover from "../ModalCover/ModalCover";
import useStyles from "./useStyles";

import staffPNG from "../../assets/images/icons/staff.png";
import strings from "../../strings";

import { checkShopNameAndOrderType } from "../../utils/index"

const StaffLoginPage = () => {
  const { setShowStaffLogin } = useConfirmPage();
  useHistoryOrder();
  const {
    login,
    password,
    onPasswordChange,
    onGuestNoChange,
    guestNo,
    loginResponse,
    loginLoading,
    openTableResponse,
    sauceNo,
    onSauceNoChange,
    initSauceNo,
  } = useLogin();

  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    const { hostname } = window.location;
    if (
      hostname === "jackpothotpot.redpayments.com.au" ||
      hostname === "localhost"
    ) {
      initSauceNo();
    }
  }, []);

  return (
    <ModalCover
      dismiss={() => {
        setShowStaffLogin(false);
      }}
    >
      <img src={staffPNG} className={classes.icon} alt="" />
      <div className={classes.title}>{strings.loginPageTitle}</div>
      <div style={{ width: "100%" }}>
        {!localStorage.getItem("aupos_customer_order_salesorder_id") && (
          <input
            type="number"
            placeholder={strings.guestNoPlaceholder}
            className={classes.input}
            value={guestNo}
            onChange={onGuestNoChange}
          />
        )}
        {(checkShopNameAndOrderType("jackpothotpot", "order") ||
          window.location.hostname === "localhost") &&
          !localStorage.getItem("aupos_customer_order_salesorder_id") && (
            <input
              type="number"
              placeholder={strings.sourceNoPlaceholder}
              className={classes.input}
              value={sauceNo}
              onChange={onSauceNoChange}
            />
          )}
        <input
          type="password"
          placeholder={strings.passwordPlaceholder}
          value={password}
          onChange={onPasswordChange}
          className={classnames(classes.input, {
            error: loginResponse.code === 400,
          })}
        />
      </div>
      {(loginResponse.code === 400 || openTableResponse.code === 400) && (
        <div className={classes.errorMessage}>
          {loginResponse.code === 400
            ? loginResponse.message
            : openTableResponse.message}
        </div>
      )}

      <button className={classes.confirmButton} onClick={login}>
        {loginLoading ? strings.sending : strings.confirm}
      </button>
    </ModalCover>
  );
};

export default StaffLoginPage;
