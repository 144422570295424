import React from "react";
import { ConfirmPageContext, MenuContext, CartContext } from "../contexts";

export default () => {
  const [cartState, cartDispatch] = React.useContext(CartContext);
  const [state, dispatch] = React.useContext(ConfirmPageContext);
  const [, menuDispatch] = React.useContext(MenuContext);
  const {
    displayMode, // can be 'CART' or 'ORDER', display shopping cart list and history order list
    showStaffLogin, // bool toggle staff login page show/dismiss
    showOptionModal,
    isShowCommentsModal,
  } = state;

  const [orderComments, setOrderComments] = React.useState("");

  React.useEffect(() => {
    setOrderComments(cartState.orderComments);
  }, []);

  // helper functions - switch between two display modes
  const showOrder = () => {
    dispatch({ type: "SET", payload: { displayMode: "ORDER" } });
  };
  const showCart = () => {
    dispatch({ type: "SET", payload: { displayMode: "CART" } });
  };
  const setShowStaffLogin = (value) => {
    dispatch({ type: "SET", payload: { showStaffLogin: value } });
  };
  const displayOptionModal = (selectedItem) => {
    dispatch({ type: "SET", payload: { showOptionModal: true } });
    menuDispatch({ type: "SET", payload: { selectedItem } });
  };
  const showCommentsModal = () => {
    dispatch({ type: "SET", payload: { isShowCommentsModal: true } });
  };

  const dismissCommentsModal = () => {
    dispatch({ type: "SET", payload: { isShowCommentsModal: false } });
  };

  const orderCommentsOnChange = (e) => {
    setOrderComments(e.target.value);
  };

  const confirmOrderComments = () => {
    cartDispatch({ type: "SET", payload: { orderComments } });
    dismissCommentsModal();
    setOrderComments("");
  };

  const showExpiredModal = () => {
    dispatch({
      type: "SET",
      payload: { isShowExpiredModal: true },
    });
  };

  return {
    displayMode,
    showOrder,
    showCart,
    showStaffLogin,
    setShowStaffLogin,
    showOptionModal,
    displayOptionModal,
    showCommentsModal,
    isShowCommentsModal,
    dismissCommentsModal,
    orderComments,
    orderCommentsOnChange,
    confirmOrderComments,
    expired: state.expired,
    isShowExpiredModal: state.isShowExpiredModal,
    showExpiredModal,
  };
};
