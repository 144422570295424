import React from "react"
import { useTheme } from "react-jss"
// import { useSpring, animated } from "react-spring"

import useStyles from "./useStyles"
import { ListHeader, ScrollContainer } from ".."


export default ({ children, onButtonClick, buttonText, icon, title, backgroundColor, quantity }) => {
    // onButtonClick, buttonText, icon, title, headerColor

    const theme = useTheme()
    const classes = useStyles(theme)
    // const animationProps = useSpring({ from: { transform: 'rotateY(180deg)' }, transform: 'rotateY(0)', config: { duration: 600 } })


    return (
        // <animated.div style={animationProps}>
        <div className={classes.container}>
            <ListHeader
                onButtonClick={onButtonClick}
                buttonText={buttonText}
                icon={icon}
                title={title}
                backgroundColor={backgroundColor}
                quantity={quantity}
            />
            <ScrollContainer
                height={'68vh'}
                backgroundColor={'#FFF'}
            >
                {children}
            </ScrollContainer>
        </div>
        // </animated.div>
    )

}