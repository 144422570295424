import request from "./request"

interface InputValues {
    guestNo: number,
    token: string,
    tableCode: string
}

const openTable = async (inputValues: InputValues) => {
    const resp = await request.post("salesorder", inputValues)
    return resp
}

export default openTable