const hotpotpalace = {
  defaultLanguage: "en",
  multiLanguage: false,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: true,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    OptionsA: 1,
    OptionsB: "A",
    NO: "A",
    POT: "D",
  },
  extraToStock: {
    POT: {
      HP1004: 4,
      HP1003: 2,
      HP1002: 1,
      HP1001: 1,
    },
  },
  bannerStyle: {
    backgroundSize: "cover",
  },
  needPay: false,
};

const jiaojiaoxiaoyu = {
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  defaultLanguage: "cn",
  multiLanguage: true,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    OPTIONS: 1,
  },

  bannerStyle: {
    backgroundSize: "contain",
  },
  needPay: false,
};

const hkyuebaby = {
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  defaultLanguage: "cn",
  multiLanguage: true,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    "1SUGAR": 1,
    "3TOPPING": "A",
    "2ICE": 1,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "45%",
  },
  needPay: false,
};

var order = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: true,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    OptionsA: 1,
    OptionsB: "A",
    NO: "A",
    POT: "D",
  },
  extraToStock: {
    POT: {
      HP1003: 2,
      HP1002: 1,
      HP1001: 1,
    },
  },
  bannerStyle: {
    backgroundSize: "50%",
  },
  welcomeBannerHeight: "30px",
  needPay: false,
};

const dolarshopsydney = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    "(N/A)": 1,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "40%",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const jackpothotpot = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    "(N/A)": "D",
  },
  extraToStock: {
    "(N/A)": {
      G108: -2,
    },
  },
  bannerStyle: {
    backgroundSize: "40%",
    backgroundPosition: "center 2px",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
  sourceBarcode: "SAUCE",
};

const lilongchatswood = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "30%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const xiaochuanyue = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "35%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const goodluckhotpot = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "29%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const zhongyi = {
  defaultLanguage: "cn",
  multiLanguage: true,
  enCode: 2, //language code , description = 1, description2 =2
  cnCode: 1,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "30%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const buffet88ashfield = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "22%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const spicyworld = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "25%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const smilingdumplings = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "27%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const kgalaxyBurwood = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "44%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
  defaultStaffCode: "1",
};

const takasushi = {
  defaultLanguage: "en",
  multiLanguage: false,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "44%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
  defaultStaffCode: "1234",
};

const dumplingco = {
  defaultLanguage: "en",
  multiLanguage: true,
  enCode: 1, //language code , description = 1, description2 =2
  cnCode: 2,
  expired: {
    // in minutes
    timeLimit: false,
    limit: {
      total: 90, // expired notification will be shown, and addDish request will be blocked
      alarm: 20, // countDown clock will turn red when lower than this value
    },
  },
  extraAdv: {
    // 数字为 可选择的数量，例如：1为单选,2为双选...;字母"A"为无限制多选;字母"D"为不同的stock可选数量不一样
    // "(N/A)": 2,
  },
  extraToStock: {},
  bannerStyle: {
    backgroundSize: "30%",
    backgroundPosition: "center center",
  },
  welcomeBannerHeight: "43px",
  needPay: false,
};

const test = {
  ...jackpothotpot,
  needPay: true,
};

export default {
  default: jackpothotpot,
  hotpotpalace,
  hkyuebaby,
  jiaojiaoxiaoyu,
  dolarshopsydney,
  localhost: { ...jackpothotpot, needPay: false },
  order: jackpothotpot,
  "192": jackpothotpot,
  jackpothotpot,
  test,
  lilongchatswood,
  xiaochuanyue,
  goodluckhotpot,
  zhongyi,
  buffet88ashfield,
  spicyworld,
  smilingdumplings,
  "kgalaxy-burwood": kgalaxyBurwood,
  takasushi,
  dumplingco,
};
