import React, { createContext, useReducer } from "react";

interface State {
  historyOrder: any;
  minConsumption: null | number;
}

const initState = {
  historyOrder: {},
  minConsumption: null,
};

enum ActionTypes {
  update = "SET",
}

const reducer = (
  state: State,
  action: { type: ActionTypes; payload: any }
): State => {
  switch (action.type) {
    case ActionTypes.update:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const Context = createContext<[State, Function]>([initState, reducer]);

const Provider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export default {
  Context,
  Provider,
  ActionTypes,
};
