import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    colors,
    funcs: { rw, rh, rem },
    borders,
  } = theme;

  return {
    icon: {
      width: rw(49),
    },
    title: {
      marginTop: rh(20),
      color: colors.lightPrimaryText,
      fontSize: rem(18),
      fontFamily: "PingFangSC-Regular,PingFang SC",
      fontWeight: 400,
      lineHeight: rh(25),
      marginBottom: rh(28),
    },
    input: {
      display: "block",
      height: rem(34),
      width: "100%",
      borderRadius: rem(17),
      textAlign: "center",
      color: colors.primaryText,
      border: borders.input,
      outline: "none",
      fontSize: "1rem",
      marginBottom: rh(15),
      "&:last-child": {
        marginBottom: 0,
      },
      "&:focus": {
        borderColor: colors.main,
      },
      "&.error": {
        borderColor: colors.error,
      },
    },
    errorMessage: {
      width: "100%",
      color: colors.error,
      opacity: 0.75,
      fontFamily: "sans-serif",
      fontSize: rem(8),
      marginTop: rh(20),
    },
    confirmButton: {
      marginTop: rh(20),
      height: rh(40),
      padding: `0 ${rw(35)}`,
      color: "#FFF",
      fontSize: rem(16),
      fontFamily: "PingFangSC-Medium,PingFang SC",
      fontWeight: 500,
      backgroundColor: colors.main,
      borderRadius: "6px",
      border: "none",
      outline: "none",
    },
  };
});

export default useStyles;
