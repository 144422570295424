import React, { useContext, useEffect } from "react";
import { useLastLocation } from "react-router-last-location";

import { useMenu } from "../../hooks";
import { MenuContext } from "../../contexts";
import { Context as AppCTX } from "../../contexts/App.context";
import WelcomeCtx from "../../contexts/welcome-modal.context";

import {getOrderType} from "../../utils/index"

import {
  Header,
  Menu,
  SearchBar,
  MenuHeader,
  ShoppingCartButton,
  WelcomeModal,
} from "../../components";

let firstTimeRender = true;

export default () => {
  // get dishes from API, all dishes grouped by categories
  const { getStockList } = useMenu();
  const [appState] = useContext(AppCTX);
  const { cnCode, enCode } = appState;
  useEffect(() => {
    if (enCode !== null && cnCode !== null) {
      getStockList();
    }
  }, [enCode, cnCode]);

  const lastLocation = useLastLocation();
  const [welcomeState, welcomeDispatch] = useContext(WelcomeCtx.Context);

  if (firstTimeRender && lastLocation === null) {
    const action = {
      type: WelcomeCtx.ActionTypes.update,
      payload: {
        show: true,
      },
    };
    welcomeDispatch(action);
    firstTimeRender = false;
  }

  // menu context
  const [menuState, menuDispatch] = React.useContext(MenuContext);
  const { searchKeyword, showSearchInput } = menuState;

  /* component state  */

  if (
    getOrderType() !== "preorder" &&
    window.location.hostname !== "preorder.xiyantong.pw" &&
    window.location.hostname !== "localhost"
  )
    return null;

  return (
    <div style={{ backgroundColor: "#FFF" }}>
      {welcomeState.show && <WelcomeModal />}
      <Header size="lg" search={showSearchInput}>
        {showSearchInput ? (
          <SearchBar
            inputValue={searchKeyword}
            onInputChange={(e) => {
              menuDispatch({
                type: "SET",
                payload: { searchKeyword: e.target.value },
              });
            }}
            hideSearch={() => {
              menuDispatch({
                type: "SET",
                payload: { searchKeyword: "", showSearchInput: false },
              });
            }}
          />
        ) : (
          <MenuHeader
            showSearch={() => {
              menuDispatch({ type: "SET", payload: { showSearchInput: true } });
            }}
          />
        )}
      </Header>
      <Menu />
      <ShoppingCartButton />
    </div>
  );
};
