import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {

    const { funcs: { rh }, extends: { horizontalFlexbox }, colors } = theme
    return {
        container: {
            ...horizontalFlexbox,
            backgroundColor: 'transparent',
            color: '#6D7278',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: rh(50),
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            overflow: 'hidden',
            width: 'calc(100% - 2rem)',
            margin: 'auto',
            boxShadow: '0px 9px 9px -9px rgba(0,0,0,0.11) ,0px 9px 9px 0px rgba(0,0,0,0.11)'
            // padding: '0 0.5rem',
        },
        tab: {
            ...horizontalFlexbox,
            width: '50%',
            height: rh(46),
            boxShadow: `0px 2px 9px 0px rgba(0,0,0,0.05)`,
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: '#FFF',
            transition: 'all 300ms',
            "&.activeLeft": {
                transform: 'skew(10deg) scaleX(1.1) translateX(3px)',
                height: rh(50),
                backgroundColor: colors.main,
                color: '#FFF',

            },
            "&.activeRight": {
                transform: 'skew(-10deg) scaleX(1.1) translateX(-3px)',
                height: rh(50),
                backgroundColor: colors.main,
                color: '#FFF',
            }
        },
        tabLabel: {
            "&.activeLeft": {
                transform: `skew(-10deg) scalex(${1 / 1.1}) translateX(-3px)`,
            },
            "&.activeRight": {
                transform: `skew(10deg) scalex(${1 / 1.1}) translateX(3px)`,

            }
        }
    }
})

export default useStyles