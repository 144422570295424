import React, { useState, useContext } from "react";
import {
  LoadingContext,
  ApiResponseContext,
  CartContext,
  MenuContext,
} from "../contexts";
import { useParams } from "react-router";
import useAddDish from "./useAddDish";
import { getStaffToken, openTable } from "../apis";
import strings from "../strings";

export default () => {
  const [password, setPassword] = useState("");
  const [guestNo, setGuestNo] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sauceNo, setSauceNo] = useState("");
  const [cartState, cartDispatch] = useContext(CartContext);
  const [menuState] = useContext(MenuContext);
  const { categories } = menuState;
  const [loadingState, loadingDispatch] = useContext(LoadingContext);

  const [responseState, responseDispatch] = useContext(ApiResponseContext);
  const { loginResponse, openTableResponse } = responseState;

  const { tableCode } = useParams();
  const { addDish } = useAddDish();

  const login = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    // read data from localStorage
    const salesorderId = localStorage.getItem(
      "aupos_customer_order_salesorder_id"
    );
    if (loadingState.login) return; // prevent duplicate api calls
    try {
      loadingDispatch({ type: "SET", payload: { login: true } });
      responseDispatch({
        type: "SET",
        payload: { loginResponse: { code: 100, message: "silence" } },
      });
      const resp = await getStaffToken({ barcode: password, tableCode });

      if (resp.data.code == "0") {
        /*login success*/
        setIsLoggedIn(true);
        responseDispatch({
          type: "SET",
          payload: { loginResponse: { code: 200, message: "success" } },
        });
        localStorage.setItem(
          "aupos_customer_order_staff_token",
          resp.data.data
        );
        const now = new Date();
        localStorage.setItem(
          "aupos_customer_order_staff_token_expired_datetime",
          now.getTime().toString()
        );
        if (salesorderId) {
          /** table already opened addDish directly */
          await addDish();
        } else {
          /** table not opened yet */
          // openTable first
          try {
            let openTableResp = await openTable({
              token: resp.data.data,
              tableCode,
              guestNo,
            });
            if (openTableResp.data.code == "0") {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: { code: 200, message: "success" },
                },
              });

              localStorage.setItem(
                "aupos_customer_order_salesorder_id",
                openTableResp.data.data.salesorderId
              );
              //addDish
              await addDish({
                salesorderId: openTableResp.data.data.salesorderId,
              });
            } else if (openTableResp.data.code == "3000") {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: {
                    code: 400,
                    message: strings.openTableFailMessage1,
                  },
                },
              });
              //stop process
              loadingDispatch({ type: "SET", payload: { login: false } });
            } else if (openTableResp.data.code == "3001") {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: {
                    code: 400,
                    message: strings.openTableFailMessage2,
                  },
                },
              });
              //stop process
              loadingDispatch({ type: "SET", payload: { login: false } });
            } else if (openTableResp.data.code == "2000") {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: {
                    code: 400,
                    message: strings.openTableFailMessage3,
                  },
                },
              });
              //stop process
              loadingDispatch({ type: "SET", payload: { login: false } });
            } else {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: {
                    code: 400,
                    message: strings.openTableFailMessage4,
                  },
                },
              });
              //stop process
              loadingDispatch({ type: "SET", payload: { login: false } });
            }
          } catch (error) {
            if (error.response) {
              if (error.response.data.code == "3000") {
                responseDispatch({
                  type: "SET",
                  payload: {
                    openTableResponse: {
                      code: 400,
                      message: strings.openTableFailMessage1,
                    },
                  },
                });
                //stop process
                loadingDispatch({ type: "SET", payload: { login: false } });
              } else if (error.response.data.code == "3001") {
                responseDispatch({
                  type: "SET",
                  payload: {
                    openTableResponse: {
                      code: 400,
                      message: strings.openTableFailMessage2,
                    },
                  },
                });
                //stop process
                loadingDispatch({ type: "SET", payload: { login: false } });
              } else if (error.response.data.code == "2000") {
                responseDispatch({
                  type: "SET",
                  payload: {
                    openTableResponse: {
                      code: 400,
                      message: strings.openTableFailMessage3,
                    },
                  },
                });
                //stop process
                loadingDispatch({ type: "SET", payload: { login: false } });
              } else {
                responseDispatch({
                  type: "SET",
                  payload: {
                    openTableResponse: {
                      code: 400,
                      message: strings.openTableFailMessage4,
                    },
                  },
                });
                //stop process
                loadingDispatch({ type: "SET", payload: { login: false } });
              }
            } else {
              responseDispatch({
                type: "SET",
                payload: {
                  openTableResponse: {
                    code: 400,
                    message: strings.openTableFailMessage4,
                  },
                },
              });
              //stop process
              loadingDispatch({ type: "SET", payload: { login: false } });
            }
          }
        }
      } else {
        setIsLoggedIn(false);
        responseDispatch({
          type: "SET",
          payload: {
            loginResponse: { code: 400, message: strings.passwordError },
          },
        });
        loadingDispatch({ type: "SET", payload: { login: false } });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.code === "2000") {
          setIsLoggedIn(false);
          responseDispatch({
            type: "SET",
            payload: {
              loginResponse: { code: 400, message: strings.passwordError },
            },
          });
          loadingDispatch({ type: "SET", payload: { login: false } });
        } else {
          responseDispatch({
            type: "SET",
            payload: {
              loginResponse: {
                code: 400,
                message: strings.internetConnectionError,
              },
            },
          });
          setIsLoggedIn(false);
          loadingDispatch({ type: "SET", payload: { login: false } });
        }
      } else {
        responseDispatch({
          type: "SET",
          payload: {
            loginResponse: {
              code: 400,
              message: strings.internetConnectionError,
            },
          },
        });
        setIsLoggedIn(false);
        loadingDispatch({ type: "SET", payload: { login: false } });
      }
    }
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onGuestNoChange = (e) => {
    setGuestNo(e.target.value);
  };

  const onSauceNoChange = (e) => {
    setSauceNo(e.target.value);
    let jackPotSauce = {};
    categories.forEach((cate) => {
      cate.stocks.forEach((stock) => {
        if (stock.barcode === "SAUCE") {
          jackPotSauce = stock;
        }
      });
    });

    // add sources to cart
    if (jackPotSauce.barcode) {
      cartDispatch({
        type: "ADD_SAUCE_JACKPOT",
        payload: {
          ...jackPotSauce,
          quantity: e.target.value !== "" ? parseInt(e.target.value) : 0,
        },
      });
    }
  };

  const initSauceNo = () => {
    const { cartList } = cartState;
    const jackPotSauce = cartList.find((x) => x.barcode === "SAUCE");
    if (jackPotSauce) {
      setSauceNo(jackPotSauce.quantity);
    }
  };

  React.useEffect(() => {
    const token = localStorage.getItem("aupos_customer_order_staff_token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return {
    login,
    password,
    isLoggedIn,
    onPasswordChange,
    guestNo,
    onGuestNoChange,
    loginResponse,
    loginLoading: loadingState.login,
    openTableResponse,
    sauceNo,
    onSauceNoChange,
    initSauceNo,
  };
};
