import React from "react"
import { createUseStyles } from "react-jss"

export default ({ children, ...props }) => {
    const classes = useStyles()


    return (
        <div className={classes.container}>
            {children}
        </div>
    )
}


const useStyles = createUseStyles({
    container: {
        position: 'fixed',
        zIndex: 5,
        height: '3.375rem',
        bottom: '0.5625rem',
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        width: '100%'
    }
})