import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";

import {
  useConfirmPage,
  useShoppingCart,
  useLogin,
  useAddDish,
  useHistoryOrder,
  useAppSetting,
} from "../../hooks";
import {
  ConfirmPageHeader,
  Footer,
  AddDishResponsePage,
  StaffLoginPage,
  ExpiredModal,
} from "../../components";
import CartList from "./CartList";
import OrderList from "./OrderList";
import useStyles from "./useStyles";
import CommentModal from "./CommentModal";
import strings from "../../strings";

// import editIcon from "../../assets/images/icons/edit.png";
// import dollarIcon from "../../assets/images/icons/dollar.png";
import OptionModal from "../../components/Menu/OptionModal";
import { withRouter, useParams, RouteComponentProps } from "react-router";
import PreorderConfirm from "./PreorderConfirm";

import {checkShopNameAndOrderType, getOrderType} from "../../utils/index"
import { checkIsMiniProgram } from "../../utils/miniapp";

import PaymentCover from "../../components/PaymentCover/PaymentCover.component"

const ConfirmPage = ({ history }: RouteComponentProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { tableCode } = useParams() as any;
  const [isSubmitting, setSubmitting] = useState(false);

  const [paymentOpen, setPaymentOpen] = useState(false);
  const handlePaymentClose = ()=>{
    setPaymentOpen(false)
  }


  const {
    displayMode,
    showStaffLogin,
    setShowStaffLogin,
    showOptionModal,
    isShowCommentsModal,
    showCommentsModal,
    showCart,
    expired,
    showExpiredModal,
    isShowExpiredModal,
  } = useConfirmPage();
  const {
    totalAmount: cartTotal,
    totalQuantity: cartQuantity,
  } = useShoppingCart();
  const { isLoggedIn } = useLogin();
  const {
    addDishResponse,
    addDish,
    addDishToKitchenWithoutAuthensication,
  } = useAddDish();
  const {
    subTotal: historyTotal,
    quantity: historyQuantity,
    updateMinConsumption,
    // minConsumption,
  } = useHistoryOrder();
  const { needPay, defaultStaffCode } = useAppSetting();

  /** handle footer button onClick
   * if user are in cart list then submit order
   * if user are in order list then back to menu page
   */
  const onSubmit = async () => {
    if (
      (displayMode === "CART" && cartQuantity <= 0) || // if cart is empty should return to menu without any further api calls
      displayMode === "ORDER" || // if on history orderList page
      expired //if table is expired return to menu
    ) {
      showCart();
      history.push(`${process.env.PUBLIC_URL}/${tableCode}`);
      return; // function finised, stop here!
    }

    if(checkIsMiniProgram()){
      setPaymentOpen(true)
      return
    }

    // if is calling apis prevent duplicate call
    if (isSubmitting) return; // function finised, stop here!
    setSubmitting(true); // block submit button

    // if user is loggedIn and defaultStaffCode is not set, then add dish
    if (
      !defaultStaffCode &&
      isLoggedIn &&
      localStorage.getItem("aupos_customer_order_salesorder_id")
    ) {
      await addDish();
      setSubmitting(false); // release submit button
    } else if (defaultStaffCode) {
      const resp = await addDishToKitchenWithoutAuthensication(
        defaultStaffCode,
        tableCode
      );
      if (resp !== null) {
        setSubmitting(false);
        return;
      } else {
        setSubmitting(false);
      }
    }

    // if user is not logged in ask them to enter staff password
    if (
      !isLoggedIn ||
      !localStorage.getItem("aupos_customer_order_salesorder_id")
    ) {
      setShowStaffLogin(true);
      setSubmitting(false); // release submit button
    }

    return;
  };

  React.useEffect(() => {
    if (expired) {
      showExpiredModal();
    }
  }, [expired]);

  React.useEffect(() => {
    const { hostname } = window.location;
    if (
      hostname === "localhost" ||
      checkShopNameAndOrderType("jackpothotpot", "order")
    ) {
      if (tableCode === "大包") {
        updateMinConsumption(888);
      }

      if (tableCode === "小包") {
        updateMinConsumption(688);
      }

      if (tableCode !== "大包" && tableCode !== "小包") {
        updateMinConsumption(null);
      }
    } else {
      updateMinConsumption(null);
    }
  }, [tableCode, window.location.hostname]);

  /**
   * function - determine web application running as preorder or table-order according with website hostname
   */
  const isPreorder = () => {
    return getOrderType() === "preorder" ? true : false
  };

  return (
    <div>
      <ConfirmPageHeader />
      {isShowExpiredModal && <ExpiredModal />}
      {isPreorder() && <PreorderConfirm />}
      {!isPreorder() && (displayMode === "CART" ? <CartList /> : <OrderList />)}
      {addDishResponse.code !== 100 && <AddDishResponsePage />}
      {showStaffLogin && <StaffLoginPage />}
      {isShowCommentsModal && <CommentModal />}
      {showOptionModal && <OptionModal mode={"ORDER_ITEM"} />}
      {!isPreorder() && (
        <Footer>
          <div className={classes.footerBotton}>
            <div className={classes.amountGrid}>
              <div className={classes.amount}>
                $ {displayMode === "CART" ? cartTotal : historyTotal}
              </div>
              <div className={classes.quantity}>
                {displayMode === "CART"
                  ? strings.formatString(strings.confirmTotal, cartQuantity)
                  : strings.formatString(strings.confirmTotal, historyQuantity)}
                {/* {displayMode !== "CART" &&
                minConsumption !== null &&
                minConsumption > Number(historyTotal)
                  ? `, ${strings.underMinConsumption} $${minConsumption.toFixed(
                      2
                    )}`
                  : null} */}
              </div>
            </div>
            <div
              className={classes.commentGrid}
              onClick={() => {
                if (cartQuantity > 0) {
                  showCommentsModal();
                }
              }}
            >
              {strings.orderComments}
            </div>
            <div className={classes.submitGrid} onClick={onSubmit}>
              {expired && strings.back}
              {displayMode === "CART" &&
              cartQuantity > 0 &&
              !isSubmitting &&
              !expired &&
              needPay
                ? strings.makePayment
                : strings.orderConfirm}
              {displayMode === "CART" &&
                cartQuantity > 0 &&
                isSubmitting &&
                !expired &&
                strings.sending}
              {displayMode === "CART" &&
                cartQuantity <= 0 &&
                !expired &&
                strings.back}
              {displayMode === "ORDER" && !expired && strings.back}
            </div>
          </div>
        </Footer>
      )}
      <PaymentCover open={paymentOpen} onClose={handlePaymentClose}/>
    </div>
  );
};

export default withRouter(ConfirmPage);
