import React, { useContext, useEffect } from "react";
import PaymentCtx from "../../contexts/Payment.context";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

const PayForm = () => {
  const [state] = useContext(PaymentCtx.Context);
  const history = useHistory();

  useEffect(() => {
    const dom = document.getElementById("alipaysubmit");

    if (dom && window.location.search === "") {
      dom.submit();
    }

    if (window.location.search !== "") {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
  }, []);

  return <div>{ReactHtmlParser(state.payform)}</div>;
};

export default PayForm;
