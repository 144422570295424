import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {
    return {
        container: {
            height: `calc(93vh - 5rem)`,
            backgroundColor: theme => theme.backgroundColor || '#FFF',
            overflow: 'scroll',
            width: 'calc(100% - 2rem)',
            margin: 'auto',
            boxShadow: '0px 9px 9px -9px rgba(0,0,0,0.11) ,0px 9px 9px 0px rgba(0,0,0,0.11)',
        },
        content: {
            minHeight: '101%',
            paddingBottom: '15rem'
        }
    }
})

export default useStyles