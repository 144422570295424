import ShoppingCartHelperFuncs from "./ShoppingCartHelper";

import {checkIsMiniProgram} from "./miniapp"

const defaultShopName = "gongcha"

export * from "./theme.utils";
export const ShoppingCartHelper = ShoppingCartHelperFuncs;

// const hostDomain = "redpayments.com.au";

// URL type: "hyphen" when "kgalaxy-burwood-qrorder.redpayments.com.au"
//           "dot" when "kgalaxy-burwood.order.redpayments.com.au"
export const getUrlType = (hostname) => {
  if(hostname === "localhost") return "hyphen";
  return hostname.split('.')[0].includes("order") ? "hyphen" : "dot"
}

export const getStoreName = () => {
  const { hostname } = window.location;
  if(hostname === "localhost") return defaultShopName;
  const urlType = getUrlType(hostname);
  if(urlType === "hyphen"){
    const nameFrames = hostname.split('.')[0].split('-');
    nameFrames.pop();
    return nameFrames.join('-')
  } else {
    return hostname.substr(0, hostname.indexOf("."));
  }
};

export const getApiName = () => {
  const { hostname } = window.location;
  console.log("hostname", hostname)
  if(hostname === "localhost") return `${defaultShopName}.api.redpayments.com.au`;
  const urlType = getUrlType(hostname);
  console.log("urlType", urlType)
  if(urlType === "hyphen"){
    if(checkIsMiniProgram()){
      return hostname.replace(/-[^-]*?order\./, '-api.')
    }
    return hostname.replace(/-[^-]*?order\./, '.api.')
  } else {
    let domainArray = hostname.split(".");
    domainArray[1] = "api";
    console.log("urlType-dot", domainArray.join("."))
    return domainArray.join(".");
  }
}

// order type could be "order" or "preorder"
export const getOrderType = () => {
  const { hostname } = window.location;
  if(hostname === "localhost") return "order";
  const urlType = getUrlType(hostname);
  if(urlType === "hyphen"){
    if(hostname.match(/-telorder\./)) return "order";
    return "preorder"
  } else {
    if(hostname.match(/\.order\./)) return "order";
    return "preorder"
  }
}

export const checkShopNameAndOrderType = (shopName = "", orderType = "") => {
  if(shopName && getStoreName() !== shopName){
    return false;
  }
  if(orderType && getOrderType() !== orderType){
    return false;
  }
  return true;
}

export const getDomainName = (hostname) => {
  if (hostname === "localhost") return "redpayments.com.au"
  const matches = hostname.match(/order\.(.*)/)
  if(matches && matches.length >= 2) return matches[1]
  return "redpayments.com.au"
}

export const getHostByShopNameAndOrderType = (shopName = "", orderType = "") => {
  const { hostname } = window.location;
  if(hostname === "localhost") return `${defaultShopName}.order.redpayments.com.au`
  const urlType = getUrlType(hostname);
  const doaminName = getDomainName(hostname);
  if(urlType === "hyphen") {
    return `${shopName}-${orderType === "order"?"telorder":"qrorder"}.${doaminName}`;
  } 
  return `${shopName}.${orderType === "order"?"order":"preorder"}.${doaminName}`;
}

export const getPayway = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const isWechat = ua.match(/MicroMessenger/i) == "micromessenger";

  return isWechat ? "WECHAT" : "ALIPAY";
};

export const secondToMinutesStr = (seconds) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0')
  return `${parseInt(seconds/60)}:${zeroPad(parseInt(seconds%60),2)}`
}

export const amountToStr = (seconds) => {
  return seconds.toFixed(2)
}