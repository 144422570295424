import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    funcs: { rem, rw, rh },
    colors,
  } = theme;
  const buttonSize = {
    height: rem(24),
    width: rem(24),
    border: "none",
    outline: "none",
    padding: 0,
    borderRadius: "50%",
    fontSize: "0.875rem",
    backgroundColor: "#FFF",
    "&:active": {
      opacity: 0.3,
    },
  };

  return {
    iconButton: {
      ...buttonSize,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      // marginLeft: rem(6),
    },
    numberButton: {
      ...buttonSize,
      marginLeft: rem(6),
      color: "#fff",
      backgroundColor: colors.main,
    },
    textButton: {
      ...buttonSize,
      margin: 0,
      borderRadius: rem(12),
      minWidth: rem(24),
      width: "max-content",
      backgroundColor: colors.main,
      color: colors.buttonText,
      fontFamily: "Pingfang",
    },
    button: {
      height: rh(40),
      padding: `0 ${rw(26)}`,
      color: "#FFF",
      fontSize: rem(16),
      fontFamily: "PingFangSC-Medium,PingFang SC,PingFang",
      fontWeight: 500,
      backgroundColor: colors.main,
      borderRadius: "6px",
      border: "none",
      outline: "none",
    },
    "icon-button-svg": {
      "& #add-circular-outlined-button": {
        fill: colors.main,
      },
      "& #Path": {
        fill: colors.buttonText,
      },
      "& #Shape": {
        fill: colors.main,
      },
      "& rect": {
        fill: colors.main,
      },
    },
  };
});

export default useStyles;
