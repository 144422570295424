import React from "react";
import queryString from "query-string";
import { Redirect, useParams } from "react-router-dom";

export default () => {
  let isPaymentSuccess = false;

  const { payway } = useParams();
  const parsedSearch = queryString.parse(window.location.search);

  if (payway === "WECHAT") {
    isPaymentSuccess = parsedSearch.done === "1";
  } else {
    isPaymentSuccess = parsedSearch.trade_status === "TRADE_FINISHED";
  }

  if (isPaymentSuccess) {
    return <Redirect to={`${process.env.PUBLIC_URL}/confirm/preorder`} />;
  }

  return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
};
