import React, { useState } from "react";
import classnames from "classnames";
import { useAppSetting } from "../../hooks";
import strings from "../../strings";

interface Props {
  extraGroup: Object;
  formatExtraName: Function;
  selectedOptions: any[];
  clickOption: Function;
  isCn: boolean;
  classes: any;
  itemBarcode: string;
}

const ExtraGroup = ({
  extraGroup,
  formatExtraName,
  selectedOptions,
  clickOption,
  isCn,
  classes,
  itemBarcode,
}) => {
  const { isForcedMultipleChoice, getNumberOfChoices } = useAppSetting();
  const calcNumberOfChoicesMade = () => {
    return selectedOptions.reduce((sum, option) => {
      if (extraGroup.extra.find((extra) => extra.barcode === option.barcode)) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);
  };
  return (
    <React.Fragment>
      <div className={classes.subTitle}>
        <span>
          {extraGroup.custom1 !== ""
            ? formatExtraName(extraGroup.custom1)
            : "Extra"}
        </span>
        {/* notifications: for multiple choices options */}
        {isForcedMultipleChoice(extraGroup.custom1, itemBarcode) && (
          <span>
            {strings.formatString(
              strings.introForcedChoices,
              <span style={{ fontFamily: "sans-serif" }}>
                {getNumberOfChoices(extraGroup.custom1, itemBarcode)}
              </span>
            )}
          </span>
        )}
        {isForcedMultipleChoice(extraGroup.custom1, itemBarcode) && (
          <span
            style={{
              fontFamily: "sans-serif",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {calcNumberOfChoicesMade()}/
            {getNumberOfChoices(extraGroup.custom1, itemBarcode)}
          </span>
        )}
      </div>
      <div className={classes.gridContainer}>
        {extraGroup.extra.map((e, i) => {
          return (
            <div
              key={`extra-${i}`}
              className={classnames(classes.gridItem, {
                active: selectedOptions.find((x) => x.barcode === e.barcode),
              })}
              onClick={() => {
                clickOption(e);
              }}
              style={
                e.price !== undefined &&
                e.price !== null &&
                typeof parseFloat(e.price) === "number" &&
                parseFloat(e.price) > 0
                  ? {
                      justifyContent: "space-between",
                      height: "3.25rem",
                    }
                  : {}
              }
            >
              <span>{isCn ? e.description1 : e.description2}</span>
              {e.price !== undefined &&
                e.price !== null &&
                typeof parseFloat(e.price) === "number" &&
                parseFloat(e.price) > 0 && (
                  <span style={{ fontSize: "0.5rem" }}>{`$${parseFloat(
                    e.price
                  ).toFixed(2)}`}</span>
                )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ExtraGroup;
