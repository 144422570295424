import React, { createContext, useReducer } from "react";

enum UiSuites {
  easi = "EASI",
  chowbus = "CHOWBUS",
}

interface State {
  uiSuite: UiSuites;
}

enum ActionTypes {
  update = "SET",
  reset = "RESET",
  switchUi = "SWITCH",
}

const initState = {
  uiSuite: UiSuites.easi,
};

const reducer = (
  preState: State,
  action: { type: ActionTypes; payload: any }
): State => {
  switch (action.type) {
    case ActionTypes.update:
      return { ...preState, ...action.payload };
    case ActionTypes.reset:
      return initState;
    case ActionTypes.switchUi:
      return {
        ...preState,
        uiSuite:
          preState.uiSuite === UiSuites.easi ? UiSuites.chowbus : UiSuites.easi,
      };
    default:
      return preState;
  }
};

const Context = createContext<[State, Function]>([initState, reducer]);

const Provider = ({ children }: { children: any }) => {
  const hook = useReducer(reducer, initState);

  return <Context.Provider value={hook}>{children}</Context.Provider>;
};

export default {
  Provider,
  ActionTypes,
  Context,
};
