import Axios, { AxiosInstance } from "axios";
import { getApiName, getHostByShopNameAndOrderType } from "../utils/index"
// use for finalize production version
// const baseURL = `http://${window.location.hostname}/staff_order/api/public/api/`// const baseURL = "http://192.168.1.13:8000/api/";
const xinhaocai = "http://gongcha.api.redpayments.com.au/";
const xiyantongHomeApi = "http://test.xiyantong.pw/";
const mtdevserver = "http://mtoffice.xiyantong.pw/";
const lilongchatswood = "http://lilongchatswood.api.redpayments.com.au/";
const zhongyi = "http://zhongyi.api.redpayments.com.au";
const smilingdumplings = "http://smilingdumplings.api.redpayments.com.au/";
const yuebaby = "http://hkyuebaby.api.redpayments.com.au/";
const spicyworld = "http://spicyworld.api.redpayments.com.au";
const kgalaxyBurwood = "http://kgalaxy-burwood.api.redpayments.com.au/";
const hotpotpalace = "http://hotpotpalace.api.redpayments.com.au/";
const devBaseUrl = xinhaocai;
// const testBaseUrl = "http://hkyuebaby.api.redpayments.com.au/"
const testBaseUrl = xiyantongHomeApi;


const getBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === "order.xiyantong.pw") return devBaseUrl;
  if (hostname === "preorder.xiyantong.pw") return "http://api.xiyantong.pw/";
  if (
    hostname === "localhost" ||
    hostname === "kidsnparty.com.au" ||
    hostname.includes("192.") ||
    hostname === "order.xiyantong.pw"
  ) {
    return devBaseUrl;
  } else if (
    hostname === getHostByShopNameAndOrderType("test", "preorder") ||
    hostname === getHostByShopNameAndOrderType("test", "order")
  ) {
    return testBaseUrl;
  } else {
    // const indexOfFirstDot = hostname.indexOf(".");
    // return `${window.location.protocol}//api${hostname.substr(0,indexOfFirstDot)}`
    // let domainArray = hostname.split(".");
    // domainArray[1] = "api";
    return `${window.location.protocol}//${getApiName()}`;
  }
};

const request: AxiosInstance = Axios.create({
  baseURL: getBaseUrl(),
});

request.interceptors.request.use(
  (config) => {
    // change headers sample
    // config.headers['Content-Type'] = 'application/json';
    config.data = new URLSearchParams(body(config.data));
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const body = (data: Object): Array<Array<string>> => {
  const values = data as { [key: string]: number | string };
  let res: Array<any> = [];
  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      res = [...res, [key, values[key]]];
    }
  }
  return res;
};

// request.defaults.headers.common['token'] = localStorage.getItem('aupos_staff_order_token') || "";

export default request;
