import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    colors,
    funcs: { rw, rem },
    extends: { horizontalFlexbox },
  } = theme;
  return {
    addComments: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      paddingLeft: "8.26667%",
    },
    addCommentsIcon: {
      height: "1rem",
      width: "1rem",
      marginRight: "0.5625rem",
    },
    addCommentsText: {
      fontSize: "1rem",
      color: colors.primaryText,
    },
    submitOrder: {
      height: "calc(100% - 6px)",
      width: "66.6667%",
      borderRadius: "6px",
      backgroundColor: colors.footerButton,
      color: "#FFF",
      paddingLeft: "1.0625rem",
      paddingRight: "2.1875rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "1rem",
    },
    submitOrderPrice: {
      display: "flex",
      alignItems: "center",
    },
    submitOrderPriceIcon: {
      height: "1.25rem",
      width: "1.25rem",
      marginRight: "0.375rem",
    },
    submitOrderPriceVaule: {
      fontFamily: "PingFangSC",
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "-0.39px",
      color: "#FFF",
    },
    submitOrderText: {
      fontFamily: "PingFangSC",
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "-0.39px",
      color: "#FFF",
    },
    preorderRoot: {
      height: "calc(100vh - 7rem)",
      width: "100%",
      overflow: "scroll",
    },
    preorderScroll: {
      minHeight: "101%",
      height: "max-content",
      paddingBottom: "5rem",
    },
    qrcodeContainer: {
      height: "30vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "10px 15px",
    },
    preorderInstruction: {
      marginTop: "1rem",
      fontSize: "13px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      color: "#4A4A4A",
    },
    footerBotton: {
      width: rw(322),
      display: "grid",
      gridTemplateColumns: `1fr max-content max-content`,
      borderRadius: "27px",
      overflow: "hidden",
      boxShadow: "0px 4px 20px 0px rgba(32,35,38,0.15)",
    },
    amountGrid: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "4px 0 7px 25px",

      gridColumn: "1/2",
      backgroundColor: "#202326",
      boxShadow:
        "0px 4px 20px 0px rgba(32,35,38,0.15),0px 3px 6px 2px rgba(32,35,38,0.1),0px 2px 4px 0px rgba(32,35,38,0.1)",
    },
    amount: {
      fontSize: rem(18),
      color: "#FFF",
      fontWeight: "bold",
      fontFamily: "Poppins-Medium",
      letterSpacing: "2px",
      marginBottom: "-2px",
    },
    quantity: {
      fontSize: "10px",
      color: "#FFF",
      fontFamily: "PingFang",
    },
    commentGrid: {
      ...horizontalFlexbox,
      gridColumn: "2/3",
      backgroundColor: "#474747",
      boxShadow: "0px 4px 20px 0px rgba(32,35,38,0.15)",
      textShadow: "0px 4px 20px rgba(32,35,38,0.15)",
      fontWeight: "bold",
      color: colors.shoppingCartLabel,
      padding: "0 0.5rem",
      minWidth: rw(78),
      textTransform: "capitalize",
    },
    submitGrid: {
      ...horizontalFlexbox,
      gridColumn: "3/4",
      background: `linear-gradient(121deg,${colors.mainLight} 0%,${colors.main} 100%)`,
      boxShadow: "0px 4px 20px 0px rgba(32,35,38,0.15)",
      fontWeight: "bold",
      color: "#FFF",
      textShadow: "0px 4px 20px rgba(32,35,38,0.15)",
      padding: "0 0.5rem",
      minWidth: rw(85),
    },
  };
});

export default useStyles;
