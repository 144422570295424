const reducer = (state, action) => {
    switch (action.type) {
        case "SET":

            return { ...state, ...action.payload };

        default:
            return state;
    }
}

const initState = {
    addDishResponse: { code: 100, message: 'silence' },
    loginResponse: { code: 100, message: 'silence' },
    openTableResponse: { code: 100, message: 'silence' }
}

export default [reducer, initState]