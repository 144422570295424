import React from "react"

const PaymentFail = () => {
    return(
        <div>
            Payment Fail
        </div>
    )
}

export default PaymentFail