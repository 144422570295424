import React, { useContext } from "react";
import { LanguageContext } from "../../contexts";
import { useTheme } from "react-jss";
import useStyles from "./useStyles";

export default ({ children, onClick, style }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state] = useContext(LanguageContext);
  const { isCn } = state;

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={classes.textButton}
      style={{ ...style, fontFamily: isCn ? "Pingfang" : "Lato" }}
    >
      {children}
    </button>
  );
};
