import React from 'react'
import { useTheme } from "react-jss"

import useStyles from "./useStyles"
import strings from "../../strings"

import {checkShopNameAndOrderType} from "../../utils/index"

export default ({ item, className }) => {

    const theme = useTheme()
    const classes = useStyles(theme)

    if (window.location.hostname === "localhost" || checkShopNameAndOrderType("dolarshopsydney", "order")) {
        if (item.barcode === "A001") {
            return null;
        }
    }

    return (
        <div className={className}>
            <div className={classes.choiceButton}>
                {strings.choiceButton}
            </div>
        </div>
    )
}