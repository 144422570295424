import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {

    const { colors, funcs: { rem } } = theme

    return {
        icon: {
            height: '30%',
            width: rem(17)
        },
        input: {
            fontSize: '1rem',
            height: '2rem',
            outline: 'none',
            border: `1px solid ${colors.greyInputBorder}`,
            borderRadius: '1rem',
            paddingLeft: '1rem',
            backgroundClip: 'padding-box'
        },
        cancelButton: {
            color: colors.main,
            backgroundColor: 'transparent',
            fontSize: '0.875rem',
            fontFamily: 'Arial',
            letterSpacing: '1px'
        }
    }
})

export default useStyles