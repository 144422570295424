import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    funcs: { rem },
  } = theme;

  return {
    icon: {
      height: "30%",
      width: rem(17),
    },
    logo: {
      height: "90%",
      width: "80%",
      objectFit: "contain",
    },
    textLogo: {
      fontSize: "2rem",
      fontWeight: "bold",
      letterSpacing: "4px",
      color: "#574E4E",
      fontFamily: `'Dancing Script', cursive`,
    },
    tel: {
      fontSize: "10px",
      fontWeight: "normal",
      position: "absolute",
      bottom: 0,
      right: rem(17),
      color: theme.colors.secondaryText,
    },
  };
});

export default useStyles;
