/**
 * information of order items - ie. name, price all those stuff which are only used for display purpose not interatctable
 */

import React from "react";
import { useTheme } from "react-jss";

import { LanguageContext } from "../../contexts";
import useStyles from "./useStyles";
// import iconPNG from "../../assets/images/icons/edit.png"

export default ({ item, className, nameColor, editable, displayPrice }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [state] = React.useContext(LanguageContext);
  const { isCn } = state;

  const getUnitPrice = () => {
    let unitPrice =
      item.selectedSize && item.selectedSize.price
        ? item.selectedSize.price
        : item.price;

    if (item.selectedOptions) {
      item.selectedOptions.forEach((o) => {
        unitPrice += o.price;
      });
    }

    return parseFloat(unitPrice).toFixed(2);
  };

  return (
    <div className={className}>
      <div
        className={classes.name}
        style={nameColor ? { color: nameColor } : {}}
      >
        {isCn ? item.description1 : item.description2}
      </div>

      {displayPrice && (
        <div className={classes.inforamtionPrice}>${getUnitPrice()}</div>
      )}
    </div>
  );
};
