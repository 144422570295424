import React, { useEffect, useContext, useState } from "react";
import { Route } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import staticTheme from "./theme";

import { Context } from "./contexts/App.context";
import Preorder from "./pages/Preorder";
import Complete from "./pages/Complete";
import PayForm from "./pages/PayForm";
import { MenuPage, ConfirmPage, WePay, PaymentProcessing, PaymentFail, PaymentSuccess } from "./pages";
import { useLanguage, useBootStrap } from "./hooks";
import getUserOpenId from "./apis/getUserOpenId";
import {checkIsMiniProgram} from "./utils/miniapp"

//Component
// import LoadingCover from "./components/Cover/LoadingCover";

const Routes = () => {
  const [state, appDispatch] = useContext(Context);
  const { initLanguage } = useLanguage();
  const [theme, setTheme] = useState(staticTheme);

  const { loadWebSiteSettings } = useBootStrap();

  const {
    buttonTextColor,
    mainLightColor,
    shoppingCartLabelColor,
    mainColor,
    userOpenId
  } = state;

  useEffect(()=>{
    const _getUserOpenId = async() => {
      if(checkIsMiniProgram() && (!userOpenId)){
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code")
        console.log("code", code)
        if(code){
          const resp = await getUserOpenId(code)
          console.log("resp", resp)
          if(resp && resp?.data && resp.data?.openid){
            console.log("resp.data.openid",resp.data.openid)
            appDispatch({
              type: "SET",
              payload: {
                userOpenId: resp.data.openid
              },
            })
          }
        }
      }
    }
    _getUserOpenId()
  },[])

  console.log("userOpenId", userOpenId)

  useEffect(() => {
    setTheme({
      ...theme,
      colors: {
        ...theme.colors,
        buttonText: buttonTextColor,
        mainLight: mainLightColor,
        shoppingCartLabel: shoppingCartLabelColor,
        main: mainColor,
      },
    });
  }, [buttonTextColor, mainLightColor, shoppingCartLabelColor, mainColor]);

  useEffect(() => {
    loadWebSiteSettings();
    initLanguage();
  }, [window.location.hostname, state.multiLanguage, state.defaultLanguage]);

  return (
    <ThemeProvider theme={theme}>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/:tableCode`}
        component={MenuPage}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/confirm/:tableCode`}
        component={ConfirmPage}
      />
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Preorder} />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payment-complete/:payway`}
        component={Complete}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payment-complete/:payway/:paymentId`}
        component={Complete}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payform/alipay`}
        component={PayForm}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payform/wepay`}
        component={WePay}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payment/processing`}
        component={PaymentProcessing}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payment/success`}
        component={PaymentSuccess}
      />
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/payment/fail`}
        component={PaymentFail}
      />
      {/* <LoadingCover/> */}
    </ThemeProvider>
  );
};

export default Routes;
