import React from "react"

import { useShoppingCart, useConfirmPage } from "../../hooks"
import { ConfirmPageListContainer } from "../../components"
import strings from "../../strings"

import icon from "../../assets/images/icons/table.png"
import ShoppingCartItem from "../../components/ShoppingCartItem/ShoppingCartItem"

export default () => {
    const { showOrder, displayOptionModal } = useConfirmPage()
    const { cartList, totalQuantity } = useShoppingCart()
    return (
        <ConfirmPageListContainer
            onButtonClick={showOrder}
            buttonText={strings.showOrderedDishes}
            icon={icon}
            title={strings.shoppingCart}
            quantity={totalQuantity}
        >
            {cartList.map((x, i) => {
                return <ShoppingCartItem
                    item={x}
                    key={`shopping-cart-list-item-${i}`}
                    index={i}
                    onClick={() => { displayOptionModal({ ...x, index: i }) }}
                    hideControl={true}
                    displayPrice={true}
                />
            })}
        </ConfirmPageListContainer>
    )
}
