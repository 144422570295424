import React, {useEffect} from "react"

import { CartContext } from "../../contexts";
import { Context } from "../../contexts/App.context";

//Utils
import { checkIsMiniProgram } from "../../utils/miniapp";
import {secondToMinutesStr} from "../../utils/index"
//Apis
import createMiniappWechatPay from "../../apis/createMiniappWechatPay";

//Styles
import useStylesWepayPage from "./styles/useStyles.WePay.page"


const WePay = ()=>{
    const [appState, appDispatch] = React.useContext(Context);
    const [state, dispatch] = React.useContext(CartContext);
    const [remainingTime, setRemainingTime] = React.useState(900)
    const classes = useStylesWepayPage()
    const { cartList } = state;
    const {
        userOpenId
      } = appState;
    const [totalAmount, setTotalAmount] = React.useState(0);
    useEffect(()=>{
        setTotalAmount(0.01)
        // setTotalAmount(
        //     cartList
        //       .reduce((sum, orderItem) => {
        //         let unitPrice =
        //           orderItem.quantity *
        //           parseFloat(
        //             orderItem.selectedSize && orderItem.selectedSize.price
        //               ? orderItem.selectedSize.price
        //               : orderItem.price
        //           );
        //         if (orderItem.selectedOptions) {
        //           orderItem.selectedOptions.forEach((o) => {
        //             unitPrice += o.price;
        //           });
        //         }
        //         return sum + unitPrice;
        //       }, 0)
        //       .toFixed(2)
        //   );
    },[])

    const handleClickPay = async() => {
      if(checkIsMiniProgram()){
        const res = await createMiniappWechatPay({amount: totalAmount, userOpenId:userOpenId})
        console.log("res",res)
        if(res.code === "0" && res?.data && res.data?.data && res.data.data?.resultCode === "success"){
          wx.miniProgram.navigateTo({
            url: '../pay/index?data=' + encodeURIComponent(JSON.stringify(res.data.data))
          }) 
        }
      }

    }

    return(
      <div className={classes.root}>
        <div className={classes.title}>AUPOS Cashier</div>
          <div>
              Remaining time: 
              <span>{secondToMinutesStr(remainingTime)}</span>
          </div>
          <div>
              <span>User Openid:</span>
              <span>{userOpenId}</span>
          </div>0
          <button onClick={handleClickPay}>Submit</button>
      </div>
    )
}

export default WePay