import React from "react"
import { useTheme } from "react-jss"
import classnames from "classnames"

import useStyles from "./useStyles"
import strings from "../../strings"
import { useConfirmPage } from "../../hooks"

export default ({ onButtonClick, buttonText, icon, title, backgroundColor, quantity }) => {

    const theme = useTheme()
    const classes = useStyles({ ...theme, customStyles: { backgroundColor } })

    const { displayMode, showCart, showOrder } = useConfirmPage();

    return (
        <div className={classes.container}>
            <div className={classnames(classes.tab, { activeLeft: displayMode === "CART" })} onClick={showCart}>
                <span className={classnames(classes.tabLabel, { activeLeft: displayMode === "CART" })}>{strings.showShoppingCart}</span>
            </div>
            <div className={classnames(classes.tab, { activeRight: displayMode === "ORDER" })} onClick={showOrder}>
                <span className={classnames(classes.tabLabel, { activeRight: displayMode === "ORDER" })}>{strings.orderedDishTitle}</span>
            </div>
        </div>
    )
}