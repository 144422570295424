import React from "react";
import _ from "lodash";
import { getProducts } from "../apis";
import { LoadingContext, MenuContext, CartContext } from "../contexts";
import { Context as AppCTX } from "../contexts/App.context";

import {checkShopNameAndOrderType} from "../utils/index"

export default () => {
  const [, loadingDispatch] = React.useContext(LoadingContext);
  const [, menuDispatch] = React.useContext(MenuContext);
  const [, cartDispatch] = React.useContext(CartContext);
  const [appState] = React.useContext(AppCTX);
  const { cnCode, enCode } = appState;
  const callApi = async () => {
    loadingDispatch({ type: "SET", payload: { global: true } });
    try {
      const resp = await getProducts();
      if (resp.code === "0") {
        loadingDispatch({ type: "SET", payload: { global: false } });
        const stock = resp.data.stock;
        const getDisableSizeLevels = (option, disableRule, optionType) => {
          if (disableRule === undefined) return [];
          const disabledSizeLevels = disableRule.reduce((res, ruleItem) => {
            const ruleItemArray = ruleItem.split(";");
            if (
              optionType === "TASTE" &&
              ruleItemArray[2].includes(option.stockId.toString())
            )
              return [...res, parseInt(ruleItemArray[0])];
            if (
              optionType === "EXTRA" &&
              ruleItemArray[1].includes(option.stockId.toString())
            )
              return [...res, parseInt(ruleItemArray[0])];
            return res;
          }, []);

          return disabledSizeLevels;
        };
        const makeOptionRule = (optionRule, optionType) => {
          if (typeof optionRule !== "string") return null;
          const optionArray = optionRule.split(";");
          if (optionArray[0] === optionType) {
            return {
              cat1: {
                name: optionArray[0],
                limitation:
                  optionArray[1] === "" ? "" : parseInt(optionArray[1]),
              },
              cat2: {
                name: optionArray[2],
                limitation:
                  optionArray[3] === "" ? "" : parseInt(optionArray[3]),
              },
            };
          } else {
            return null;
          }
        };
        menuDispatch({
          type: "SET",
          payload: {
            categories: stock.map((cate) => {
              // console.log(cate.stocks.map(x => ({ barcode: x.barcode, name: x.description, name2: x.description2, stockId: x.stockId })));
              return {
                ...cate,
                stocks: cate.stocks
                  .filter((x) => !x.inactive)
                  .map((stk) => {
                    const { hostname } = window.location;
                    if (
                      hostname === "localhost" ||
                      checkShopNameAndOrderType("dolarshopsydney", "order")
                    ) {
                      if (stk.barcode === "A001") {
                        cartDispatch({
                          type: "SET",
                          payload: {
                            defaultAddItem: {
                              ...stk,
                              cat_name: cate.catName,
                              sequenceNumber: stk.barcode.padStart(5, "0"),
                              image: `${resp.data.imageUrl}${stk.barcode}.jpg`,
                              description1:
                                cnCode === 1
                                  ? stk.description
                                  : stk.description2,
                              description2:
                                enCode === 2
                                  ? stk.description2
                                  : stk.description,
                              stock_id: stk.stockId,
                              taste: stk.taste
                                .map((t) => {
                                  const foundTaste = resp.data.taste.find(
                                    (y) => y.stockId === t
                                  );
                                  if (foundTaste) {
                                    return {
                                      ...foundTaste,
                                      type: "TASTE",
                                      description1:
                                        cnCode === 1
                                          ? foundTaste.description
                                          : foundTaste.description2,
                                      description2:
                                        enCode === 2
                                          ? foundTaste.description2
                                          : foundTaste.description,
                                    };
                                  } else {
                                    return null;
                                  }
                                })
                                .filter((x) => x) /*remove null value*/,
                              extra: stk.extra
                                .map((e) => {
                                  const foundExtra = resp.data.extra.find(
                                    (y) => {
                                      return y.stockId === e;
                                    }
                                  );
                                  if (foundExtra) {
                                    return {
                                      ...foundExtra,
                                      type: "EXTRA",
                                      description1:
                                        cnCode === 1
                                          ? foundExtra.description
                                          : foundExtra.description2,
                                      description2:
                                        enCode === 2
                                          ? foundExtra.description2
                                          : foundExtra.description,
                                    };
                                  } else {
                                    return null;
                                  }
                                })
                                .filter((x) => x) /*remove null value*/,
                              price: stk.price["0"],
                              sizeLevels: Object.values(stk.price).map(
                                (p, i) => {
                                  if (i === 0) {
                                    return { price: p, name: "", sizeLevel: 0 };
                                  } else {
                                    return {
                                      price: p[0],
                                      name: p[1],
                                      sizeLevel: i,
                                    };
                                  }
                                }
                              ),
                            },
                          },
                        });
                      }
                    }
                    return {
                      ...stk,
                      cat_name: cate.catName,
                      sequenceNumber: stk.barcode.padStart(5, "0"),
                      image: `${resp.data.imageUrl}${stk.barcode}.jpg`,
                      description1:
                        cnCode === 1 ? stk.description : stk.description2,
                      description2:
                        enCode === 2 ? stk.description2 : stk.description,
                      longdesc1: cnCode === 1 ? stk.longdesc : stk.longdesc2,
                      longdesc2: enCode === 2 ? stk.longdesc2 : stk.longdesc,
                      stock_id: stk.stockId,
                      taste: stk.taste
                        .map((t) => {
                          const foundTaste = resp.data.taste.find(
                            (y) => y.stockId === t
                          );
                          if (foundTaste) {
                            return {
                              ...foundTaste,
                              type: "TASTE",
                              description1:
                                cnCode === 1
                                  ? foundTaste.description
                                  : foundTaste.description2,
                              description2:
                                enCode === 2
                                  ? foundTaste.description2
                                  : foundTaste.description,
                              disableSizeLevels: getDisableSizeLevels(
                                foundTaste,
                                stk.sizeLevelOptionDisallowRules,
                                "TASTE"
                              ),
                              rule: stk.menuOptionLimitationRules
                                ? stk.menuOptionLimitationRules
                                    .map((ruleItem) => {
                                      return makeOptionRule(ruleItem, "TASTE");
                                    })
                                    .filter((x) => x !== null)
                                : [],
                            };
                          } else {
                            return null;
                          }
                        })
                        .filter((x) => x) /*remove null value*/,
                      extra: stk.extra
                        .map((e) => {
                          const foundExtra = resp.data.extra.find((y) => {
                            return y.stockId === e;
                          });
                          if (foundExtra) {
                            return {
                              ...foundExtra,
                              type: "EXTRA",
                              description1:
                                cnCode === 1
                                  ? foundExtra.description
                                  : foundExtra.description2,
                              description2:
                                enCode === 2
                                  ? foundExtra.description2
                                  : foundExtra.description,
                              disableSizeLevels: getDisableSizeLevels(
                                foundExtra,
                                stk.sizeLevelOptionDisallowRules,
                                "EXTRA"
                              ),

                              rule: stk.menuOptionLimitationRules
                                ? stk.menuOptionLimitationRules
                                    .map((ruleItem) => {
                                      return makeOptionRule(ruleItem, "EXTRA");
                                    })
                                    .filter((x) => x !== null)
                                : [],
                            };
                          } else {
                            return null;
                          }
                        })
                        .filter((x) => x) /*remove null value*/,
                      price: stk.price["0"],
                      sizeLevels: Object.values(stk.price).map((p, i) => {
                        if (i === 0) {
                          return { price: p, name: "", sizeLevel: 0 };
                        } else {
                          return { price: p[0], name: p[1], sizeLevel: i };
                        }
                      }),
                    };
                  }),
                cat_name: cate.catName,
                cat_name1: cnCode === 1 ? cate.catName : cate.catName2,
                cat_name2: enCode === 2 ? cate.catName2 : cate.catName,
              };
            }),
            baseImageUrl: resp.data.imageUrl,
          },
        });
      } else {
        loadingDispatch({ type: "SET", payload: { global: false } });
        menuDispatch({ type: "SET", payload: { categories: [] } });
      }
    } catch (error) {
      console.log(error);

      loadingDispatch({ type: "SET", payload: { global: false } });
      menuDispatch({ type: "SET", payload: { categories: [] } });
    }
  };

  // React.useEffect(() => {
  //   if (cnCode !== null && enCode !== null) {
  //     callApi();
  //   }
  // }, [cnCode, enCode]);
  return {
    getStockList: callApi,
  };
};
