import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    backIcon: {
        width: '20px',
    },
    tableIcon: {
        marginLeft: '15px',
        width: '25px',
    },
    tableCode: {
        position: 'absolute',
        left: '85px',
    },
    title: {
        flex: 1,
        textAlign: 'center',
        paddingRight: '60px'
    }
})

export default useStyles