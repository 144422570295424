import React from "react";
import {
  addDish as addDishRequest,
  getStaffToken,
  getHistoryOrder,
  openTable,
} from "../apis";
import {
  LoadingContext,
  ApiResponseContext,
  ConfirmPageContext,
  CartContext,
  LanguageContext,
} from "../contexts";
import { useMenu } from "../hooks";
import { ShoppingCartHelper } from "../utils";
import { useParams } from "react-router";
import strings from "../strings";

export default () => {
  const [responseState, responseDispatch] = React.useContext(
    ApiResponseContext
  );
  const [cartState, cartDispatch] = React.useContext(CartContext);
  const [lanState] = React.useContext(LanguageContext);
  const { isCn } = lanState;
  const { getStockList } = useMenu();
  const { addDishResponse } = responseState;

  const [, loadingDispatch] = React.useContext(LoadingContext);
  const [, confirmPageDispatch] = React.useContext(ConfirmPageContext);

  const { salesorderLines, resetCart } = ShoppingCartHelper();

  const { tableCode } = useParams();

  const addDish = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    // read data from localStorage
    const token = localStorage.getItem("aupos_customer_order_staff_token");
    let salesorderId =
      e && e.salesorderId
        ? e.salesorderId
        : parseInt(localStorage.getItem("aupos_customer_order_salesorder_id"));

    try {
      loadingDispatch({ type: "SET", payload: { global: true } });

      if (salesorderId) {
        try {
          const resp = await addDishRequest({
            tableCode,
            token,
            salesorderId,
            salesorderLines,
          });
          if (resp.data.code == "0") {
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 200, message: "success" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            resetCart();
          } else if (resp.data.code == "4000") {
            /*token invaild */
            // handle known error
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 400, message: "fail" } },
            });
            // remove local saved token
            localStorage.removeItem("aupos_customer_order_staff_token");
            // show login modal
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
          } else {
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });

            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 300, message: "fail" } },
            });
            // remove local saved token
            localStorage.removeItem("aupos_customer_order_staff_token");
            // show login modal
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            resetCart();
          }
        } catch (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code === "4000"
          ) {
            //1. remove local stored staff token
            localStorage.removeItem("aupos_customer_order_staff_token");
            //2. dispaly staff login modal
            responseDispatch({
              type: "SET",
              payload: {
                loginResponse: {
                  code: 400,
                  message: strings.addDishFailMessage1,
                },
              },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: true },
            });
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code === "3000"
          ) {
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 400, message: "fail" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            // resetCart();
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code === "1001"
          ) {
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            responseDispatch({
              type: "SET",
              payload: {
                addDishResponse: {
                  code: 1001,
                  message: cartState.cartList.reduce((res, orderItem) => {
                    if (error.response.data.data.includes(orderItem.stockId)) {
                      return `${res} ${
                        isCn ? orderItem.description1 : orderItem.description2
                      }x${orderItem.quantity},`;
                    } else {
                      return res;
                    }
                  }, ""),
                },
              },
            });
            getStockList();
            cartDispatch({
              type: "REMOVE_INACTIVE_ORDERITEM",
              payload: error.response.data.data,
            });
          } else {
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 300, message: "fail" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
          }
        }
      } else {
        responseDispatch({
          type: "SET",
          payload: { addDishResponse: { code: 300, message: "fail" } },
        });
        loadingDispatch({
          type: "SET",
          payload: { global: false, login: false },
        });
        resetCart();
      }
    } catch (error) {
      responseDispatch({
        type: "SET",
        payload: { addDishResponse: { code: 300, message: "fail" } },
      });
      loadingDispatch({
        type: "SET",
        payload: { global: false, login: false },
      });
      resetCart();
    }
  };

  const addDishToKitchenWithoutAuthensication = async (barcode, tableCode) => {
    // 1. getToken by defaultStaffCode
    let token = localStorage.getItem("aupos_customer_order_staff_token");
    const tokenExpiredDatetime = localStorage.getItem(
      "aupos_customer_order_staff_token_expired_datetime"
    );
    const now = new Date();
    if (!token) {
      try {
        const loginResp = await getStaffToken({ barcode, tableCode });
        if (loginResp.data.code === "0") {
          token = loginResp.data.data;
          localStorage.setItem("aupos_customer_order_staff_token", token);
          localStorage.setItem(
            "aupos_customer_order_staff_token_expired_datetime",
            now.getTime().toString()
          );
        }
      } catch (error) {
        return null;
      }
    }
    // refresh token
    if (token && now.getTime() - parseInt(tokenExpiredDatetime) >= 3500000) {
      try {
        const loginResp = await getStaffToken({ barcode, tableCode });
        if (loginResp.data.code === "0") {
          token = loginResp.data.data;
          localStorage.setItem("aupos_customer_order_staff_token", token);
          localStorage.setItem(
            "aupos_customer_order_staff_token_expired_datetime",
            now.getTime().toString()
          );
        }
      } catch (error) {
        return null;
      }
    }

    // 2. check table status
    let salesorderId = localStorage.getItem(
      "aupos_customer_order_salesorder_id"
    );
    if (!salesorderId) {
      try {
        const tableStatusResp = await getHistoryOrder({ tableCode });
        if (tableStatusResp.data.code === "0") {
          // table open already
          salesorderId = tableStatusResp.data.data.salesorderId;
        } else if (tableStatusResp.data.code === "3000") {
          //table code is valid but not open yet
          const openTableResp = await openTable({
            token,
            tableCode,
            guestNo: 1,
          });
          if (openTableResp.data.code === "0") {
            salesorderId = openTableResp.data.data.salesorderId;
          }
        }
      } catch (error) {
        //todo:: check table status or opentalbe fail for some reasons
      }
    }
    try {
      loadingDispatch({ type: "SET", payload: { global: true } });

      if (salesorderId) {
        try {
          const resp = await addDishRequest({
            tableCode,
            token,
            salesorderId,
            salesorderLines,
          });
          if (resp.data.code == "0") {
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 200, message: "success" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            resetCart();
          } else if (resp.data.code == "4000") {
            /*token invaild */
            // handle known error
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 400, message: "fail" } },
            });
            // remove local saved token
            localStorage.removeItem("aupos_customer_order_staff_token");
            // show login modal
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
          } else {
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });

            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 300, message: "fail" } },
            });
            // remove local saved token
            localStorage.removeItem("aupos_customer_order_staff_token");
            // show login modal
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            resetCart();
          }
        } catch (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code === "4000"
          ) {
            //1. remove local stored staff token
            localStorage.removeItem("aupos_customer_order_staff_token");
            //2. dispaly staff login modal
            responseDispatch({
              type: "SET",
              payload: {
                loginResponse: {
                  code: 400,
                  message: strings.addDishFailMessage1,
                },
              },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: true },
            });
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.code === "3000"
          ) {
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 400, message: "fail" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
            // resetCart();
          } else {
            responseDispatch({
              type: "SET",
              payload: { addDishResponse: { code: 300, message: "fail" } },
            });
            loadingDispatch({
              type: "SET",
              payload: { global: false, login: false },
            });
            confirmPageDispatch({
              type: "SET",
              payload: { showStaffLogin: false },
            });
          }
        }
      } else {
        responseDispatch({
          type: "SET",
          payload: { addDishResponse: { code: 300, message: "fail" } },
        });
        loadingDispatch({
          type: "SET",
          payload: { global: false, login: false },
        });
        resetCart();
      }
    } catch (error) {
      responseDispatch({
        type: "SET",
        payload: { addDishResponse: { code: 300, message: "fail" } },
      });
      loadingDispatch({
        type: "SET",
        payload: { global: false, login: false },
      });
      resetCart();
    }
  };

  const resetAddDishResponse = () => {
    responseDispatch({
      type: "SET",
      payload: { addDishResponse: { code: 100, message: "silence" } },
    });
  };

  return {
    addDishResponse,
    addDish,
    resetAddDishResponse,
    addDishToKitchenWithoutAuthensication,
  };
};
