/**
 * control panel for order item, may decrease/increase/remove orderItem in shopping cart or any interactions by user triggered events
 */

import React from 'react'
import { useTheme } from "react-jss"

import useStyles from "./useStyles"
import IconButton from "../Button/IconButton"

import addPNG from "../../assets/images/icons/add.png"
import decreasePNG from "../../assets/images/icons/decrease.png"
import { useShoppingCart } from '../../hooks'
import strings from '../../strings'

import {checkShopNameAndOrderType} from "../../utils/index"

export default ({ item, index, className }) => {

    const theme = useTheme()
    const classes = useStyles(theme)

    const { decreaseInCart, increaseInCart } = useShoppingCart()

    if (window.location.hostname === "localhost" || checkShopNameAndOrderType("dolarshopsydney", "order")) {
        if (item.barcode === "A001") {
            return <div className={className}>
                <span className={classes.quantity} style={{ textAlign: 'right', paddingRight: '7px' }}>x {item.quantity}</span>
            </div>;
        }
    }

    return (
        <div className={className} onClick={e => e.stopPropagation()}>

            <IconButton
                icon={decreasePNG}
                onClick={() => { decreaseInCart(index) }}
                style={{ marginLeft: 0 }}
                type="remove"
            />
            <div className={classes.quantity}>
                {item.quantity}
            </div>
            <IconButton
                icon={addPNG}
                onClick={() => { increaseInCart(index) }}
                style={{ marginLeft: 0 }}
                type="add"
            />
        </div>
    )
}