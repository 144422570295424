import React from "react";

import { MenuContext, LanguageContext, CartContext } from "../../contexts";
import ModalCover from "../ModalCover/ModalCover";
import { useTheme, createUseStyles } from "react-jss";
import StockCardControl from "../Menu/StockCardControl";
import strings from "../../strings";
import Button from "../Button/Button";
import {checkShopNameAndOrderType} from "../../utils/index"

export default () => {
  const [menuState, menuDispatch] = React.useContext(MenuContext);
  const [cartState, cartDispatch] = React.useContext(CartContext);
  const { cartList } = cartState;

  const { selectedItem } = menuState;
  const [state] = React.useContext(LanguageContext);
  const { isCn } = state;
  const [src, setSrc] = React.useState("");
  const [showControl, setShowControl] = React.useState(false);

  /* component state for managing displaying status, ex: which button should be shown */
  const [stockWithOptions, setStockWithOptions] = React.useState(false);

  const calculateQuantity = () => {
    return cartList.reduce((qty, orderItem) => {
      if (orderItem.barcode === selectedItem.barcode) {
        return qty + orderItem.quantity;
      } else {
        return qty;
      }
    }, 0);
  };

  /* initialize component state */
  React.useEffect(() => {
    setStockWithOptions(
      selectedItem.extra.length > 0 ||
        selectedItem.taste.length > 0 ||
        selectedItem.sizeLevels.length > 1
    );
  }, [selectedItem]);

  React.useEffect(() => {
    setSrc(selectedItem.image);
  }, []);

  const onError = () => {
    // generate default image url
    const lastIndexOfSlash = selectedItem.image.lastIndexOf("/");

    setSrc(
      `${selectedItem.image.substr(0, lastIndexOfSlash)}/default_product.jpg`
    );
  };
  const dismiss = () => {
    menuDispatch({
      type: "SET",
      payload: { selectedItem: {}, isShowLargeImage: false },
    });
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ModalCover dismiss={dismiss} contentWidth={335} noPadding={true}>
      <img src={src} alt="" className={classes.image} onError={onError} />
      <div className={classes.footer}>
        <span className={classes.name}>
          {isCn ? selectedItem.description1 : selectedItem.description2}
        </span>
        {selectedItem.longdesc && selectedItem.longdesc !== "" && (
          <span className={classes.longdesc}>
            {isCn
              ? selectedItem.longdesc1.trim()
              : selectedItem.longdesc2.trim()}
          </span>
        )}
        <div className={classes.stockCardPriceControl}>
          <span className={classes.stockCardPrice}>
            <span className={classes.priceSymbol}>$</span>
            <span className={classes.priceValue}>
              {selectedItem.price.toFixed(2)}
            </span>
          </span>
          {!stockWithOptions &&
            !showControl &&
            calculateQuantity() <= 0 &&
            (!checkShopNameAndOrderType("jackpothotpot", "order") ||
              selectedItem.barcode !== "SAUCE") &&
            (window.location.hostname !== "localhost" ||
              selectedItem.barcode !== "SAUCE") && (
              <Button
                onClick={() => {
                  setShowControl(true);
                  cartDispatch({
                    type: "ADD",
                    payload: { ...selectedItem, quantity: 1 },
                  });
                }}
                style={{
                  fontSize: "12px",
                  fontFamily: `'Lato',sans-serif`,
                  fontWeight: "300",
                  padding: "0 10px",
                  height: "27px",
                  borderRadius: "14px",
                }}
              >
                {strings.addToCart}
              </Button>
            )}
          {(stockWithOptions || showControl || calculateQuantity() > 0) && (
            <StockCardControl stock={selectedItem} />
          )}
        </div>
      </div>
    </ModalCover>
  );
};

const useStyles = createUseStyles((theme) => {
  const {
    colors,
    funcs: { rw, rem },
    extends: { horizontalFlexbox },
  } = theme;
  return {
    image: {
      width: "100%",
      objectFit: "cover",
      backgroundColor: "#FFF",
      minHeight: rw(85),
      minWidth: rw(85),
    },
    footer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
      padding: "0 16px 12px 20px",
    },
    name: {
      fontSize: "1rem",
      color: colors.primaryText,
      width: "100%",
      textAlign: "left",
      whiteSpace: "normal",
      wordBreak: "break-all",
    },
    stockCardPriceControl: {
      ...horizontalFlexbox,
      width: "100%",
      justifyContent: "space-between",
      marginTop: "18px",
    },

    stockCardPrice: {
      fontSize: rem(14),
      lineHeight: rem(16),
      marginTop: rem(8),
      fontFamily: "sans-serif",
      color: colors.emphasis,
    },
    priceSymbol: {
      fontSize: rem(16),
    },
    priceValue: {
      fontSize: rem(20),
    },
    longdesc: {
      color: "#4a4a4a",
      fontSize: "12px",
      marginTop: "8px",
      width: "100%",
    },
  };
});
