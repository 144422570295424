import { getColor } from "./utils";

const flexBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const screenValues = {
  viewHeight: "812",
  viewWidth: "375",
};

export default {
  colors: {
    background: "#fbfbfd", // grey background color for body background color
    // primaryText: '#4A4A4A', // use for stock name, price and ect...
    primaryText: "#4A4A4A",
    secondaryText: "#888",
    // buttonText: getColor("buttonText"),
    // mainLight: getColor("mainLight"),
    // shoppingCartLabel: getColor("shoppingCartLabel"),
    // main: getColor("main"), // main theme color,use for everything which are trying to stand out ["#ffd258","A98D68"]
    buttonText: "red",
    mainLight: "red",
    shoppingCartLabel: "red",
    main: "red", // main theme color,use for everything which are trying to stand out ["#ffd258","A98D68"]
    greyInputBorder: "#4a4a4a29", // for input box with light grey border,
    lightPrimaryText: "#9B9B9B", // for quantity numbers
    footerButton: "#747474", // large footer button , ex: submit order
    coverBackground: "#696969b5", //transparent cover for modal background
    error: "#F1534D",
    optionTag: "#666666",
    sentDishName: "#F1534D",
    emphasis: "#F1534D",
    buttonBlack: "#202326",
    chowButton: "#E01151",
  },
  borders: {
    greyLine: "1px solid #bdc0c84d", // light border use for separation
    input: "1px solid #DDD",
  },
  extends: {
    verticalFlexbox: {
      ...flexBox,
      flexDirection: "column",
    },
    horizontalFlexbox: {
      ...flexBox,
      flexDirection: "row",
    },
  },
  funcs: {
    rw: (value) => `${(value * 100) / screenValues.viewWidth}vw`,
    rh: (value) => `${(value * 100) / screenValues.viewHeight}vh`,
    rem: (value) => `${value / 16}rem`,
  },
};
