import React from "react";
import { useTheme } from "react-jss";

import useStyles from "./useStyles";
import Image from "../Image/Image";

import searchPNG from "../../assets/images/icons/search.png";

export default ({ hideSearch, inputValue, onInputChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Image src={searchPNG} className={classes.icon} />
      <div>
        <input
          type="text"
          className={classes.input}
          value={inputValue}
          onChange={onInputChange}
        />
      </div>
      <div onClick={hideSearch} className={classes.cancelButton}>
        cancel
      </div>
    </>
  );
};
