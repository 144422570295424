import React from "react"
import { useTheme } from "react-jss"

import useStyles from "./useStyles"

export default ({ children, ...props }) => {

    const theme = useTheme()
    const classes = useStyles({ ...theme, ...props })

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}