/**
 * control panel for option selection modal, add dish to shopping cart
 */

import React from 'react'
import { useTheme, createUseStyles } from "react-jss"

import IconButton from "../Button/IconButton"

import addPNG from "../../assets/images/icons/add.png"
import decreasePNG from "../../assets/images/icons/decrease.png"

export default ({ decrease, increase, quantity }) => {

    const theme = useTheme()
    const classes = useStyles(theme)


    return (
        <div className={classes.container}>
            <IconButton
                icon={decreasePNG}
                onClick={decrease}
                style={{ marginLeft: 0 }}
                type="remove"
            />
            <div className={classes.quantity}>
                {quantity}
            </div>
            <IconButton
                icon={addPNG}
                onClick={increase}
                style={{ marginLeft: 0 }}
                type="add"
            />
        </div>
    )
}

const useStyles = createUseStyles(theme => {
    const { extends: { horizontalFlexbox }, colors } = theme

    return {
        container: {
            ...horizontalFlexbox,
            width: '7rem',
            justifyContent: 'space-between'
        },
        quantity: {
            color: colors.lightPrimaryText,
            fontWeight: 500,
            fontSize: '1.125rem',
            textAlign: 'center',
            flex: 1,
            letterSpacing: '1.29px'
        }
    }
})