import request from "./request";

interface InputValues {
  tableCode: string;
}

const getHistoryOrder = async ({ tableCode }: InputValues) => {
  const resp = await request.get(`salesorder?tableCode=${tableCode}`);

  return resp;
};

export default getHistoryOrder;
