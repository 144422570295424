const reducer = (state, action) => {
    switch (action.type) {
        case "SET":

            return { ...state, ...action.payload };

        default:
            return state;
    }
}

const initState = {
    displayMode: 'CART', // can be 'CART' and 'ORDER' will display shopping cart list and history order list
    showStaffLogin: false,
    showOptionModal: false,
    isShowCommentsModal: false,
    expired: false,
    isShowExpiredModal: false,
    payForm: undefined
}

export default [reducer, initState]