import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {
    const { borders, funcs: { rem }, colors } = theme

    return {
        container: {
            display: 'grid',
            gridTemplateColumns: '68px 1fr 4.375rem',
            gridTemplateRows: 'auto',
            padding: rem(10),
            borderBottom: borders.greyLine,
            position: 'relative',
        },
        imageContainer: {
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            gridRowEnd: 4,
        },
        image: {
            height: '60px',
            width: '60px',
            borderRadius: '5px',
            backgroundColor: '#FFF',
            objectFit: 'cover',
            border: '1px solid #00000024',
        },
        information: {
            gridColumnStart: 2,
            gridColumnEnd: 4,
            gridRowStart: 1,
            gridRowEnd: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        options: {
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 2,
            gridRowEnd: 3,
        },
        priceSymbol: {
            fontSize: rem(10),
            transform: 'scale(0.5)'
        },
        priceValue: {
            fontSize: rem(20)
        },
        price: {
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 3,
            gridRowEnd: 4,
            fontSize: rem(14),
            lineHeight: rem(16),
            marginTop: rem(11),
            fontFamily: 'sans-serif',
            color: colors.emphasis
        },
        control: {
            gridColumnStart: 3,
            gridColumnEnd: 4,
            gridRowStart: 3,
            gridRowEnd: 4,
            // justifySelf: 'end',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',

        },
        quantity: {
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 3,
            gridRowEnd: 4,
            fontSize: rem(14),
            fontWeight: 400,
            color: '#6D7278',
            fontFamily: 'sans-serif',
        }

    }
})

export default useStyles