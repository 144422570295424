import React from "react";
import { CartContext } from "../contexts";

export default () => {
  const [state, dispatch] = React.useContext(CartContext);
  const { cartList, orderComments } = state;
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [salesorderLines, setSalesorderLines] = React.useState("{}");

  const getTotalQuantity = () => {
    setTotalQuantity(
      cartList.reduce((sum, orderItem) => sum + orderItem.quantity, 0)
    );
  };

  const getSalesorderLines = () => {
    setSalesorderLines(
      JSON.stringify(
        cartList.map((cli) => {
          return {
            stockId: cli.stockId,
            taste: cli.selectedOptions
              ? cli.selectedOptions
                  .filter((y) => y.type === "TASTE")
                  .map((x) => {
                    return {
                      stockId: x.stockId,
                      price: x.price,
                      quantity: 1,
                    };
                  })
              : [],
            extra: cli.selectedOptions
              ? cli.selectedOptions
                  .filter((y) => y.type === "EXTRA")
                  .map((x) => {
                    return {
                      stockId: x.stockId,
                      price: x.price,
                      quantity: 1,
                    };
                  })
              : [],
            sizeLevel:
              cli.selectedSize && cli.selectedSize.sizeLevel
                ? cli.selectedSize.sizeLevel
                : cli.sizeLevels[0].sizeLevel || 0,
            quantity: cli.quantity,
            comments: getComments(cli.comments) || "",
            price:
              cli.selectedSize && cli.selectedSize.price
                ? cli.selectedSize.price
                : cli.sizeLevels[0].price,
          };
        })
      )
    );
  };

  const getQrcodeString = () => {
    return cartList.reduce((resStr, cli) => {
      let optionString = "";
      if (cli.selectedOptions && cli.selectedOptions.length > 0) {
        cli.selectedOptions.forEach((option) => {
          optionString = `${optionString}${option.barcode},${cli.quantity},0;`;
        });
      }

      return `${resStr}${cli.barcode},${cli.quantity},${
        cli.selectedSize && cli.selectedSize.sizeLevel
          ? cli.selectedSize.sizeLevel
          : cli.sizeLevels[0].sizeLevel
      };${optionString}`;
    }, "");
  };

  const resetCart = () => {
    dispatch({ type: "RESET" });
  };

  const getComments = (value) => {
    let result = value || "";

    if (orderComments && orderComments !== "") {
      return result === "" ? orderComments : `${result},${orderComments}`;
    } else {
      return result;
    }
  };

  React.useEffect(() => {
    getTotalQuantity();
    getSalesorderLines();
  }, []);

  React.useEffect(() => {
    getTotalQuantity();
    getSalesorderLines();
  }, [cartList, orderComments]);

  return {
    totalQuantity,
    salesorderLines,
    resetCart,
    qrCodeString: getQrcodeString(),
  };
};
