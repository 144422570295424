import React from "react";
import { useShoppingCart } from "../../hooks";
import ShoppingCartItem from "../ShoppingCartItem/ShoppingCartItem";
import { useTheme } from "react-jss";
import useStyles from "./useStyles";
import strings from "../../strings";
import icon from "../../assets/images/icons/trash-bin.svg";

const ShoppingCartOverlay = ({ toggleCartOverlay }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { cartList, clearCart, totalQuantity } = useShoppingCart();

    return (
        <div className={classes.root} onClick={() => { toggleCartOverlay() }}>
            <div className={classes.rootContent} onClick={e => { e.stopPropagation(); }}>
                <div className={classes.header}>
                    <div className={classes.headerContent}>
                        <div className={classes.quantity}>{strings.shoppingCart} （{totalQuantity}）</div>
                        <div className={classes.clear} onClick={clearCart}>
                            <img src={icon} alt="" className={classes.clearImage} />
                            {strings.clearCart}
                        </div>
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.bodyContent}>
                        {cartList.map((x, i) => {
                            return <ShoppingCartItem
                                item={x}
                                key={`shopping-cart-list-item-${i}`}
                                index={i}
                                onClick={() => { }}
                                hideControl={false}
                                displayPrice={false}
                            />
                        })}
                    </div>
                </div>
                <div className={classes.footer} />
            </div>
        </div >
    )
}

export default ShoppingCartOverlay;


