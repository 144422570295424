import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import _ from "lodash";

import ModalCover from "../ModalCover/ModalCover";
import ControlPanel from "./ControlPanel";
import Button from "../Button/RectangleButton";
import {
  CartContext,
  MenuContext,
  ConfirmPageContext,
  LanguageContext,
} from "../../contexts";

import editPNG from "../../assets/images/icons/edit.png";
import strings from "../../strings";
import { useAppSetting } from "../../hooks";
import ExtraGroup from "./ExtraGroup.component";

const getRealName = (name) => {
  const _name = name;
  const _nameParts = _name.split("_")
  if(_nameParts.length > 1){
    return _nameParts[1]
  }
  return _name
}

export default ({ mode }) => {
  const theme = useTheme();
  const {
    funcs: { rw },
  } = theme;
  const classes = useStyles(theme);
  const [state] = React.useContext(LanguageContext);
  const { isCn } = state;
  const { isForcedMultipleChoice, getNumberOfChoices } = useAppSetting();

  /* import context state */
  const [, cartDispatch] = React.useContext(CartContext);
  const [, confirmPageDispatch] = React.useContext(ConfirmPageContext);
  const [menuState, menuDispatch] = React.useContext(MenuContext);
  const { selectedItem } = menuState;
  const { extraAdv, extraToStock } = useAppSetting();
  const { image, taste, extra, index, sizeLevels, barcode } = selectedItem;

  const [imageSrc, setSrc] = React.useState("");

  const onError = () => {
    // generate default image url
    const lastIndexOfSlash = image.lastIndexOf("/");

    const defaultImageUrl = `${image.substr(
      0,
      lastIndexOfSlash
    )}/default_product.jpg`;
    if (imageSrc !== image) {
      setSrc(image);
    } else {
      setSrc(defaultImageUrl);
    }
  };

  /** component state */
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedSize, setSelectedSize] = React.useState({});
  const [quantity, setQuantity] = React.useState(1);
  const [comments, setComments] = React.useState("");

  /** initialize component */

  const getImageUrlWithSize = ({ sizeLevel }) => {
    let lastIndexOfSlash = image.lastIndexOf("/");
    let imageUrlWithSizeLevel = `${image.substr(
      0,
      lastIndexOfSlash
    )}/${barcode}_${sizeLevel}.jpg`;

    return imageUrlWithSizeLevel;
  };

  React.useEffect(() => {
    if (mode === "ORDER_ITEM") {
      setQuantity(selectedItem.quantity);
      setSelectedOptions(selectedItem.selectedOptions);
      setComments(selectedItem.comments);
      if (selectedItem.selectedSize) {
        setSelectedSize(selectedItem.selectedSize);
        let imageUrlWithSizeLevel = getImageUrlWithSize(
          selectedItem.selectedSize
        );

        setSrc(imageUrlWithSizeLevel);
      } else {
        setSrc(image);
      }
    }

    if (mode !== "ORDER_ITEM" && sizeLevels.length > 1) {
      setSelectedSize(sizeLevels[1]);
      let imageUrlWithSizeLevel = getImageUrlWithSize(sizeLevels[1]);

      setSrc(imageUrlWithSizeLevel);
    } else if (mode !== "ORDER_ITEM") {
      setSrc(image);
    }
  }, []);

  React.useEffect(() => {
    setSrc(getImageUrlWithSize(selectedSize));
  }, [selectedSize]);

  /** component helper functions */
  const clickOption = (option) => {
    //1. un-selected
    if (selectedOptions.find((s) => s.barcode === option.barcode)) {
      // click on selected tag/option, just remove it from selectedOptions
      setSelectedOptions(
        selectedOptions.filter((s) => s.barcode !== option.barcode)
      );
      return;
    }

    //2. cat2 not set
    if (option.cat2 === "") {
      // cat2 not set, just add opiton into selectedOptions without any check
      setSelectedOptions([...selectedOptions, option]);
      return;
    }

    //3. new option && cat2 is set
    //3-1. try to import local setting.js
    let foundOptionWithSameCat2 = selectedOptions.find(
      (s) => s.cat2 === option.cat2
    );

    if (!foundOptionWithSameCat2) {
      // 如果当前cat2没有选项被选中，直接加入完事儿
      setSelectedOptions([...selectedOptions, option]);
      return;
    }

    // if POS already setup rules stop read the setting from useAppSettings hook
    if (option.rule.length > 0) {
      // get the limitation value according to cat2
      const ruleItem = option.rule.find((x) => x.cat2.name === option.cat2);
      if (ruleItem) {
        if (ruleItem.cat2.limitation === 1) {
          setSelectedOptions(
            selectedOptions.map((s) => {
              if (s.cat2 === option.cat2) {
                return option;
              } else {
                return s;
              }
            })
          );
          return;
        }

        if (
          typeof ruleItem.cat2.limitation === "number" &&
          ruleItem.cat2.limitation >= 1
        ) {
          let existLength = selectedOptions.filter(
            (x) => x.cat2 === option.cat2
          ).length;

          if (existLength < ruleItem.cat2.limitation) {
            // 如果还没选够
            setSelectedOptions([...selectedOptions, option]);
            return;
          }

          if (existLength >= ruleItem.cat2.limitation) {
            // over limit,no more option can be made,replace the first chosen option
            let flag = true; //true - not replace any element yet,false - first chosen option already been replaced no more replace need.
            setSelectedOptions(
              selectedOptions
                .map((s) => {
                  if (s.cat2 === option.cat2 && flag) {
                    if (s.barcode !== option.barcode) {
                      flag = false; //完成替换标记一下，防止多余的替换
                      return option;
                    } else {
                      return null;
                    }
                  } else {
                    return s;
                  }
                })
                .filter((x) => x)
            );
          }
          return;
        }

        if (ruleItem.cat2.limitation === "") {
          // 如果当前cat2没有设置数量限制，直接加入完事儿
          setSelectedOptions([...selectedOptions, option]);
          return;
        }
      } else {
        // 如果当前cat2没有设置数量限制，直接加入完事儿
        setSelectedOptions([...selectedOptions, option]);
        return;
      }
    }

    // from here: cat2 has some other tags been selected.
    // we have to decide how many choices can be made for this cat2
    // if no local setting 1. size, sugar, ice single choice 2. others are multiple choices
    // if local setting has been loaded successfully, follow those settings

    if (
      extraAdv && //settings read
      extraAdv[option.cat2] //cat2 is set
    ) {
      // local setting success read
      let numberOfChoicesCanbeMade = null;

      if (!isNaN(extraAdv[option.cat2]))
        numberOfChoicesCanbeMade = parseInt(extraAdv[option.cat2]);

      if (typeof extraAdv[option.cat2] === "string") {
        switch (extraAdv[option.cat2]) {
          case "A":
            // no limit
            numberOfChoicesCanbeMade = null;
            break;

          case "D":
            if (extraToStock && extraToStock[option.cat2]) {
              // limit setup
              numberOfChoicesCanbeMade = Math.abs(
                extraToStock[option.cat2][selectedItem.barcode]
              );
            } else {
              // no limit
              numberOfChoicesCanbeMade = null;
            }
            break;
          default:
            break;
        }
      }

      if (!numberOfChoicesCanbeMade) {
        // unlimited multiple choices
        setSelectedOptions([...selectedOptions, option]);
        return;
      }

      if (numberOfChoicesCanbeMade) {
        if (numberOfChoicesCanbeMade === 1) {
          // 替换
          setSelectedOptions(
            selectedOptions.map((s) => {
              if (s.cat2 === option.cat2) {
                return option;
              } else {
                return s;
              }
            })
          );
          return;
        }

        if (numberOfChoicesCanbeMade >= 1) {
          let existLength = selectedOptions.filter(
            (x) => x.cat2 === option.cat2
          ).length;

          if (existLength < numberOfChoicesCanbeMade) {
            // 如果还没选够
            setSelectedOptions([...selectedOptions, option]);
            return;
          }

          if (existLength >= numberOfChoicesCanbeMade) {
            // over limit,no more option can be made,replace the first chosen option
            let flag = true; //true - not replace any element yet,false - first chosen option already been replaced no more replace need.
            setSelectedOptions(
              selectedOptions
                .map((s) => {
                  if (s.cat2 === option.cat2 && flag) {
                    if (s.barcode !== option.barcode) {
                      flag = false; //完成替换标记一下，防止多余的替换
                      return option;
                    } else {
                      return null;
                    }
                  } else {
                    return s;
                  }
                })
                .filter((x) => x)
            );
            return;
          }
        }
      }
    }

    if (!extraAdv || !extraAdv[option.cat2]) {
      if (
        option.cat2.toLowerCase().includes("sugar") ||
        option.cat2.toLowerCase().includes("size") ||
        option.cat2.toLowerCase().includes("ice")
      ) {
        // if cat2 with sugar, size or ice may single choice
        // 替换
        setSelectedOptions(
          selectedOptions.map((s) => {
            if (s.cat2 === option.cat2) {
              return option;
            } else {
              return s;
            }
          })
        );
        return;
      } else {
        // no local setting treat it as unlimited choices
        setSelectedOptions([...selectedOptions, option]);
        return;
      }
    }
  };

  const formatExtraName = (value) => {
    let resultString = value;
    const tryParseInt = (str, defaultValue) => {
      var retValue = defaultValue;
      if (str !== null) {
        if (str.length > 0) {
          if (!isNaN(str)) {
            retValue = parseInt(str);
          }
        }
      }
      return retValue;
    };
    while (
      typeof tryParseInt(resultString[0], "a") === "number" ||
      resultString[0] === "-"
    ) {
      resultString = resultString.substr(1);
    }

    return resultString;
  };

  const checkIfNeccessaryChoicesMade = () => {
    const extraGroups = _.map(
      _.groupBy(_.orderBy(extra, "cat2"), "cat2"),
      function (value, key) {
        return { custom1: key, extra: value };
      }
    );
    let flag = true;
    extraGroups.forEach((ele) => {
      if (isForcedMultipleChoice(ele.custom1, selectedItem.barcode)) {
        // 1. find all requirements of forcedChoiceExtraGroup
        // 2. check selectedOptions fit the requirements in last step
        flag =
          selectedOptions.reduce((sum, option) => {
            if (ele.extra.find((extra) => extra.barcode === option.barcode)) {
              return sum + 1;
            } else {
              return sum;
            }
          }, 0) >= getNumberOfChoices(ele.custom1, selectedItem.barcode);
      }
    });
    return flag;
  };

  const addDish = () => {
    if (!checkIfNeccessaryChoicesMade()) return false;
    if (quantity > 0 && mode !== "ORDER_ITEM") {
      cartDispatch({
        type: "ADD",
        payload: {
          ...selectedItem,
          quantity,
          selectedOptions,
          comments,
          selectedSize,
        },
      });
    } else if (quantity >= 0 && mode === "ORDER_ITEM") {
      cartDispatch({
        type: "ORDER_ITEM_UPDATE",
        payload: {
          ...selectedItem,
          quantity: quantity - selectedItem.quantity,
          selectedOptions,
          comments,
          index,
          selectedSize,
        },
      });
    }

    menuDispatch({ type: "SET", payload: { showOptionModal: false } });
    confirmPageDispatch({ type: "SET", payload: { showOptionModal: false } });
  };

  const clickSize = (size) => {
    setSelectedSize(size);
  };

  const calcCurrentPrice = () => {
    let productPrice =
      selectedSize && selectedSize.price
        ? parseFloat(selectedSize.price)
        : parseFloat(selectedItem.price);
    let price = 0;
    if (selectedOptions) {
      price = selectedOptions.reduce((sum, option) => {
        if (option.price > 0) {
          return sum + option.price;
        } else {
          return sum;
        }
      }, productPrice);
    } else {
      price = productPrice;
    }

    return price.toFixed(2);
  };

  return (
    <ModalCover
      dismiss={() => {
        menuDispatch({ type: "SET", payload: { showOptionModal: false } });
        confirmPageDispatch({
          type: "SET",
          payload: { showOptionModal: false },
        });
      }}
      contentWidth={375}
      contentPosition={"flex-end"}
      contentPadding={"16px 16px 18px 16px"}
    >
      <div className={classes.item}>
        <img
          src={imageSrc}
          onError={onError}
          className={classes.image}
          alt=""
        />
        <div className={classes.stockInfo}>
          <div className={classes.stockName}>
            {isCn ? selectedItem.description1 : selectedItem.description2}
          </div>
          <div className={classes.stockCardPrice}>
            <span className={classes.priceSymbol}>$</span>
            <span className={classes.priceValue}>{calcCurrentPrice()}</span>
          </div>
        </div>
      </div>
      <div className={classes.optionsContainer}>
        <div className={classes.optionContainerContent}>
          {sizeLevels.length > 1 && (
            <>
              <div className={classes.subTitle}>{strings.size}</div>
              <div className={classes.gridContainer}>
                {sizeLevels.map((size, i) => {
                  if (i === 0) return null;
                  return (
                    <div
                      key={`size-level-${i}`}
                      className={classnames(classes.gridItem, {
                        active: selectedSize.sizeLevel === size.sizeLevel,
                      })}
                      onClick={() => {
                        clickSize(size);
                      }}
                    >
                      {size.name}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {taste.length > 0 &&
            _.map(
              _.groupBy(_.orderBy(taste.filter((item)=> !item.inactive ), "cat2"), "cat2"),
              function (value, key) {
                return { custom1: key, taste: value };
              }
            ).sort((a, b)=>{
              if(a.custom1 === undefined && b.custom1 === undefined) return -1;
              if(a.custom1 === undefined) return 1;
              if(b.custom1 === undefined) return -1;
              const custom1 = a.custom1.toLowerCase()
              const custom2 = b.custom1.toLowerCase()
              if(custom1 > custom2){
                return 1
              }; 
              return -1
            }
            ).map((tasteGroup, k) => {
              return (
                <React.Fragment key={`taste-group-${k}`}>
                  <div className={classes.subTitle}>
                    {tasteGroup.custom1 !== "" ? getRealName(tasteGroup.custom1) : "Taste"}
                  </div>
                  <div className={classes.gridContainer}>
                    {tasteGroup.taste.map((t, i) => {
                      return (
                        <div
                          key={`taste-${i}`}
                          className={classnames(classes.gridItem, {
                            active: selectedOptions.find(
                              (x) => x.barcode === t.barcode
                            ),
                          })}
                          onClick={() => {
                            clickOption(t);
                          }}
                        >
                          {isCn ? t.description1 : t.description2}
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          {extra.length > 0 &&
            _.map(
              _.groupBy(_.orderBy(extra.filter((item)=> !item.inactive ), "cat2"), "cat2"),
              function (value, key) {
                return { custom1: key, extra: value };
              }
            ).map((extraGroup, k) => {
              return (
                <ExtraGroup
                  extraGroup={extraGroup}
                  formatExtraName={formatExtraName}
                  selectedOptions={selectedOptions}
                  clickOption={clickOption}
                  isCn={isCn}
                  classes={classes}
                  itemBarcode={selectedItem.barcode}
                  key={`extra-group-${k}`}
                />
              );
            })}
        </div>
      </div>
      <input
        type="text"
        className={classes.input}
        value={comments}
        onChange={(e) => {
          setComments(e.target.value);
        }}
      />
      <div className={classes.control}>
        <ControlPanel
          decrease={() => {
            if (quantity > 0) setQuantity(quantity - 1);
          }}
          increase={() => {
            setQuantity(quantity + 1);
          }}
          quantity={quantity}
        />
        <Button
          onClick={addDish}
          buttonText={
            quantity !== 0 || mode === "ORDER_ITEM"
              ? checkIfNeccessaryChoicesMade()
                ? strings.confirm
                : strings.missChoices
              : strings.cancel
          }
          style={
            (quantity !== 0 || mode === "ORDER_ITEM") &&
            checkIfNeccessaryChoicesMade()
              ? {}
              : {
                  backgroundColor: "grey",
                  padding: `0 ${isCn ? rw(26) : "10px"}`,
                }
          }
        />
      </div>
    </ModalCover>
  );
};

const useStyles = createUseStyles((theme) => {
  const {
    extends: { verticalFlexbox, horizontalFlexbox },
    colors,
    funcs: { rh, rw, rem },
    borders,
  } = theme;

  return {
    item: {
      ...horizontalFlexbox,
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: "0.75rem",
      height: "96px",
      borderBottom: "1px solid #F4F4F488",
    },
    image: {
      height: "85px",
      width: "85px",
      marginRight: "0.875rem",
      objectFit: "contain",
      backgroundColor: "#FFF",
      borderRadius: "6px",
      objectFit: "cover",
    },
    stockInfo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      height: "85px",
    },
    stockName: {
      color: colors.primaryText,
      fontSize: "1.25rem",
      letterSpacing: "-0.48px",
    },
    stockCardPrice: {
      fontSize: rem(14),
      lineHeight: rem(16),
      marginTop: rem(8),
      fontFamily: "sans-serif",
      color: colors.emphasis,
    },
    priceSymbol: {
      fontSize: rem(10),
      transform: "scale(0.5)",
    },
    priceValue: {
      fontSize: rem(20),
    },
    optionsContainer: {
      ...verticalFlexbox,
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      maxHeight: "50vh",
      overflow: "scroll",
    },
    optionContainerContent: {
      height: "max-content",
      // paddingBottom: '5rem',
    },
    subTitle: {
      fontSize: "0.875rem",
      letterSpacing: "-0.48px",
      color: colors.primaryText,
      marginBottom: "0.75rem",
      marginTop: "0.75rem",
      textTransform: "capitalize",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    gridContainer: {
      display: "flex",
      flexWrap: "wrap",
      // gridTemplateColumns: 'repeat(3,1fr)',
      // gridTemplateRows: 'auto',
      // gridGap: '10px',
      // width: '100%',
      // marginBottom: '12px'
    },
    gridItem: {
      ...verticalFlexbox,
      borderRadius: "6px",
      height: rem(36),
      color: colors.lightPrimaryText,
      border: "1px solid #dddddd",
      width: "max-content",
      fontSize: "12px",
      letterSpacing: "-0.29px",
      padding: "6px 12px",
      whiteSpace: "normal",
      wordBreak: "break-word",
      textAlign: "center",
      marginRight: "12px",
      marginBottom: "12px",
      backgroundColor: "transparent",
      fontFamily: "PingFang",
      "&.active": {
        color: colors.main,
        borderColor: colors.main,
        backgroundColor: "rgba(254,191,47,0.05)",
      },
    },
    input: {
      marginTop: rem(20),
      marginBottom: rem(18),
      width: "100%",
      height: "2.125rem",
      borderRadius: "21px",
      border: borders.input,
      backgroundImage: `url(${editPNG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "1rem",
      backgroundPosition: "5% center",
      outline: "none",
      paddingLeft: "2.5rem",
      color: colors.primaryText,
      fontSize: "1rem",
      boxShadow: "none",
    },
    control: {
      ...horizontalFlexbox,
      width: "100%",
      justifyContent: "space-between",
    },
  };
});
