import React, { useContext, useEffect, useState } from "react";
import useStyles from "./useStyles";
import { useHistory, useParams } from "react-router-dom";
import { MenuContext } from "../../contexts";
import { useAppSetting } from "../../hooks";

export default ({ children, size, search }) => {
  const history = useHistory();
  const { tableCode } = useParams();
  const classes = useStyles();
  const [state] = useContext(MenuContext);
  const { bannerStyle } = useAppSetting();
  const { baseImageUrl } = state;

  const getStyles = () => {
    const sizeStyle =
      size === "lg"
        ? { height: "4.75rem", marginBottom: "0.25rem" }
        : { height: "3.75rem", marginBottom: "1.25rem" };
    const backgroundStyle =
      history.location.pathname === `${process.env.PUBLIC_URL}/${tableCode}` ||
      history.location.pathname === `${process.env.PUBLIC_URL}/`
        ? {
            backgroundImage: `url(${baseImageUrl.replace(
              " ",
              "%20"
            )}banner.png)`,
            backgroundSize: bannerStyle.backgroundSize,
            backgroundPosition: bannerStyle.backgroundPosition || "center",
            backgroundRepeat: "no-repeat",
          }
        : { backgroundColor: "#FFF" };
    return search
      ? { ...sizeStyle, backgroundColor: "#FFF" }
      : { ...sizeStyle, ...backgroundStyle };
  };

  // useEffect(() => {
  //     setStyles(getStyles());
  // }, [baseImageUrl, size, history.location.pathname, search])

  return (
    <div className={classes.container} style={getStyles()}>
      {children}
    </div>
  );
};
