import { useContext } from "react";
import { Context } from "../contexts/App.context";

const useAppSetting = () => {
  const [state] = useContext(Context);
  const isForcedMultipleChoice = (cat2: string, barcode: string) => {
    const { extraAdv, extraToStock } = state;
    if (extraAdv[cat2] !== "D") return false;
    if (extraToStock[cat2] === undefined) return false;
    if (extraToStock[cat2][barcode] === undefined) return false;
    if (isNaN(extraToStock[cat2][barcode])) return false;
    return parseInt(extraToStock[cat2][barcode]) < 0;
  };
  const getNumberOfChoices = (cat2: string, barcode: string) => {
    const { extraAdv, extraToStock } = state;
    if (extraAdv[cat2] !== "D") return false;
    if (extraToStock[cat2] === undefined) return false;
    if (extraToStock[cat2][barcode] === undefined) return false;
    if (isNaN(extraToStock[cat2][barcode])) return false;
    return Math.abs(parseInt(extraToStock[cat2][barcode]));
  };
  return { ...state, isForcedMultipleChoice, getNumberOfChoices };
};

export default useAppSetting;
