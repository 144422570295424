import React, {useEffect, useState, useContext} from "react"
import {checkIsMiniProgram} from "../../utils/miniapp"

import { withRouter } from "react-router";

//Apis
import queryMiniappWechatPay from "../../apis/queryMiniappWechatPay"

//Context
import { Context } from "../../contexts/App.context";

//Materialui
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

//Utils
import {getOrderType} from "../../utils/index"

//Styles
import useStyles from "./useStyles"
import { Divider } from "@material-ui/core";

const tableCells = [
    {
        title: "Store Name",
        value: "storeName"
    },
    {
        title: "Order No.",
        value: "orderNo"
    },
    {
        title: "Merchant Order No.",
        value: "mchOrderNo"
    },
    {
        title: "Amount",
        value: "orderAmount"
    },
    {
        title: "Status",
        value: "orderStatus"
    },
    {
        title: "Message",
        value: "message"
    },

]

const PaymentProcessing = ({ history }) => {
    const [state, appDispatch] = useContext(Context);
    const classes = useStyles()
    const [request, setRequest] = useState<any>(null)
    const [result, setResult] = useState<any>(null)
    const isGetResult = result && result?.orderStatus !== "PROCESSING"
    const isSuccess = result && (result?.orderStatus === "SUCCEEDED" || result?.orderStatus === "COMPLETED")
    useEffect(()=>{
        let timeoutId:  ReturnType<typeof setTimeout>|null = null;
        const getPaymentStatus = async ()=>{
            if(checkIsMiniProgram()){
                const urlParams = new URLSearchParams(window.location.search);
                const dataRaw = urlParams.get("data")
                if(dataRaw){
                    const data =  JSON.parse(dataRaw)
                    setRequest(data)
                    console.log("data", data)
                    if((!state?.userOpenId) && data?.userOpenId){
                        appDispatch({
                            type: "SET",
                            payload: {
                              userOpenId: data.userOpenId
                            },
                        })
                    }

                    if(!data?.isPaid){
                        setResult({
                            storeName: undefined,
                            orderNo: data?.orderNo,
                            mchOrderNo: data?.mchOrderNo,
                            orderAmount: data?.entryAmount,
                            orderStatus: "Failed",
                            message: data?.errMsg
                        })
                        return;
                    }

                    const resp = await queryMiniappWechatPay(data)
                    if(resp && resp.code === "0" && resp?.data && resp.data.code === "0" && resp.data.data){
                        const result = resp.data.data;
                        console.log("result", result)
                        if(result.orderStatus === "PROCESSING"){
                            timeoutId = setTimeout(getPaymentStatus, 3000)
                            return
                        } else {
                            setResult(result)
                        }
                    }
                }
            }
        }
        getPaymentStatus()
        return ()=>{
            if(timeoutId) clearTimeout(timeoutId)
        }
    },[])
    
    const TableCell = ({cell}) => {
        if(result[cell.value] === undefined) return null
        return(
            <>
                    <div className={classes.table__cell}>
                        <div className={classes.table__title}>
                            {cell.title}
                        </div>
                        <div className={classes.table_text}>
                            {cell.title === "Amount"?
                                result?result[cell.value]?`A$ ${result[cell.value]}`:"":""
                                :
                                result?result[cell.value]?result[cell.value]:"":""
                            }
                        </div>
                    </div>
                    <Divider/>
            </>
        )
    }
    const handleNavigateBack = () => {
        if(isGetResult){
            if(getOrderType() === "preorder"){
                console.log("preorder", `${process.env.PUBLIC_URL}/`)
                history.push(`${process.env.PUBLIC_URL}/`);
            } else {
                if(result?.tableNumber !== undefined){
                    console.log("order", `${process.env.PUBLIC_URL}/${result.tableNumber}`)
                    history.push(`${process.env.PUBLIC_URL}/${result.tableNumber}`);
                } else {
                    console.log("order: result.tableNumber === undefined", `${process.env.PUBLIC_URL}/${result.tableNumber}`)
                    history.push(`${process.env.PUBLIC_URL}/1`);
                }

            }
        }
        
    }

    return (
        <div className={classes.root}>
            <div className={classes.statusIcon}>

                {isGetResult? 
                    result?.orderStatus === "SUCCEEDED" || result?.orderStatus === "COMPLETED"?
                        <div className={classes.successIcon}>
                            <CheckIcon className={classes.successIcon__icon}/>
                        </div>
                    :
                        <div className={classes.errorIcon}>
                            <CloseIcon className={classes.successIcon__icon}/>
                        </div>
                : 
                <CircularProgress style={{width: "100%", height:"100%"}} thickness={2.5} className={classes.statusIcon__processing}/>}
            </div>
            <div className={classes.statusText}>
                {result?.orderStatus?result.orderStatus : "PROCESSING"}
            </div>
            {
                isGetResult?
                    isSuccess?
                        <div className={classes.orderNumber}>Please pick up your meal at counter</div>
                    :
                        null
                :
                    <div className={classes.orderNumber}>
                        Order Number: <span> </span>
                        {request && request?.orderNo}
                    </div>

            }
            {isGetResult?
                <>
                    <div className={classes.table}>
                        <div className={`${classes.table__title} ${classes.table__cell}`}>Payment Detail</div>
                        <Divider/>
                        {tableCells.map((item, idx)=>(<TableCell cell={item} key={idx}/>))}
                    </div>
                    <div className={classes.backButton}>
                        <button className={classes.backButton__button} onClick={handleNavigateBack}>
                                {isSuccess?"Order More":"Try Again"}
                        </button>
                    </div>
                </>
            :null} 

        </div>
    )
}

export default withRouter(PaymentProcessing)