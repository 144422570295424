import request from "./request";

interface InputValues {
  tableCode: string;
  token: string;
  salesorderId: string | number;
  salesorderLines: string;
}

const getStaffToken = async (inputValues: InputValues) => {
  const resp = await request.post("salesorderline", inputValues);

  return resp;
};

export default getStaffToken;
