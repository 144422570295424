import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => {
    const { funcs: { rh, rem }, extends: { horizontalFlexbox } } = theme;
    return {
        root: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            height: '100vh',
            width: '100vw',
            backgroundColor: '#696969b3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        rootContent: {
            maxHeight: rh(533),
            width: '100vw',
            borderRadius: '16px 16px 0 0',
            backgroundColor: '#FFF',
            animation: '$rising 400ms ease-in-out'
        },
        "@keyframes rising": {
            from: "transform: translateY(100%)",
            to: "transform: translateY(0)"
        },
        header: {
            height: rh(53),
            width: '100%',
            padding: '0 1rem',
            fontSize: rem(12),
        },
        quantity: {
            color: '#000',
        },
        clear: {
            ...horizontalFlexbox,
            color: '#6D7278',
            fontWeight: 500,
        },
        clearImage: {
            height: '13px',
            width: '12px',
            marginRight: '6px',
        },
        headerContent: {
            ...horizontalFlexbox,
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            borderBottom: '1px solid #F4F4F4',
        },
        body: {
            height: 'max-content',
            maxHeight: rh(396),
            overflow: 'scroll'
        },
        bodyContent: {
            height: 'max-content',
            minHeight: '101%',
            padding: '0 1rem',
        },
        footer: {
            height: rh(84),
            width: '100%',
        },
    }
})

export default useStyles;