import React from "react";
import { CartContext } from "../contexts";
import { makePayment } from "../apis";
import getTimeStamps from "./getTimeStamps";

import {checkShopNameAndOrderType, getHostByShopNameAndOrderType} from "../utils/index"

export default () => {
  const [state, dispatch] = React.useContext(CartContext);
  const { cartList } = state;

  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [isShowCartOverlay, setShowCartOverlay] = React.useState(false);

  const getTotalQuantity = () => {
    setTotalQuantity(
      cartList.reduce((sum, orderItem) => sum + orderItem.quantity, 0)
    );
  };

  const getTotalAmount = () => {
    setTotalAmount(
      cartList
        .reduce((sum, orderItem) => {
          let unitPrice =
            orderItem.quantity *
            parseFloat(
              orderItem.selectedSize && orderItem.selectedSize.price
                ? orderItem.selectedSize.price
                : orderItem.price
            );
          if (orderItem.selectedOptions) {
            orderItem.selectedOptions.forEach((o) => {
              unitPrice += o.price;
            });
          }
          return sum + unitPrice;
        }, 0)
        .toFixed(2)
    );
  };

  /**
   *
   * @param {Integer} payload position index in cart list
   */
  const increaseInCart = (payload) => {
    if (
      window.location.hostname === "localhost" ||
      checkShopNameAndOrderType("dolarshopsydney", "order")
    ) {
      if (cartList[payload] && cartList[payload].barcode === "A001") {
        alert("can not");
      } else {
        dispatch({ type: "INCREASE_IN_CART", payload });
      }
    } else {
      dispatch({ type: "INCREASE_IN_CART", payload });
    }
  };

  /**
   *
   * @param {Integer} payload position index in cart list
   */
  const decreaseInCart = (payload) => {
    if (
      window.location.hostname === "localhost" ||
      checkShopNameAndOrderType("dolarshopsydney", "order")
    ) {
      if (cartList[payload] && cartList[payload].barcode === "A001") {
        alert("can not");
      } else {
        dispatch({ type: "DECREASE_IN_CART", payload });
      }
    } else {
      dispatch({ type: "DECREASE_IN_CART", payload });
    }
  };

  // calculate how many dishes in cart for given certain category
  const cateInCartQuantity = (category) => {
    let sum = 0;
    cartList.forEach((x) => {
      const isFound = category.stocks.find((stock) => {
        if (stock.barcode === x.barcode) {
          return true;
        } else {
          return false;
        }
      });

      if (isFound) {
        sum += x.quantity;
      }
    });
    return sum;
  };

  //
  const toggleCartOverlay = () => {
    setShowCartOverlay(!isShowCartOverlay);
  };

  //
  const clearCart = () => {
    dispatch({ type: "RESET" });
  };

  const getPaymentUrl = async (channel) => {
    const resp = await makePayment({
      channel: channel,
      hostname:
        window.location.hostname === "localhost"
          ? getHostByShopNameAndOrderType("test", "order")
          : window.location.hostname,
      order_id: `PREORDER${getTimeStamps()}`,
      quantity: totalQuantity,
      total:
        window.location.hostname === "localhost" ||
        checkShopNameAndOrderType("test", "preorder")
          ? 0.01
          : totalAmount,
      payway: channel === "WECHAT" ? undefined : "WAP",
      returnUrl: `${window.location.protocol}//${window.location.hostname}/payment-complete`,
    });

    return resp;
  };

  React.useEffect(() => {
    getTotalQuantity();
    getTotalAmount();
    dispatch({ type: "LOAD" });
  }, []);

  React.useEffect(() => {
    getTotalQuantity();
    getTotalAmount();
  }, [cartList]);

  return {
    totalQuantity,
    totalAmount,
    cartList,
    increaseInCart,
    decreaseInCart,
    cateInCartQuantity,
    toggleCartOverlay,
    isShowCartOverlay,
    clearCart,
    getPaymentUrl,
  };
};
