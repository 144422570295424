const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const initState = {
  categories: [],
  baseImageUrl: "",
  searchKeyword: "",
  showOptionModal: false,
  selectedItem: {},
  isShowLargeImage: false,
  showSearchInput: false,
};

export default [reducer, initState];
