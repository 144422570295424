import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
    return{
        root:{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        title:{
            fontSize: 23,
            marginTop: "2rem"
        }
    }
})

export default useStyles