const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const initState = {
  lanuage: "cn",
  isCn: false,
};

export default [reducer, initState];
