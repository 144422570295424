import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme) => {
    const { funcs: { rw, rh, rem }, colors } = theme

    return {
        container: {
            width: '100%',
            overflow: 'hidden',
            flex: 1,
            // boxShadow: 'rgba(0, 0, 0, 0.11) 0px 2px 9px 0px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            margin: 'auto'
        }
    }
})

export default useStyles