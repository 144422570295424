import React from "react";
import { useTheme } from "react-jss";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import useStyles from "./useStyles";
import { useShoppingCart, useConfirmPage } from "../../hooks";
import ShoppingCartItem from "../../components/ShoppingCartItem/ShoppingCartItem";
import { ShoppingCartHelper } from "../../utils";
import strings from "../../strings";

export default () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { cartList, totalQuantity, totalAmount } = useShoppingCart();

  const { showOrder, displayOptionModal } = useConfirmPage();
  const { qrCodeString } = ShoppingCartHelper();

  return (
    <div className={classes.preorderRoot}>
      <div className={classes.preorderScroll}>
        <div className={classes.qrcodeContainer}>
          <QRCode value={`=QROD=${qrCodeString}`} />
          <div className={classes.preorderInstruction}>
            {strings.preorderInstruction}
          </div>
        </div>

        <div>
          {cartList.map((x, i) => {
            return (
              <ShoppingCartItem
                item={x}
                key={`shopping-cart-list-item-${i}`}
                index={i}
                onClick={() => {
                  displayOptionModal({ ...x, index: i });
                }}
                hideControl={true}
                displayPrice={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
