import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const {
    colors,
    funcs: { rw, rh, rem },
    extends: { horizontalFlexbox, verticalFlexbox },
  } = theme;

  return {
    container: {
      height: `calc(100vh - ${(60 + 20) / 16}rem)`,
      backgroundColor: "#FFF",
      display: "flex",
      overflow: "hidden",
      flexDirection: "row",
    },
    containerChow: {
      height: `calc(100vh - ${(60 + 20) / 16}rem)`,
      backgroundColor: "#FFF",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: "0 10px",
    },
    categoryList: {
      position: "static",
      height: `calc(100% - ${100 / 16}rem)`,
      flexDirection: "column",
      overflow: "scroll",
      WebkitOverflowScrolling: "touch",
      backgroundColor: "#F9F9F8",
    },
    categoryListChow: {
      display: "none",
      position: "static",
      height: "max-content",
      flexDirection: "row",
      overflow: "scroll",
      justifyContent: "flex-start",
      WebkitOverflowScrolling: "touch",
      backgroundColor: "#F9F9F8",
    },
    categoryListItem: {
      minHeight: rem(40),
      width: rw(105),
      paddingTop: "0.75rem",
      paddingBottom: "0.75rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#7D7D7E",
      fontSize: rem(12),
      letterSpacing: "0.52px",
      flexDirection: "column",
      overflow: "hidden",
      position: "relative",
    },
    icon: {
      height: rh(19),
    },
    quantity: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "1rem",
      height: "1rem",
      fontSize: "0.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "normal",
      color: "#FFF",
      backgroundColor: colors.emphasis,
      borderRadius: "50%",

      fontFamily: "sans-serif",
    },
    active: {
      opacity: 1,
      backgroundColor: "#FFF",
      // color: "#343434",
      color: "#000",
      fontWeight: "bold",
      letterSpacing: 0,
    },
    nextToActive: {
      borderTopRightRadius: "8px",
    },
    productList: {
      position: "static",
      height: `100%`,
      overflow: "scroll",
      flex: 1,
      marginLeft: "5px",
      WebkitOverflowScrolling: "touch",
    },
    productListChow: {
      position: "static",
      overflow: "scroll",
      flex: 1,
      width: "100%",
      WebkitOverflowScrolling: "touch",
    },
    categoryName: {
      fontSize: rem(14),
      color: "#616161",
      paddingLeft: "0.3rem",
      // letterSpacing: '4px',
      backgroundColor: "#FFF",
      padding: "0.5rem 0",
      position: "sticky",
      top: 0,
    },
    categoryNameChow: {
      fontSize: rem(16),
      color: "#4A4A4A",
      fontWeight: "bold",
      // letterSpacing: '4px',
      backgroundColor: "#FFF",
      padding: "0.5rem 0",
      position: "sticky",
      top: 0,
      zIndex: 50,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    stockCardContainer: {
      display: "flex",
      overflow: "hidden",
      minHeight: rem(80),
      width: "100%",
      backgroundColor: "#FFF",
      // boxShadow: `5px 4px 4px 0px rgba(0,0,0,0.11)`,
      marginBottom: rh(10),
      borderRadius: "8px 0px 0px 8px",
      padding: "5px 9px 5px 5px",
    },
    stockCardContainerChow: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "6px",
      overflow: "hidden",
      position: "relative",
      border: "1px solid #00000024",
      marginBottom: "1.1rem",
      height: "calc(100vw - 20px)",
    },
    stockCardImage: {
      height: rw(85),
      width: rw(85),
      marginRight: "9px",
      borderRadius: "6px",
      backgroundColor: "#FFF",
      objectFit: "cover",
      border: "1px solid #00000024",
    },
    stockCardImageChow: {
      height: "100%",
      width: "100%",
      margin: 0,
      padding: 0,
      borderRadius: "6px",
      backgroundColor: "#FFF",
      objectFit: "cover",
    },
    stockCardMain: {
      ...verticalFlexbox,
      flex: 1,
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    stockCardName: {
      width: "100%",
      fontSize: rem(14),
      fontFamily: "PingFang-SC-Regular,PingFang-SC,PingFang",
      color: colors.primaryText,
      lineHeight: rem(16),
      whiteSpace: "normal",
      wordBreak: "break-all",
      fontWeight: "bold",
    },
    stockCardInfoChow: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      backgroundImage: "linear-gradient(#00000078, #00000029,transparent)",
      padding: "0.75rem",
      height: "50%",
    },
    stockCardNameChow: {
      color: "#FFF",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    stockCardPriceChow: {
      color: "#FFF",
      fontSize: "14px",
      fontWeight: "normal",
      fontFamily: "sans-serif",
    },
    stockCardPriceControl: {
      ...horizontalFlexbox,
      width: "100%",
      justifyContent: "space-between",
    },
    stockCardPriceControlChow: {
      position: "absolute",
      height: "max-content",
      width: "max-content",
      backgroundColor: "transparent",
      right: "10px",
      bottom: "10px",
    },
    clockContainer: {
      position: "absolute",
      height: "39px",
      bottom: "calc(6.25rem - 44px)",
      left: "11px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    mealClockTitle: {
      color: "#202326",
      fontSize: "10px",
      fontFamily: "PingFang",
      lineHeight: "14px",
      fontWeight: 600,
      marginLeft: "5px",
    },
    stockCardPrice: {
      fontSize: rem(14),
      lineHeight: rem(16),
      marginTop: rem(8),
      fontFamily: "sans-serif",
      color: colors.emphasis,
    },
    priceSymbol: {
      fontSize: rem(10),
      transform: "scale(0.5)",
    },
    priceValue: {
      fontSize: rem(20),
    },
    stockCardControll: {
      display: "flex",
      alignItems: "center",
    },
    stockCardControllChow: {
      display: "flex",
      alignItems: "center",
    },
    stockCardQuantity: {
      ...verticalFlexbox,
      height: rem(24),
      width: rem(24),
      fontFamily: "sans-serif",
      color: colors.lightPrimaryText,
    },
    seasonalIntro: {
      color: "#4a4a4a",
      fontSize: "10px",
      lineHeight: "12px",
      width: "75%",
    },
    "icon-button-svg": {
      height: "2rem",
      width: "2rem",
      boxShadow: "inset 0 0 1px #0000004d",
      borderRadius: "50%",
      "& #add-circular-outlined-button": {
        fill: colors.chowButton,
      },
      "& #Path": {
        fill: colors.buttonText,
      },
      "& #Shape": {
        fill: colors.chowButton,
      },
      "& rect": {
        fill: colors.chowButton,
      },
    },
    buttonQuantity: {
      height: "2rem",
      width: "2rem",
      backgroundColor: "#FFF",
      borderRadius: "50%",
      boxShadow: "inset 0 0 1px #0000004d",
      display: "flex",
      marginRight: "0.5rem",
      color: "#000",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "sans-serif",
    },
  };
});

export default useStyles;
