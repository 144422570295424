import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { Link, Element, Events } from "react-scroll";
import _ from "lodash";
import HistoryOrderCtx from "../../contexts/history-order.context";

import { MenuContext, LanguageContext } from "../../contexts";
import { useShoppingCart, useAppSetting, useLayout } from "../../hooks";
import useStyles from "./useStyles";
import StockCard from "./StockCard";
import OptionModal from "./OptionModal";
import ProductDetail from "./ProductDetail";
import MealClock from "../MealClock/MealClock";
import { getStoreName, checkShopNameAndOrderType } from "../../utils";

// import defaultCategoryIcon from "../../assets/images/icons/default_category.png"
export default () => {
  const [state, dispatch] = React.useContext(MenuContext);
  const [languageState] = React.useContext(LanguageContext);
  const [historyOrderState] = React.useContext(HistoryOrderCtx.Context);
  const { uiSuite } = useLayout();
  const { isCn } = languageState;
  const theme = useTheme();
  const classes = useStyles(theme);
  const { cateInCartQuantity } = useShoppingCart();

  const {
    categories,
    searchKeyword,
    showOptionModal,
    isShowLargeImage,
  } = state;
  const { expired } = useAppSetting();

  useEffect(() => {
    Events.scrollEvent.register("end", function (to, element) {
      dispatch({
        type: "SET",
        payload: { searchKeyword: "", showSearchInput: false },
      });
    });

    return () => {
      Events.scrollEvent.remove("end");
    };
  }, []);
  // main render JSX
  return (
    <div
      className={uiSuite === "EASI" ? classes.container : classes.containerChow}
    >
      {historyOrderState.historyOrder.startTime &&
        expired &&
        expired.timeLimit && (
          <div className={classes.clockContainer}>
            <div style={{ width: "35px", height: "39px" }}>
              <MealClock />
            </div>
            <div className={classes.mealClockTitle}>Meal Time</div>
          </div>
        )}
      {showOptionModal && <OptionModal />}
      {isShowLargeImage && <ProductDetail />}
      <div
        className={
          uiSuite === "EASI" ? classes.categoryList : classes.categoryListChow
        }
      >
        <div
          style={
            uiSuite === "EASI"
              ? { minHeight: "101%", paddingBottom: "10rem" }
              : {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  height: "max-content",
                  paddingRight: "20vw",
                  minWidth: "101%",
                }
          }
        >
          {categories
            .filter((c) => c.stocks.length > 0)
            .map((category, index) => {
              return (
                <Link
                  key={`categoryList-item-${index}`}
                  className={classes.categoryListItem}
                  activeClass={classes.active}
                  to={`nav${category.cat_name}`}
                  isDynamic={false}
                  offset={0}
                  spy={true}
                  smooth={true}
                  duration={300}
                  containerId="product-list"
                >
                  {cateInCartQuantity(category) > 0 && (
                    <span className={classes.quantity}>
                      {cateInCartQuantity(category)}
                    </span>
                  )}
                  <span
                    style={{
                      textAlign: "center",
                      wordBreak: "break-word",
                      padding: "0 0.5rem",
                    }}
                  >
                    {isCn ? category.cat_name1 : category.cat_name2}
                  </span>
                </Link>
              );
            })}
        </div>
      </div>

      <div
        id="product-list"
        className={
          uiSuite === "EASI" ? classes.productList : classes.productListChow
        }
      >
        <div
          style={{
            minHeight: "101%",
            paddingBottom: "15rem",
          }}
        >
          {categories
            .filter((c) => c.stocks.length > 0)
            .map((category, categoryIndex) => {
              let displayCategoryName = false;

              category.stocks.forEach((stk) => {
                if (
                  stk.description1 &&
                  stk.description1
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase())
                ) {
                  displayCategoryName = true;
                }
                if (
                  stk.description2 &&
                  stk.description2
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase())
                ) {
                  displayCategoryName = true;
                }
              });

              const storeName = getStoreName();
              const displayStocks =
                storeName === "hkyuebaby"
                  ? _.orderBy(category.stocks, "sequenceNumber")
                  : category.stocks;
              return (
                <Element
                  key={`productGroup${category.cat_name}`}
                  className="product-group"
                  style={{ marginTop: "1rem" }}
                  name={`nav${category.cat_name}`}
                >
                  {displayCategoryName && (
                    <div
                      className={
                        uiSuite === "EASI"
                          ? classes.categoryName
                          : classes.categoryNameChow
                      }
                    >
                      {isCn ? category.cat_name1 : category.cat_name2}
                      {/* <ArrowDownSVG /> */}
                    </div>
                  )}
                  {displayStocks
                    .filter((stk) => {
                      let flag = false;
                      if (
                        stk.description1 &&
                        stk.description1
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      ) {
                        flag = true;
                      }
                      if (
                        stk.description2 &&
                        stk.description2
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      ) {
                        flag = true;
                      }
                      return flag;
                    })
                    .map((stock, k) => {
                      if (
                        window.location.hostname === "localhost" ||
                        checkShopNameAndOrderType("dolarshopsydney", "order")
                      ) {
                        if (stock.barcode === "A001") return null;
                      }
                      return (
                        <StockCard
                          stock={stock}
                          key={`${category.cat_name}stock${stock.stock_id}-index${k}`}
                          promotion={categoryIndex === 0}
                        />
                      );
                    })}
                </Element>
              );
            })}
        </div>
      </div>
    </div>
  );
};
