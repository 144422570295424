import React from "react";

import { ReactComponent as AddButton } from "../../assets/images/icons/add.svg";
import { ReactComponent as DecreaseButton } from "../../assets/images/icons/decrease.svg";
import { useTheme } from "react-jss";
import useStyles from "./useStyles";

export default ({ onClick, icon, style, type }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={classes.iconButton}
      style={style ? { ...style } : {}}
    >
      {type === "add" && <AddButton className={classes["icon-button-svg"]} />}
      {type === "remove" && (
        <DecreaseButton className={classes["icon-button-svg"]} />
      )}
    </button>
  );
};
