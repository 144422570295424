/**
 * display options for order item
 */

import React from "react"
import strings from "../../strings";

import { LanguageContext } from "../../contexts"
import { useTheme, createUseStyles } from "react-jss"

import {checkShopNameAndOrderType} from "../../utils/index"

export default ({ item }) => {
    const { selectedSize, selectedOptions, comments } = item

    const theme = useTheme()
    const classes = useStyles(theme)

    const [state] = React.useContext(LanguageContext);
    const { isCn } = state;
    return (
        <div className={classes.container}>
            {(window.location.hostname === "localhost" || checkShopNameAndOrderType("dolarshopsydney", "order"))
                && item.barcode === "A001"
                && <div className={classes.option}>
                    {strings.notEditable}
                </div>}
            {selectedSize && selectedSize.name && <div className={classes.option}>{selectedSize.name}</div>}
            {selectedOptions && selectedOptions.map((option, index) => {
                return (
                    <div
                        key={`selected-option=${index}`}
                        className={classes.option}
                    >
                        {isCn ? option.description1 : option.description2}{(option.price !== null && option.price !== undefined && option.price > 0) ? ` +$${parseFloat(option.price).toFixed(2)}` : null}
                    </div>
                )
            })}
            <div className={classes.comments}>
                {comments}
            </div>
        </div>
    )
}

const useStyles = createUseStyles(theme => {
    const { colors, extends: { horizontalFlexbox }, funcs: { rem } } = theme

    return {
        container: {
            ...horizontalFlexbox,
            justifyContent: 'flex-start',
            gridColumn: '2/3',
            gridRow: '2/3',
            flexWrap: 'wrap',
            lineHeight: '16px',
        },
        option: {
            color: colors.optionTag,
            fontSize: '12px',
            letterSpacing: '-0.29px',
            marginRight: '11px',
            marginLeft: '0px',
        },
        comments: {
            color: colors.lightPrimaryText,
            fontSize: '10px',
            letterSpacing: '-0.29px',
            // transform: 'scale(0.8)',
            marginLeft: '0px',
        }
    }
})