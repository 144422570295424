import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { BrowserRouter as Router } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import {
  LoadingContext,
  MenuContext,
  CartContext,
  ConfirmPageContext,
  ApiResponseContext,
  LanguageContext,
} from "./contexts";
import {
  LoadingReducer,
  MenuReducer,
  CartReducer,
  ConfirmPageReducer,
  ApiResponseReducer,
  LanguageReducer,
} from "./reducers";
import { Provider as AppProvider } from "./contexts/App.context";
import WelcomeCtx from "./contexts/welcome-modal.context";
import HistoryOrderCtx from "./contexts/history-order.context";
import PaymentCtx from "./contexts/Payment.context";
import theme from "./theme";
import Routes from "./routes";
import LayoutCTX from "./contexts/Layout.context";

function App() {
  console.log("v1")
  // apply global css styles
  useStyles();

  // apply application settings
  useEffect(() => {});

  // record loading state
  const loadingHook = React.useReducer(LoadingReducer[0], LoadingReducer[1]);

  // menu state will store all dishes and category information which are getting from server
  const menuHook = React.useReducer(MenuReducer[0], MenuReducer[1]);

  // cart state will store user's shopping cart information
  const cartHook = React.useReducer(CartReducer[0], CartReducer[1]);

  // confirm page state will control confirm page displaying and interative behaviors
  const confirmPageHook = React.useReducer(
    ConfirmPageReducer[0],
    ConfirmPageReducer[1]
  );

  // response state will store all api calls response status
  const apiResponseHook = React.useReducer(
    ApiResponseReducer[0],
    ApiResponseReducer[1]
  );

  // store language settings
  const languageHook = React.useReducer(LanguageReducer[0], LanguageReducer[1]);
  console.log("Hello RalphMa2.10!")
  const isMiniProgram = /miniProgram/i.test(navigator.userAgent.toLowerCase())
  console.log("isMiniProgram", isMiniProgram)
  console.log("window.location.pathname", window.location.pathname)
  console.log("window.location.href", window.location.href)

  return (
    <Router>
      <LastLocationProvider>
        <LayoutCTX.Provider>
          <AppProvider>
            <WelcomeCtx.Provider>
              <LoadingContext.Provider value={loadingHook}>
                <LanguageContext.Provider value={languageHook}>
                  <ApiResponseContext.Provider value={apiResponseHook}>
                    <MenuContext.Provider value={menuHook}>
                      <HistoryOrderCtx.Provider>
                        <CartContext.Provider value={cartHook}>
                          <ConfirmPageContext.Provider value={confirmPageHook}>
                            <PaymentCtx.Provider>
                              <Routes />
                            </PaymentCtx.Provider>
                          </ConfirmPageContext.Provider>
                        </CartContext.Provider>
                      </HistoryOrderCtx.Provider>
                    </MenuContext.Provider>
                  </ApiResponseContext.Provider>
                </LanguageContext.Provider>
              </LoadingContext.Provider>
            </WelcomeCtx.Provider>
          </AppProvider>
        </LayoutCTX.Provider>
      </LastLocationProvider>
    </Router>
  );
}

export default App;

const useStyles = createUseStyles({
  "@global": {
    body: {
      height: "100vh",
      margin: 0,
      backgroundColor: theme.colors.background,
      fontFamily: "PingFang",
    },
    "*": {
      boxSizing: "border-box",
    },
  },
});
