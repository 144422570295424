import React from "react"
import { useHistoryOrder, useConfirmPage } from "../../hooks"
import { ConfirmPageListContainer, HistoryOrderItem } from "../../components"
import strings from "../../strings"

import icon from "../../assets/images/icons/table.png"


export default () => {
    const { showCart } = useConfirmPage()
    const { historyOrder, quantity } = useHistoryOrder()

    return (
        <ConfirmPageListContainer
            onButtonClick={showCart}
            buttonText={strings.showShoppingCart}
            icon={icon}
            title={strings.orderedDishTitle}
            backgroundColor={'#9B9B9B'}
            quantity={quantity}

        >
            {historyOrder.map((x, i) => {
                return <HistoryOrderItem item={x} key={`history-order-list-item=${i}`} />
            })}
        </ConfirmPageListContainer>
    )
}
