import { createContext } from "react";
import { Context } from "./App.context";

export const LoadingContext = createContext();
export const MenuContext = createContext();
export const CartContext = createContext();
export const ConfirmPageContext = createContext();
export const ApiResponseContext = createContext();
export const LanguageContext = createContext();
export const AppContext = Context;
