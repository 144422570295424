import { useContext, useEffect } from "react";
import strings from "../strings";
import { LanguageContext } from "../contexts";
import { Context } from "../contexts/App.context";

export default () => {
  const [state, dispatch] = useContext(LanguageContext);
  const [appSetting] = useContext(Context);
  const { language } = state;
  const { multiLanguage, defaultLanguage } = appSetting;

  const initLanguage = () => {
    let defaultLan = "en";

    try {
      defaultLan =
        window.navigator.language.substr(0, 2).toLowerCase() === "zh"
          ? "cn"
          : "en";
    } catch (error) {
      defaultLan = defaultLanguage;
    }

    let storedLan = localStorage.getItem("aupos_customer_order_language_code");
    if (!multiLanguage) {
      dispatch({
        type: "SET",
        payload: { language: defaultLanguage, isCn: defaultLanguage === "cn" },
      });
      strings.setLanguage(defaultLanguage);
    } else {
      if (defaultLan && !storedLan) {
        dispatch({
          type: "SET",
          payload: { language: defaultLan, isCn: defaultLan === "cn" },
        });
        strings.setLanguage(defaultLan);
      }

      if (storedLan) {
        dispatch({
          type: "SET",
          payload: { language: storedLan, isCn: storedLan === "cn" },
        });
        strings.setLanguage(storedLan);
      }
    }
  };

  const changeLanguage = () => {
    if (!multiLanguage) {
      return;
    } else if (language === "en") {
      strings.setLanguage("cn");
      localStorage.setItem("aupos_customer_order_language_code", "cn");
      dispatch({ type: "SET", payload: { language: "cn", isCn: true } });
    } else {
      strings.setLanguage("en");
      localStorage.setItem("aupos_customer_order_language_code", "en");
      dispatch({ type: "SET", payload: { language: "en", isCn: false } });
    }
  };

  return {
    isCn: language === "cn",
    changeLanguage,
    initLanguage,
  };
};
